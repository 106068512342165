import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG } from "../../global/config/config.token";
import { AppConfig } from "../../global/config/app.config";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, catchError, debounceTime, map, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AsyncValidatorService {
  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) { }

  validateUniqueEmail(): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      if (!control.value) {
        return of(null);
      }

      return this.httpClient.get<{ userExist: boolean }>(`${this.config.apiUrl}userinvite/check-user-exist?email=${control.value}`, {withCredentials: true})
        .pipe(
          debounceTime(300), 
          map((response) => {
            return response.userExist ? { emailTaken: true } :  null;
          }),
          catchError(() => of(null))
        );
    };
  }
}
