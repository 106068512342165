
<p-toast />
<p-confirmDialog />
<h1>Invite New User</h1>
<p-stepper 
  orientation="vertical"
  (activeStepChange)="stepChange($event)"
  [linear]="true"
  #userInviteStepper>
  <p-stepperPanel header="User Details">
    <ng-template pTemplate="content" #userInputPanel let-nextCallback="userPanelNextCallback" let-index="index">
      <form [formGroup]="personalInfoForm">
        <p-card styleClass="mb-2">
          <div class="formgrid grid">
            <div class="field col-12 md:col-4 xxl:col-3">
              <label for="firstname" class="field-label required">First Name</label>
              <input id="firstname"
                    type="text"
                    pInputText
                    formControlName="firstName">
              <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="p-error">
                <div *ngIf="firstName.errors?.['required']">Please enter your first name.</div>
                <div *ngIf="firstName?.errors?.['pattern']">The First Name contains invalid characters.</div>
              </div>
            </div>
            <div class="field col-12 md:col-4 xxl:col-3">
              <label for="lastname" class="field-label required">Last Name</label>
              <input id="lastname"
                    type="text"
                    pInputText
                    formControlName="lastName">
              <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="p-error">
                <div *ngIf="lastName.errors?.['required']">Please enter your last name.</div>
                <div *ngIf="lastName?.errors?.['pattern']">The Last Name contains invalid characters.</div>
              </div>
            </div>
            <div class="field col-12 md:col-4 xxl:col-3">
              <label for="email" class="field-label required">Email</label>
              <input id="email"
                    type="email"
                    pInputText
                    formControlName="email">
              <div *ngIf="email.invalid && (email.dirty || email.touched)"
                  class="p-error">
                <div *ngIf="email.errors?.['required']">Please enter your Email address.</div>
                <div *ngIf="email.errors?.['email']">Please enter a valid Email address.</div>
                <div *ngIf="email.errors?.['emailTaken']">
                  The Email address you entered is already in use.</div>
              </div>
            </div>
            <div class="col-12 xxl:col-3 mt-2 xxl:mt-0 flex align-items-center justify-content-start">
              <p-inputSwitch id="isAdmin" (click)="onAdminClick($event)" formControlName="isAdmin"></p-inputSwitch>
              <label for="isAdmin" class="field-label ml-2">Grant Admin Access</label>
            </div>
          </div>
        </p-card>
      </form>

      <p-card>
        <p-button 
          label="Next" 
          id="usersNextButton"
          severity="secondary"
          [disabled]="personalInfoForm.invalid"
          (onClick)="onStep1UserPanelNextClick($event)" />
      </p-card>

    </ng-template>
  </p-stepperPanel>

  <p-stepperPanel header="Manage Campaign Access">
    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
      <div *ngIf="showCampaignSearchLoader" class="loader-container">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div *ngIf="!showCampaignSearchLoader">

        <p-card class="campaign-search mt-3">

          <form [formGroup]="searchForm">
            <div class="search-bar mt-3 mb-5">
              <input type="text"
                id="campaignSearchInput"
                pInputText
                placeholder="Search campaigns"
                formControlName="search"
                (input)="filterCampaigns()" />
            </div>

            <div class="campaign-list">
              <div class="grid" *ngIf="filteredCampaigns && filteredCampaigns.length > 0">
                <ng-container *ngFor="let campaign of filteredCampaigns">
                  <div class="col-12 sm:col-6 md:col-4 xxl:col-3" *ngIf="!campaign.isEntityCampaign">
                    <p-checkbox [formControlName]="campaign.id"
                      (onChange)="onCampaignSelected($event, searchForm.controls[campaign.id], campaign.id, campaign.name, campaign.isEntityCampaign)"
                      binary="true"
                      [label]="campaign.name"></p-checkbox>
                  </div>

                  <ng-container *ngIf="campaign.isEntityCampaign">
                    <div class="col-12 sm:col-6 md:col-4 xxl:col-3" *ngFor="let childCampaign of campaign.childCampaigns">
                      <p-checkbox [formControlName]="campaign.id + '-' + childCampaign.id"
                        (onChange)="onChildCampaignSelected($event, searchForm.controls[campaign.id + '-' + childCampaign.id], campaign.id, campaign.name, campaign.isEntityCampaign, childCampaign.id, childCampaign.name)"
                        binary="true"
                        [label]="campaign.name + ' &mdash; ' + childCampaign.name"></p-checkbox>
                    </div>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          </form>
        </p-card>
        <p-card>
          <p-button label="Previous" id="campaignPrevBtn" severity="secondary" styleClass="p-button-outlined" (onClick)="prevCallback.emit()" />
          <p-button label="Next" id="campaignNextBtn" severity="secondary"[disabled]="continueToStep3()" (onClick)="nextCallback.emit()" />
        </p-card>
      </div>

    </ng-template>
  </p-stepperPanel>

  <p-stepperPanel header="Manage Campaign Permissions">
    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">

      
      <form [formGroup]="userInviteForm" (ngSubmit)="onSubmitUserInviteForm()">
        <div *ngIf="step3DataIsInitialized">

          <p-card>

            <div formGroupName="allAccess">
              <div class="grid bottom-dashed-divider mb-3">
                <div class="col-12 md:col-4 xxl:col-3 pr-5">
                  <div id="selectedCampaignsCount" class="flex align-content-center">
                    <span class="campaign-selected-title">Campaigns Selected: </span>
                    <span class="campaign-count">{{selectedCampaigns.length}}</span>
                  </div>
                </div>
                <div class="col">
                  <div class="flex align-content-center">
                    <i class="text-sm neutral-50">
                      Apply the following settings to ALL selected campaigns, if they share the same settings. You will be able to adjust settings to individual campaigns below.
                    </i>
                  </div>
                </div>
              </div>

              <!-- Apply All Vortex3  -->
              <div id="vortex3Settings" formGroupName="vortex3" class="expandable-div bottom-dashed-divider mb-3 pb-2">
                <!-- Div Header with expand/collapse button -->
                <div class="grid expandable-div-header">
                  <div class="col-12 sm:col-6 flex align-items-center">
                    <!--<p-toggleButton 
                      id="vortex3AccessToggleBtn"
                      name="vortex3AccessToggleBtn" 
                      onLabel="Enabled" 
                      offLabel="Disabled" 
                      onIcon="pi pi-check-circle"
                      offIcon="pi pi-ban" 
                      formControlName="vortex3AccessToggleBtn" />-->
                      
                    <p-button  id="vortex3AccessToggleBtn"
                        [label]="getAllAccessProductDisabled('vortex3')? 'Disabled': 'Enabled'"
                        [icon]="getAllAccessProductDisabled('vortex3') ? 'pi pi-ban' : 'pi pi-check-circle'"
                        [ngClass]="{'p-disabled': getAllAccessProductDisabled('vortex3') }"
                        (click)="onAllAccessProductClicked('vortex3')" 
                        styleClass="rc-togglebutton" /> 

                    <span class="product-name">Vortex 3</span>
                  </div>
                  <div class="col-12 sm:col-6 flex align-items-center justify-content-end">
                    <button pButton
                      icon="pi pi-info-circle info-button"
                      class="p-button-rounded p-button-text p-button-secondary"></button>
                    <strong class="inline-block px-3 text-lg">Feature Access</strong>
                    <button pButton
                      id="v3ToggleBtn"
                      icon="{{ isVortex3Collapsed ? 'pi pi-plus' : 'pi pi-minus' }}"
                      class="p-button-rounded p-button-text"
                      (click)="toggleVortex3Collapse()"></button>
                  </div>
                </div>

                <!-- Collapsible Content -->
                <div *ngIf="!isVortex3Collapsed">
                  <div class="grid expandable-div-content mt-3" fxLayoutAlign="start stretch">
                    <!-- Table 1 -->
                    <div class="col-12 lg:col-6 p-datatable">
                      <table class="p-datatable-table">
                        <thead role="rowgroup" class="p-datatable-thead">
                          <tr>
                            <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                            <th>Features</th>
                            <th>No Access</th>
                            <th>Read Only</th>
                            <th>Full Access</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup" class="p-element p-datatable-tbody">
                          <tr *ngFor="let feature of vortex3FirstTableFeatures">
                            <td>{{ feature.name }}</td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                [inputId]="getAllFeatureName(feature, vortex3)"
                                value="NoAccess"
                                (click)="onAllCampaignFeatureClick(feature, 'NoAccess')"
                                [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton *ngIf="showRadioButton(feature)" [name]="getAllFeatureName(feature, vortex3)"
                                [inputId]="getAllFeatureName(feature, vortex3)"
                                value="ReadOnly"
                                (click)="onAllCampaignFeatureClick(feature, 'ReadOnly')"
                                [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>

                              <span *ngIf="!showRadioButton(feature)">N/A</span>
                            </td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                [inputId]="getAllFeatureName(feature, vortex3)"
                                value="FullAccess"
                                (click)="onAllCampaignFeatureClick(feature, 'FullAccess')"
                                [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!-- Table 2 -->
                    <div class="col-12 lg:col-6 p-datatable">

                      <table class="p-datatable-table">
                        <thead role="rowgroup" class="p-datatable-thead">
                          <tr>
                            <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                            <th>Features</th>
                            <th>No Access</th>
                            <th>Read Only</th>
                            <th>Full Access</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup" class="p-element p-datatable-tbody">
                          <tr *ngFor="let feature of vortex3SecondTableFeatures">
                            <td>{{ feature.name }}</td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                [inputId]="getAllFeatureName(feature, vortex3)"
                                value="NoAccess"
                                (click)="onAllCampaignFeatureClick(feature, 'NoAccess')"
                                [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton *ngIf="showRadioButton(feature)" [name]="getAllFeatureName(feature, vortex3)"
                                [inputId]="getAllFeatureName(feature, vortex3)"
                                value="ReadOnly"
                                (click)="onAllCampaignFeatureClick(feature, 'ReadOnly')"
                                [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>

                              <span *ngIf="!showRadioButton(feature)">N/A</span>
                            </td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                [inputId]="getAllFeatureName(feature, vortex3)"
                                value="FullAccess"
                                (click)="onAllCampaignFeatureClick(feature, 'FullAccess')"
                                [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- TODO: remove this from here and any associated functions in the .ts file -->
                  <!-- <div formGroupName="gl" class="grid mt-2">
                    <div class="col-12 pb-0">
                      <h3>General Ledger Access</h3>
                    </div>
                    <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                      <label for="division">Division</label>
                      <input id="division" pInputText formControlName="division" placeholder="Division" />
                      <div *ngIf="getAllAccessVortex3GlDivision.invalid && getAllAccessVortex3GlDivision.touched" class="block p-error">
                        <div *ngIf="getAllAccessVortex3GlDivision.hasError('min')">Enter a value of at least 1.</div>
                        <div *ngIf="getAllAccessVortex3GlDivision.hasError('max')">Value must not exceed 9.</div>
                        <div *ngIf="getAllAccessVortex3GlDivision.hasError('pattern')">Only numbers are allowed.</div>
                      </div>
                    </div>

                    <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                      <label for="department">Department</label>
                      <input id="department" pInputText formControlName="department" placeholder="Department" />
                      <div *ngIf="getAllAccessVortex3GlDepartment.invalid && getAllAccessVortex3GlDepartment.touched" class="block p-error">
                        <div *ngIf="getAllAccessVortex3GlDepartment.hasError('min')">Enter a value of at least 10.</div>
                        <div *ngIf="getAllAccessVortex3GlDepartment.hasError('max')">Value must not exceed 99.</div>
                        <div *ngIf="getAllAccessVortex3GlDepartment.hasError('pattern')">Only numbers are allowed.</div>
                      </div>
                    </div>

                    <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                      <label for="subDepartment">Sub Department</label>
                      <input id="subDepartment" pInputText formControlName="subDepartment" placeholder="Sub Department" />
                      <div *ngIf="getAllAccessVortex3GlSubDepartment.invalid && getAllAccessVortex3GlSubDepartment.touched" class="block p-error">
                        <div *ngIf="getAllAccessVortex3GlSubDepartment.hasError('min')">Enter a value of at least 100.</div>
                        <div *ngIf="getAllAccessVortex3GlSubDepartment.hasError('max')">Value must not exceed 999.</div>
                        <div *ngIf="getAllAccessVortex3GlSubDepartment.hasError('pattern')">Only numbers are allowed.</div>
                      </div>
                    </div>

                    <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                      <label for="role">Reports To</label>
                      <div>
                        <p-dropdown [options]="usersList"
                                    label="Reports To"
                                    optionLabel="name"
                                    optionValue="id"
                                    formControlName="reportsTo"
                                    placeholder="Select a Person">

                        </p-dropdown>
                      </div>
                    </div>
                  </div> -->

                </div>
              </div>


              <!-- Apply All Vortex Lite  -->
              <div formGroupName="vortexLite" class="expandable-div bottom-dashed-divider mb-3 pb-2">
                <!-- Div Header with expand/collapse button-->
                <div class="grid expandable-div-header">
                  <div class="col-12 sm:col-6 flex align-items-center">
                    <!--<p-toggleButton 
                      id="vortexLiteAccessToggleBtn"
                      name="vortexLiteAccessToggleBtn" 
                      onLabel="Enabled" 
                      offLabel="Disabled" 
                      onIcon="pi pi-check-circle"
                      offIcon="pi pi-ban" 
                      formControlName="vortexLiteAccessToggleBtn" />-->
                    <p-button    id="vortexLiteAccessToggleBtn"
                          [label]="getAllAccessProductDisabled('vortexLite')? 'Disabled': 'Enabled'"
                          [icon]="getAllAccessProductDisabled('vortexLite') ? 'pi pi-ban' : 'pi pi-check-circle'"
                          [ngClass]="{'p-disabled': getAllAccessProductDisabled('vortexLite') }"
                          (click)="onAllAccessProductClicked('vortexLite')" 
                          styleClass="rc-togglebutton" /> 
                    <span class="product-name">Vortex Lite</span>
                  </div>
                  <div class="col-12 sm:col-6 flex align-items-center justify-content-end">
                    <button pButton
                      icon="pi pi-info-circle info-button"
                      class="p-button-rounded p-button-text p-button-secondary"></button>
                    <strong class="inline-block px-3 text-lg">Feature Access</strong>
                    <button pButton
                      id="liteToggleBtn"
                      icon="{{ isAllVortexLiteCollapsed ? 'pi pi-plus' : 'pi pi-minus' }}"
                      class="p-button-rounded p-button-text"
                      (click)="toggleVortexLiteCollapse()"></button>
                  </div>
                </div>

                <!--Collapsible Content-->
                <div *ngIf="!isAllVortexLiteCollapsed">
                  <div class="grid expandable-div-content mt-3" fxLayoutAlign="start stretch">
                    <!--  Table 1-->
                    <div class="col-12 lg:col-6 p-datatable">
                      <table class="p-datatable-table">
                        <thead role="rowgroup" class="p-datatable-thead">
                          <tr>
                            <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                            <th>Features</th>
                            <th>No Access</th>
                            <th>Read Only</th>
                            <th>Full Access</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup" class="p-element p-datatable-tbody">
                          <tr *ngFor="let feature of vortexLiteFirstTableFeatures">
                            <td>{{ feature.name }}</td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, vortexLite)"
                                [inputId]="getAllFeatureName(feature, vortexLite)"
                                value="NoAccess"
                                (click)="onAllVortexLiteFeatureClick(feature, 'NoAccess')"
                                [formControlName]="getAllFeatureName(feature, vortexLite)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, vortexLite)"
                                [inputId]="getAllFeatureName(feature, vortexLite)"
                                value="ReadOnly"
                                (click)="onAllVortexLiteFeatureClick(feature, 'ReadOnly')"
                                [formControlName]="getAllFeatureName(feature, vortexLite)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, vortexLite)"
                                [inputId]="getAllFeatureName(feature, vortexLite)"
                                value="FullAccess"
                                (click)="onAllVortexLiteFeatureClick(feature, 'FullAccess')"
                                [formControlName]="getAllFeatureName(feature, vortexLite)"></p-radioButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>

              <!-- Apply All Apex  -->
              <div formGroupName="apex" class="expandable-div bottom-dashed-divider mb-3 pb-2">
                <!-- Div Header with expand/collapse button-->
                <div class="grid expandable-div-header">
                  <div class="col-12 sm:col-6 flex align-items-center">
                    <!--<p-toggleButton 
                      id="vortex3AccessToggleBtn"
                      name="vortex3AccessToggleBtn" 
                      onLabel="Enabled" 
                      offLabel="Disabled" 
                      onIcon="pi pi-check-circle"
                      offIcon="pi pi-ban" 
                      formControlName="vortex3AccessToggleBtn" />-->

                    <p-button    id="apexAccessToggleBtn"
                          [label]="getAllAccessProductDisabled('apex')? 'Disabled': 'Enabled'"
                          [icon]="getAllAccessProductDisabled('apex') ? 'pi pi-ban' : 'pi pi-check-circle'"
                          [ngClass]="{'p-disabled': getAllAccessProductDisabled('apex') }"
                          (click)="onAllAccessProductClicked('apex')"
                          styleClass="rc-togglebutton" /> 

                    <span class="product-name">APEX</span>
                  </div>
                  <div class="col-12 sm:col-6 flex align-items-center justify-content-end">
                    <button pButton
                      icon="pi pi-info-circle info-button"
                      class="p-button-rounded p-button-text p-button-secondary"></button>
                    <strong class="inline-block px-3 text-lg">Feature Access</strong>
                    <button pButton
                      id="apxToggleBtn"
                      icon="{{ isAllApexCollapsed ? 'pi pi-plus' : 'pi pi-minus' }}"
                      class="p-button-rounded p-button-text"
                      (click)="toggleApexCollapse()"></button>
                  </div>
                </div>

                <!--Collapsible Content-->
                <div *ngIf="!isAllApexCollapsed">
                  <div class="grid expandable-div-content mt-3" fxLayoutAlign="start stretch">
                    <!-- Table 1-->
                    <div class="col-12 lg:col-6 p-datatable">
                      <table class="p-datatable-table">
                        <thead role="rowgroup" class="p-datatable-thead">
                          <tr>
                            <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                            <th>Features</th>
                            <th>No Access</th>
                            <th>Read Only</th>
                            <th>Full Access</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup" class="p-element p-datatable-tbody">
                          <tr *ngFor="let feature of apexFirstTableFeatures">
                            <td>{{ feature.name }}</td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, apex)"
                                [inputId]="getAllFeatureName(feature, apex)"
                                value="NoAccess"
                                (click)="onAllVortexLiteFeatureClick(feature, 'NoAccess')"
                                [formControlName]="getAllFeatureName(feature, apex)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, apex)"
                                [inputId]="getAllFeatureName(feature, apex)"
                                value="ReadOnly"
                                (click)="onAllVortexLiteFeatureClick(feature, 'ReadOnly')"
                                [formControlName]="getAllFeatureName(feature, apex)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton [name]="getAllFeatureName(feature, apex)"
                                [inputId]="getAllFeatureName(feature, apex)"
                                value="FullAccess"
                                (click)="onAllVortexLiteFeatureClick(feature, 'FullAccess')"
                                [formControlName]="getAllFeatureName(feature, apex)"></p-radioButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="mb-3 mt-3">
              <p-button size="large" (click)="applyAll($event)" label="Apply to All" />
            </div>

          </p-card>

          <p-accordion formArrayName="userInviteCampaigns" class="user-campaign-accordion">
            <!--<p-accordionTab *ngFor="let campaign of selectedCampaigns" [header]="campaign.name">-->
            <p-accordionTab *ngFor="let campaign of step3Campaigns.controls; let i = index; trackBy: trackByIndex" [formGroupName]="i" [header]="step3Campaigns.at(i).get('name')?.value || 'campaign'">

              <!-- Child Campaigns -->
              <div formArrayName="childCampaigns" *ngIf="showChildCampaigns(i)" class="grid expandable-div bottom-dashed-divider mb-3 pb-2">
                <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                  <strong>Child Campaigns:</strong>
                </div>
                <div class="col-12 sm:col-6 md:col-4 lg:col-3" *ngFor="let childCampaign of getChildCampaigns(i).controls; let j = index" [formGroupName]="j">
                  <p-checkbox binary="true"
                    [label]="getChildCampaignName(i,j)"
                    formControlName="selected"
                    [readonly]="true"></p-checkbox>
                </div>
              </div>

              <!-- Apply Campaign Vortex3  -->
              <div class="expandable-div bottom-dashed-divider mb-3 pb-2">
                <!-- Div Header with expand/collapse button -->
                <div class="grid expandable-div-header">
                  <div class="col-12 sm:col-6 flex align-items-center">
                    <!-- <p-toggleButton 
                      id="vortex3CampaignToggleBtn"
                      name="vortex3CampaignToggleBtn" 
                      onLabel="Enabled" 
                      offLabel="Disabled" 
                      onIcon="pi pi-check-circle"
                      offIcon="pi pi-ban" 
                      formControlName="vortex3CampaignToggleBtn" /> -->

                    <p-button [label]="getCampaignProductDisabled(i, 'campaignVortex3')? 'Disabled': 'Enabled'"
                      [icon]="getCampaignProductDisabled(i, 'campaignVortex3') ? 'pi pi-ban' : 'pi pi-check-circle'"
                      [ngClass]="{'p-disabled': getCampaignProductDisabled(i, 'campaignVortex3') }"
                      (click)="onCampaignProductClicked(i, 'campaignVortex3')"
                      styleClass="rc-togglebutton" />

                    <span class="product-name">Vortex 3</span>
                  </div>
                  <div class="col-12 sm:col-6 flex align-items-center justify-content-end">
                    <button pButton
                      icon="pi pi-info-circle info-button"
                      class="p-button-rounded p-button-text p-button-secondary"></button>
                    <strong class="inline-block px-3 text-lg">Feature Access</strong>
                    <button pButton
                      icon="{{ isVortex3ExpandedArray[i] ?  'pi pi-minus' : 'pi pi-plus' }}"
                      class="p-button-rounded p-button-text"
                      (click)="toggleVortex3ExpandIndex(i)"></button>
                  </div>
                </div>

                <!-- Collapsible Content -->
                <div *ngIf="isVortex3ExpandedArray[i]">
                  <div formGroupName="campaignVortex3" fxLayoutAlign="start stretch">
                    <div class="grid expandable-div-content mt-3">
                      <!-- Table 1 -->
                      <div class="col-12 lg:col-6 p-datatable">
                        <table class="p-datatable-table">
                          <thead role="rowgroup" class="p-datatable-thead">
                            <tr>
                              <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                              <th>Features</th>
                              <th>No Access</th>
                              <th>Read Only</th>
                              <th>Full Access</th>
                            </tr>
                          </thead>
                          <tbody role="rowgroup" class="p-element p-datatable-tbody">
                            <tr *ngFor="let feature of vortex3FirstTableFeatures">
                              <td>{{ feature.name }}</td>
                              <td>
                                <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                value="NoAccess"
                                                (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'NoAccess')"
                                                [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton *ngIf="showRadioButton(feature)" [name]="getFeatureName(campaign, feature, vortex3)"
                                                [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                value="ReadOnly"
                                                (click)="onCampaignFeatureClick('campaignVortex3',i, campaign, vortex3, feature, 'ReadOnly')"
                                                [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>

                                <span *ngIf="!showRadioButton(feature)">N/A</span>
                              </td>
                              <td>
                                <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                value="FullAccess"
                                                (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'FullAccess')"
                                                [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <!-- Table 2 -->
                      <div class="col-12 lg:col-6 p-datatable">
                        <table class="p-datatable-table">
                          <thead role="rowgroup" class="p-datatable-thead">
                            <tr>
                              <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                              <th>Features</th>
                              <th>No Access</th>
                              <th>Read Only</th>
                              <th>Full Access</th>
                            </tr>
                          </thead>
                          <tbody role="rowgroup" class="p-element p-datatable-tbody">
                            <tr *ngFor="let feature of vortex3SecondTableFeatures">
                              <td>{{ feature.name }}</td>
                              <td>
                                <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                value="NoAccess"
                                                (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'NoAccess')"
                                                [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton *ngIf="showRadioButton(feature)" [name]="getFeatureName(campaign, feature, vortex3)"
                                                [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                value="ReadOnly"
                                                (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'ReadOnly')"
                                                [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>

                                <span *ngIf="!showRadioButton(feature)">N/A</span>
                              </td>
                              <td>
                                <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                value="FullAccess"
                                                (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'FullAccess')"
                                                [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div formGroupName="gl" class="grid mt-2">
                      <div class="col-12">
                        <strong class="text-lg redwood-80">General Ledger Access</strong>
                      </div>
                      <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                        <label for="division">Division</label>
                        <input id="division" pInputText formControlName="division" placeholder="" />
                        <div *ngIf="getCampaignVortex3GlControl(i, 'division').invalid && getCampaignVortex3GlControl(i, 'division').touched" class="block p-error">
                          <div *ngIf="getCampaignVortex3GlControl(i, 'division').hasError('min')">Enter a value of at least 1.</div>
                          <div *ngIf="getCampaignVortex3GlControl(i, 'division').hasError('max')">Value must not exceed 9.</div>
                          <div *ngIf="getCampaignVortex3GlControl(i, 'division').hasError('pattern')">Only numbers are allowed.</div>
                        </div>
                      </div>

                      <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                        <label for="department">Department</label>
                        <input id="department" pInputText formControlName="department" placeholder="" />
                        <div *ngIf="getCampaignVortex3GlControl(i, 'department').invalid && getCampaignVortex3GlControl(i, 'department').touched" class="block p-error">
                          <div *ngIf="getCampaignVortex3GlControl(i, 'department').hasError('min')">Enter a value of at least 10.</div>
                          <div *ngIf="getCampaignVortex3GlControl(i, 'department').hasError('max')">Value must not exceed 99.</div>
                          <div *ngIf="getCampaignVortex3GlControl(i, 'department').hasError('pattern')">Only numbers are allowed.</div>
                        </div>
                      </div>

                      <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                        <label for="subDepartment">Sub Department</label>
                        <input id="subDepartment" pInputText formControlName="subDepartment" placeholder="" />
                        <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').invalid && getCampaignVortex3GlControl(i, 'subDepartment').touched" class="block p-error">
                          <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').hasError('min')">Enter a value of at least 100.</div>
                          <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').hasError('max')">Value must not exceed 999.</div>
                          <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').hasError('pattern')">Only numbers are allowed.</div>
                        </div>
                      </div>

                      <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                        <label for="role">Reports To</label>
                        <div>
                          <p-dropdown [options]="usersList"
                                      label="Reports To"
                                      formControlName="reportsTo"
                                      optionLabel="name"
                                      optionValue="id"
                                      placeholder="Select a Person">

                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <!-- Apply Campaign Vortex Lite  -->
              <div formGroupName="campaignVortexLite" class="expandable-div bottom-dashed-divider mb-3 pb-2">
                <!-- Div Header with expand/collapse button-->
                <div class="grid expandable-div-header">
                  <div class="col-12 sm:col-6 flex align-items-center">
                    <!-- <p-toggleButton 
                      id="vortex3CampaignToggleBtn"
                      name="vortex3CampaignToggleBtn" 
                      onLabel="Enabled" 
                      offLabel="Disabled" 
                      onIcon="pi pi-check-circle"
                      offIcon="pi pi-ban" 
                      formControlName="vortex3CampaignToggleBtn" /> -->

                    <p-button [label]="getCampaignProductDisabled(i, 'campaignVortexLite')? 'Disabled': 'Enabled'"
                      [icon]="getCampaignProductDisabled(i, 'campaignVortexLite') ? 'pi pi-ban' : 'pi pi-check-circle'"
                      [ngClass]="{'p-disabled': getCampaignProductDisabled(i, 'campaignVortexLite') }"
                      (click)="onCampaignProductClicked(i, 'campaignVortexLite')"
                      styleClass="rc-togglebutton" />

                    <span class="product-name">Vortex Lite</span>
                  </div>
                  <div class="col-12 sm:col-6 flex align-items-center justify-content-end">
                    <button pButton
                      icon="pi pi-info-circle info-button"
                      class="p-button-rounded p-button-text p-button-secondary"></button>
                    <strong class="inline-block px-3 text-lg">Feature Access</strong>
                    <button pButton
                      icon="{{ isVortexLiteExpandedArray[i] ?  'pi pi-minus' : 'pi pi-plus' }}"
                      class="p-button-rounded p-button-text"
                      (click)="toggleVortexLiteExpandIndex(i)"></button>
                  </div>
                </div>

                <!--Collapsible Content-->
                <div *ngIf="isVortexLiteExpandedArray[i]">
                  <div class="grid expandable-div-content mt-3" fxLayoutAlign="start stretch">
                    <!--  Table 1-->
                    <div class="col-12 lg:col-6 p-datatable">
                      <table class="p-datatable-table">
                        <thead role="rowgroup" class="p-datatable-thead">
                          <tr>
                            <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                            <th>Features</th>
                            <th>No Access</th>
                            <th>Read Only</th>
                            <th>Full Access</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup" class="p-element p-datatable-tbody">
                          <tr *ngFor="let feature of vortexLiteFirstTableFeatures">
                            <td>{{ feature.name }}</td>
                            <td>
                              <p-radioButton [name]="getFeatureName(campaign, feature, vortexLite)"
                                [inputId]="getFeatureName(campaign, feature, vortexLite)"
                                value="NoAccess"
                                (click)="onCampaignFeatureClick('campaignVortexLite', i, campaign, vortexLite, feature, 'NoAccess')"
                                [formControlName]="getFeatureName(campaign, feature, vortexLite)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton [name]="getFeatureName(campaign, feature, vortexLite)"
                                [inputId]="getFeatureName(campaign, feature, vortexLite)"
                                value="ReadOnly"
                                (click)="onCampaignFeatureClick('campaignVortexLite', i, campaign, vortexLite, feature, 'ReadOnly')"
                                [formControlName]="getFeatureName(campaign, feature, vortexLite)"></p-radioButton>

                            </td>
                            <td>
                              <p-radioButton [name]="getFeatureName(campaign, feature, vortexLite)"
                                [inputId]="getFeatureName(campaign, feature, vortexLite)"
                                value="FullAccess"
                                (click)="onCampaignFeatureClick('campaignVortexLite', i, campaign, vortexLite, feature, 'FullAccess')"
                                [formControlName]="getFeatureName(campaign, feature, vortexLite)"></p-radioButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>

              <!-- Apply All Apex  -->
              <div formGroupName="campaignApex" class="expandable-div">
                <!-- Div Header with expand/collapse button-->
                <div class="grid expandable-div-header">
                  <div class="col-12 sm:col-6 flex align-items-center">
                    <!-- <p-toggleButton 
                      id="vortex3CampaignToggleBtn"
                      name="vortex3CampaignToggleBtn" 
                      onLabel="Enabled" 
                      offLabel="Disabled" 
                      onIcon="pi pi-check-circle"
                      offIcon="pi pi-ban" 
                      formControlName="vortex3CampaignToggleBtn" /> -->
                    <p-button [label]="getCampaignProductDisabled(i, 'campaignApex')? 'Disabled': 'Enabled'"
                      [icon]="getCampaignProductDisabled(i, 'campaignApex') ? 'pi pi-ban' : 'pi pi-check-circle'"
                      [ngClass]="{'p-disabled': getCampaignProductDisabled(i, 'campaignApex') }"
                      (click)="onCampaignProductClicked(i, 'campaignApex')" 
                      styleClass="rc-togglebutton" />
                      
                    <span class="product-name">APEX</span>
                  </div>
                  <div class="col-12 sm:col-6 flex align-items-center justify-content-end">
                    <button pButton
                      icon="pi pi-info-circle info-button"
                      class="p-button-rounded p-button-text p-button-secondary"></button>
                    <strong class="inline-block px-3 text-lg">Feature Access</strong>
                    <button pButton
                      icon="{{ isApexExpandedArray[i] ?  'pi pi-minus' : 'pi pi-plus' }}"
                      class="p-button-rounded p-button-text"
                      (click)="toggleApexExpandIndex(i)"></button>
                  </div>
                </div>

                <!--Collapsible Content-->
                <div *ngIf="isApexExpandedArray[i]">
                  <div class="grid expandable-div-content mt-3" fxLayoutAlign="start stretch">
                    <!-- Table 1-->
                    <div class="col-12 lg:col-6 p-datatable">
                      <table class="p-datatable-table">
                        <thead role="rowgroup" class="p-datatable-thead">
                          <tr>
                            <!-- <th>
                              <span>Features</span>
                            </th>
                            <th>
                              <span>No Access</span>
                              <p-radioButton
                                value="NoAccessAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Read Only</span>
                              <p-radioButton
                                value="ReadOnlyAll"
                                ></p-radioButton>
                            </th>
                            <th>
                              <span>Full Access</span>
                              <p-radioButton
                                value="FullAccessAll"
                                ></p-radioButton>
                            </th> -->
                            <th>Features</th>
                            <th>No Access</th>
                            <th>Read Only</th>
                            <th>Full Access</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup" class="p-element p-datatable-tbody">
                          <tr *ngFor="let feature of apexFirstTableFeatures">
                            <td>{{ feature.name }}</td>
                            <td>
                              <p-radioButton [name]="getFeatureName(campaign, feature, apex)"
                                [inputId]="getFeatureName(campaign, feature, apex)"
                                value="NoAccess"
                                (click)="onCampaignFeatureClick('campaignApex', i, campaign, apex, feature, 'NoAccess')"
                                [formControlName]="getFeatureName(campaign, feature, apex)"></p-radioButton>
                            </td>
                            <td>
                              <p-radioButton [name]="getFeatureName(campaign, feature, apex)"
                                [inputId]="getFeatureName(campaign, feature, apex)"
                                value="ReadOnly"
                                (click)="onCampaignFeatureClick('campaignApex', i, campaign, apex, feature, 'ReadOnly')"
                                [formControlName]="getFeatureName(campaign, feature, apex)"></p-radioButton>

                            </td>
                            <td>
                              <p-radioButton [name]="getFeatureName(campaign, feature, apex)"
                                [inputId]="getFeatureName(campaign, feature, apex)"
                                value="FullAccess"
                                (click)="onCampaignFeatureClick('campaignApex', i, campaign, apex, feature, 'FullAccess')"
                                [formControlName]="getFeatureName(campaign, feature, apex)"></p-radioButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </form>
      <p-card>
        <p-button label="Previous" id="inviteUserPreviousBtn" severity="secondary" styleClass="p-button-outlined" (onClick)="prevCallback.emit()" />
      </p-card>

    </ng-template>
  </p-stepperPanel>
</p-stepper>
<p-card styleClass="page-footer">
  <!-- TODO: do we need a cancel button here, where will it go? -->
  <!-- <rc-generic-button label="Cancel" borderStyle="text-only" /> -->
  <p-button label="Send Invite" id="inviteUserNextBtn" [disabled]="userInviteStepper.activeStep !== 2" (onClick)="inviteUser($event)" />
</p-card>
