
<p-panelMenu [model]="model.navigation.applicationNavset">
  <ng-template pTemplate="item" let-item>
    <a class="main-nav-item" *ngIf="!item.route" [id]="(item.idLabel || 'side_menu_' + item.label.toLowerCase())" [attr.data-cy]="item.dataCy" role="menuitem">
      <i class="icon section-icon {{item.icon}}" *ngIf="item.icon"></i>
      <div class="{{getNavVizState(item)}} item-title">{{ item.label }}</div>
      <i class="fa-sharp fa-regular fa-chevron-down expand-chevron icon" *ngIf="item.items?.length && item.expanded"></i>
      <i class="fa-sharp fa-regular fa-chevron-right expand-chevron icon" *ngIf="item.items?.length && !item.expanded"></i>
    </a>
    <a class="main-nav-item" [routerLink]=[item.route] *ngIf="item.route" [id]="(item.idLabel || 'side_menu_' + item.label.toLowerCase())" [attr.data-cy]="item.dataCy" role="menuitem">
      <i class="icon section-icon {{item.icon}}" *ngIf="item.icon"></i>
      <div class="{{getNavVizState(item)}} item-title">{{ item.label }}</div>
      <i class="fa-sharp fa-regular fa-chevron-down expand-chevron icon" *ngIf="item.items?.length && item.expanded"></i>
      <i class="fa-sharp fa-regular fa-chevron-right expand-chevron icon" *ngIf="item.items?.length && !item.expanded"></i>
    </a>
    <!-- {{item | json}} -->
  </ng-template>
</p-panelMenu>
