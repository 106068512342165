<form [formGroup]="loginForm" (ngSubmit)="loginUser()">
  <p-toast />
  <div class="login-page-wrapper grid">

    <div class="login-container-hero col-12 md:col-6 lg:col-7 xl:col-8 xxl:col-3">
      <div class="login-logo">
        <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
        <ul class="pl-0 hidden md:block">
          <li>Marketing messaging here.</li>
          <li>New Vortex 3, Vortex Lite and APEX!</li>
          <li>Lorem ipsum felis nec pharetra.</li>
        </ul>
      </div>
      <div class="login-footer-desktop hidden md:block">
        <span>Copyright &copy; 2025</span> |
        <span>RedCurve Solutions &reg;</span> |
        <span>All Rights Reserved</span>
      </div>
    </div>

    <div class="login-container-form col-12 md:col-6 lg:col-5 xl:col-4 xxl:col-3 flex-column md:flex-row">
      <p-card styleClass="py-2 md:py-4">
        <h1 class="h3">Account Login</h1>
        <div class="field">
          <label for="email">Email</label>
          <input type="text"
                 pInputText
                 formControlName="email"
                 class="w-full"
                 id="login-email"
                 name="email"
                 placeholder="Enter your email" />
          <small *ngIf="email.invalid && (email.dirty || email.touched)"
                 class="block p-error">
            <div *ngIf="email.errors?.['required']">Please provide your email address to proceed.</div>
            <div *ngIf="email.errors?.['email']">The email address you entered is not valid.</div>
          </small>
        </div>
        <div class="field">
          <label for="password">Password</label>
          <input type="password"
                 pInputText
                 formControlName="password"
                 class="w-full"
                 id="login-password"
                 name="password"
                 placeholder="Enter your password" />
          <small *ngIf="password.invalid && (password.dirty || password.touched)"
                 class="block p-error">
            <div *ngIf="password.errors?.['required']">Your password is required to log in.</div>
          </small>
        </div>
        <div class="text-center mt-4">
          <p-button label="Log in"
                    styleClass=""
                    [disabled]="loginForm.invalid"
                    type="submit"
                    id="login-button-sign-in"></p-button>
        </div>
        <div class="account-help text-center mt-4">
          <a routerLink="/forgot-password">Forgot password?</a> | <a href="#" (click)="openHelpModal($event)">Need an Account?</a>
        </div>
        <!--<div class="mt-2">Not a member? <a routerLink="/register">Register</a></div>-->
      </p-card>

      <div class="login-footer-mobile block md:hidden">
        <span>Copyright &copy; 2025</span> |
        <span>RedCurve Solutions &reg;</span> |
        <span>All Rights Reserved</span>
      </div>
    </div>
  </div>
</form>

<!--<p-dialog header="Two-Factor Authentication" [(visible)]="show2FA">
  <app-two-factor [email]="emailAddress"></app-two-factor>
</p-dialog>-->
<app-help-modal [visible]="isHelpModalVisible"></app-help-modal>
