<div class="flex justify-content-center align-items-center" style="height: 100vh;">
  <p-toast />
  <p-card class="w-3" styleClass="card-style-account">
    <ng-template pTemplate="header">
      <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
    </ng-template>

    <div class="flex justify-content-center">
      <span class="h3">Phone Number Verification</span>
    </div>

    <form [formGroup]="verificationForm">
      <div class="field">
        <label for="phoneNumber">Phone Number</label>
        <div class="p-inputgroup">
          <input type="text" pInputText formControlName="phoneNumber" placeholder="Enter phone number" [disabled]="isCodeSent" />
        </div>
      </div>

      <div class="flex justify-content-end">
        <!-- Disable Send Code button if phone number is invalid -->
        <button pButton
                type="button"
                label="Send Code"
                (click)="sendVerificationCode()"
                [disabled]="verificationForm.controls['phoneNumber'].invalid">
        </button>
      </div>

      <div class="field mt-3">
        <label for="verificationCode">Verification Code</label>
        <div class="p-inputgroup">
          <input type="text" pInputText formControlName="verificationCode" placeholder="Enter code" [disabled]="!isCodeSent" />
        </div>
      </div>

      <div class="flex justify-content-end">
        <button pButton
                type="button"
                label="Verify"
                (click)="verifyCode()"
                [disabled]="!isCodeSent || verificationForm.get('verificationCode')?.invalid">
        </button>
      </div>
    </form>
  </p-card>
</div>
