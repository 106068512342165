import { Component, effect, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation  } from 'angular-animations';
import { MainModel } from './global/model/MainModel';
import { ServerClientService } from './global/services/data/CampaignService/server-client.service';
import App from './global/model/App';
import Campaign from './global/model/Campaign';
import { NavService } from './global/services/ui/NavService/NavService';
import { JsonPipe } from '@angular/common';
import { Observable, tap, of, debounceTime } from 'rxjs';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    JsonPipe
],
  animations: [
    fadeInOnEnterAnimation(), 
    fadeOutOnLeaveAnimation(), 
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    protected model:MainModel,
    private serverClient:ServerClientService,
    private navService:NavService,
    private activatedRoute:ActivatedRoute,
    protected router:Router,
  ){

    /**
     * On Campaign Selection we:
     * 1. save it as the last accessed campaign
     * 2. get aplications and features as per the campaign
     */
    effect(() => {
      const c:Campaign|null|undefined = this.model.selectedCampaign();
      console.log(`The camp is: ${this.model.selectedCampaign()?.campaignId}`);
      if(c){
        this.navService.saveLasAccessedCampaign(c);
      }
      });
    };


    /**
     * On every page load we check to see that we have:
     * 1. list of campaigns
     * 2. last (most recently) accessed campaigns
     */
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(this.router.url !== "/login"){
          this.navService?.checkForRequiredModels(this.model.selectedCampaign());
        }
      }
    });
  }
}
