
<!-- Fly out content -->
<ng-container *ngIf="isDockable === true">
    <div class="flex justify-content-end m-auto pr-2">
        <p class="selected-campign-title">
            <button class="p-button p-button-text text-neutral-60 flex align-items-center" (click)="toggle($event)">
                <span class="p-inline-block mr-2 font-light text-lg">{{model.selectedCampaign()?.name || "Select a Campaign"}}</span>
                <i class="fa-sharp fa-thumbtack"></i>
            </button>
        </p>
    </div>

    <p-sidebar [(visible)]="isSidebarVisible" position="right" >
        <ng-template pTemplate="header">
            <span class="h3 m-0">Select Campaign</span>
        </ng-template>

        <div class="formgrid grid">
            <div class="field col-12 sm:col-6 md:col-4">
                <p-iconField iconPosition="left">
                    <p-inputIcon styleClass="pi pi-search" />
                    <input id="donorQuickfind" type="text" pInputText class="input-quickfind" placeholder="Type to find campaign" /> 
                    <small id="donorQuickfind-error" class="error hidden">
                        Error message example
                    </small>
                </p-iconField>
            </div>
            <div class="field col-12 sm:col-6 md:col-8 flex align-items-center">
                <!-- TODO: [(ngModel)]="checked"  -->
                <p-checkbox 
                    
                    [binary]="true"
                    inputId="inactiveCampaignsCheck"
                    label="Show Inactive Campaigns" />
            </div>
        </div>

        <div class="grid recent-campaigns">
            <div class="col sm:col-12 md:col-3">
                <span class="h5">Recent:</span>
            </div>
            <div class="col sm:col-4 md:col-3">
                <span>Campaign 1</span>
            </div>
            <div class="col sm:col-4 md:col-3">
                <span>Campaign 2</span>
            </div>
            <div class="col sm:col-4 md:col-3">
                <span>Campaign 3</span>
            </div>
        </div>
        
        <p-card>
            <ul class="grid campaign-switcher-grid" >
                <li *ngFor="let item of model.campaigns" class="col-12 sm:col-6 md:col-3">
                    <span (click)="selectCampaign(item)" [ngClass]="getClass(item)">
                        {{ item.name }}
                    </span>
                </li>
            </ul>
        </p-card>
    </p-sidebar>
</ng-container>

<!-- In page content -->
<ng-container *ngIf="isDockable === false">

    <h1>Select Campaign</h1>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-6 xl:col-4">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input id="donorQuickfind" type="text" pInputText class="input-quickfind" placeholder="Type to find campaign" /> 
                <small id="donorQuickfind-error" class="error hidden">
                    Error message example
                </small>
            </p-iconField>
        </div>
        <div class="field col-12 sm:col-6 xl:col-8 flex align-items-center">
            <!-- TODO: [(ngModel)]="checked"  -->
            <p-checkbox 
                
                [binary]="true"
                inputId="inactiveCampaignsCheck"
                label="Show Inactive Campaigns" />
        </div>
    </div>

    <div class="grid recent-campaigns">
        <div class="col sm:col-12 md:col-3">
            <span class="h5">Recent:</span>
        </div>
        <div class="col sm:col-4 md:col-3">
            <span>Campaign 1</span>
        </div>
        <div class="col sm:col-4 md:col-3">
            <span>Campaign 2</span>
        </div>
        <div class="col sm:col-4 md:col-3">
            <span>Campaign 3</span>
        </div>
    </div>

    <p-card>
        <ul class="grid campaign-switcher-grid">
            <li *ngFor="let item of model.campaigns" class="col-12 sm:col-6 md:col-4 xl:col-3">
                <span (click)="selectCampaign(item)" [ngClass]="getClass(item)">
                    {{ item.name }}
                </span>
            </li>
        </ul>
    </p-card>

</ng-container>
<!-- 
<div style="position:fixed;left:0px;top:0px;right:0px;bottom:0px;z-index: 10;">
{{model.campaigns | json}}
</div> -->