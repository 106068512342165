<h1>Donor Card</h1>
<p>This is donor-call-sheets! Move component below to Donor Card</p>

<p-tabView styleClass="donor-card-tabs">
    <p-tabPanel header="General">
        <div class="grid flex-nowrap justify-content-between mx-3">
            <div class="col hidden sm:flex align-items-center">
                <!-- Conditional -->
                <i class="fa-sharp fa-light fa-circle-user donor-type-icon"></i>
                <!-- <i class="fa-sharp fa-light fa-landmark donor-type-icon"></i> -->
            </div>
            <div class="col flex align-items-center" style="flex-grow: 6;">
                <div class="">
                    <p class="text-xl md:text-2xl font-bold red-60">Mr. Barnaby Smith Jr.</p>
                    <p class="mb-0">30878765433</p>
                    <p>Boston, MA</p>
                </div>
            </div>
            <div class="col flex align-items-center justify-content-end">
                <p-button 
                    styleClass="p-button-icon-teal-light mx-1" 
                    icon="fa-sharp fa-light fa-pencil"
                    [rounded]="true"
                    title="Delete" />
                <p-button 
                    styleClass="p-button-icon-red-light mx-1" 
                    icon="fa-sharp fa-light fa-trash-can"
                    [rounded]="true"
                    title="Delete" />

            </div>
        </div>

        <hr class="divider-dashed mt-0 mb-5">

        <div class="grid mx-3">
            <div class="col-6">
                <p><b>Employer:</b> Petco Co</p>
                <p><b>Occupation:</b> Junior Manager</p>
                <p><b>DOB:</b> 7/04/1988</p>
                <p><b>Inception:</b> 6/18/2022 ($1,000) Anedot</p>
            </div>
            <div class="col-6">
                <p><b>Fundraiser:</b> Nancy Paulson (87644)</p>
                <p><b>Legacy ID:</b> 98765543388</p>
                <p><b>FEC Candidate ID:</b> N/A</p>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Contact">
        <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
            ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
        </p>
    </p-tabPanel>
    <p-tabPanel header="Donor Letters">
        <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
            qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
        </p>
    </p-tabPanel>
    <p-tabPanel header="Call Sheets Log">
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
    </p-tabPanel>
    <p-tabPanel header="Notes">
        <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
            ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
        </p>
    </p-tabPanel>
    <p-tabPanel header="Donor Flags">
        <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
            qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
        </p>
    </p-tabPanel>
    <p-tabPanel header="Salutation">
        <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
            qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
        </p>
    </p-tabPanel>
    <p-tabPanel header="Supression">
        <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
            qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
        </p>
    </p-tabPanel>
</p-tabView>