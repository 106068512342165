import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './global/page/page-not-found/page-not-found.component';
import { SearchPageComponent } from './global/page/search/search.page.component';
import { CampaignComponent } from './global/page/campaign/campaign.component';
import { DonorAddComponent } from './vortex/page/donor-add/donor-add.component';
import { DonorBulkUpdateComponent } from './vortex/page/donor-bulk-update/donor-bulk-update.component';
import { DonorManualDedupesComponent } from './vortex/page/donor-manual-dedupes/donor-manual-dedupes.component';
import { DonorFlagsComponent } from './vortex/page/donor-flags/donor-flags.component';
import { DonorCallSheetsComponent } from './vortex/page/donor-call-sheets/donor-call-sheets.component';
import { LoginComponent } from './global/page/login/login.component';
import { RegisterComponent } from './global/page/register/register.component';
import { DashboardComponent } from './global/page/dashboard/dashboard.component';
import { authGuard } from './vortex/guards/auth.guard';
import { UserCampaignInviteComponent } from './vortex/page/user-campaign-invite/user-campaign-invite.component';
import { StyleguideComponent } from './global/page/styleguide/styleguide.component';
import { DefaultComponent } from './template/default/default.component';
import { FullPageComponent } from './template/full-page/full-page.component';
import { SelectCampaignComponent } from './global/page/select-campaign/select-campaign.component';
import { PostLoginLoadingComponent } from './global/page/post-login-loading/post-login-loading.component';
import { DonorCardComponent } from './vortex/page/donor-card/donor-card.component';


let urlMap:Map<string, string>|null = null;

export const routes: Routes = [

    {
        path: 'login',
        component: FullPageComponent,
        children: [
            { 
                    path: '', 
                    component: LoginComponent 
            },
        ]
        }, 
        {
        path: 'register',
        component: FullPageComponent,
        data: {
            breadcrumb: "Register",
        },
        children: [
            { 
                    path: '', 
                    component: RegisterComponent 
            },
        ]
        },
        {
        path: 'loading',
        component: FullPageComponent,
        data: {
            breadcrumb: "Register",
        },
        children: [
            { 
                path: '',
                component: PostLoginLoadingComponent,
                canActivate: [authGuard]
            },
        ]
        },
      {
        path: '',
        component: DefaultComponent,
        children: [
            {
                path: 'campaign-selection',
                data: {
                    breadcrumb: "Select a Campaign"
                },
                component: SelectCampaignComponent,
                canActivate: [authGuard]
            },
            { 
                path: "vortex", 
                data: {
                    breadcrumb: "Home",
                },
                component: CampaignComponent,
                canActivate: [authGuard]
            },

            // DONORS -------------------------------------------------------------------------------------------------------------
            {
                path: "vortex/campaign-dashboard", 
                component: DashboardComponent, 
                pathMatch: "full",
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Campaign Dashboard",
                }
            },
            {
                path: "vortex/donors", 
                component: SearchPageComponent, 
                pathMatch:"full",
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donors",
                }
            },
            {
              path: "vortex/donors/card/:id",
              component: DonorCardComponent,
              canActivate: [authGuard],
              data: {
                breadcrumb: "Donor"
              }
            },
            {
                path: "vortex/donors/add", 
                component: DonorAddComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Add Donor",
                },
            },
            {
                path: "vortex/donors/update",   
                component: DonorBulkUpdateComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Update Donor",
                },
            },
            {
                path: "vortex/donors/dedupe", 
                component: DonorManualDedupesComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Dedupe Donors",
                },
            },
            {
                path: "vortex/donors/flags", 
                component: DonorFlagsComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donor Flags",
                },
            },
            {
                path: "vortex/donors/call-sheets", 
                component: DonorCallSheetsComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donor Call Sheets",
                },
            },
            {
                path: 'vortex/user-campaign-invite',
                component: UserCampaignInviteComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: 'User Campaign Invite'
                },
            },
        
        
            // DONATIONS -------------------------------------------------------------------------
        
        
            { 
                path: "vortex/donations", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donations",
                }
            },
            {
                path: "vortex/donations/add", 
                loadComponent: () => import('./vortex/page/donations-add/donations-add.component').then( (x) => x.DonationsAddComponent ),
                canActivate: [authGuard],
                    data: {
                        breadcrumb: "Add Donation",
                        },
            },
            {
                path: "vortex/donations/upload", 
                loadComponent: () => import('./vortex/page/donations-bulk-update/donations-bulk-update.component').then( (x) => x.DonationsBulkUpdateComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Upload Donation",
                    },
            },
            {
                path: "vortex/donations/batches", 
                loadComponent: () => import('./vortex/page/donations-batches/donations-batches.component').then( (x) => x.DonationsBatchesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donation Batches",
                    },
            },
            {
                path: "vortex/donations/flags", 
                loadComponent: () => import('./vortex/page/donations-flags/donations-flags.component').then( (x) => x.DonationsFlagsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donation Flags",
                    },
            },
            {
                path: "vortex/donations/compliance-letters", 
                loadComponent: () => import('./vortex/page/donations-compliance-letters/donations-compliance-letters.component').then( (x) => x.DonationsComplianceLettersComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Complianmce Letters",
                    },
            },
        
            // FUNDRAISERS -------------------------------------------------------------------------------------------------------------
        
        
            { 
                path: "vortex/fundraisers", 
                component: SearchPageComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraisers",
                }
            },
            {
                path: "vortex/fundraisers/events", 
                loadComponent: () => import('./vortex/page/fundraisers-events/fundraisers-events.component').then( (x) => x.FundraisersEventsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Events",
                    },
            },
            {
                path: "vortex/fundraisers/initiatives", 
                loadComponent: () => import('./vortex/page/fundraisers-initiatives/fundraisers-initiatives.component').then( (x) => x.FundraisersInitiativesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Initiatives",
                    },
            },
        
            { 
                path: "vortex/expenses", 
                loadComponent: () => import('./vortex/page/expenses/expenses.component').then( (x) => x.ExpensesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Expenses",
                }
            },
        
            { 
                path: "vortex/reporting-center", 
                loadComponent: () => import('./vortex/page/expenses/expenses.component').then( (x) => x.ExpensesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Reporting Center",
                }
            },
        
            { 
                path: "vortex/fec/vortex", 
                loadComponent: () => import('./vortex/page/fec-vortex/fec-vortex.component').then( (x) => x.FecVortexComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Vortex",
                }
            },
            { 
                path: "vortex/fec/settings", 
                loadComponent: () => import('./vortex/page/fec-settings/fec-settings.component').then( (x) => x.FecSettingsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Vortex",
                }
            },
            { 
                path: "vortex/fec/report-cleanup", 
                loadComponent: () => import('./vortex/page/fec-report-cleanup/fec-report-cleanup.component').then( (x) => x.FecReportCleanupComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Report Cleanup",
                }
            },
            { 
                path: "vortex/jfc/allocations", 
                loadComponent: () => import('./vortex/page/jfc-allocations/jfc-allocations.component').then( (x) => x.JfcAllocationsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Allocations",
                }
            },
            { 
                path: "vortex/jfc/batches", 
                loadComponent: () => import('./vortex/page/jfc-batches/jfc-batches.component').then( (x) => x.JfcBatchesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Batches",
                }
            },
        
            // VORTEX - LITE -----------------------------
        
            
            { 
                path: "vortex-lite", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraising Tally",
                }
            },
            { 
                path: "vortex-lite/fundraising-tally", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraising Tally",
                }
            },
            { 
                path: "vortex-lite/events", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Events",
                }
            },
            { 
                path: "vortex-lite/fundraisers", 
                loadComponent: () => import('./global/page/donor/donor.component').then( (x) => x.DonorComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraiser",
                }
            },
            { 
                path: "vortex-lite/donor-flags", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donor Flags",
                }
            },
            { 
                path: "vortex-lite/club-settings", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Club Settings",
                }
            },
        
        //  APEX ---------------------------------------------------------------------------------------------------
            
            
            { 
                path: "apex/my-widgets", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "My Widgets",
                }
            },
            { 
                path: "settings", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Settings",
                }
            },
        
            //  ADMIN ---------------------------------------------------------------------------------------------
        
            { 
                path: "admin", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Admin",
                },
                children: [
                    { 
                        path: "donors", 
                        component: PageNotFoundComponent,
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Donors",
                        },
                        children: [
                            { 
                                path: "donors-and-donations", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Donors And Donations",
                                }
                            },
                        ]
                    },

                    
                    { 
                        path: "campaign-settings", 
                        component: PageNotFoundComponent,
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Campaign Settings",
                        },
                        children: [
                            { 
                                path: "campaign-list", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Campaign List",
                                }
                            },
                            { 
                                path: "fund-codes", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Fund Codes",
                                }
                            },
                            { 
                                path: "donation-source-rates", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Donation Source Rates",
                                }
                            },
                            { 
                                path: "accounting-system", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Accounting System",
                                }
                            },
                            { 
                                path: "compliance", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Compliance",
                                }
                            },
                            { 
                                path: "search", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Search Throttle",
                                }
                            },
                            { 
                                path: "donor-limits", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Donor Campaign Limits",
                                }
                            },
                            { 
                                path: "campaign-types", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Campaign Types",
                                }
                            },
                            { 
                                path: "approver", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Default Approver",
                                }
                            },
                            { 
                                path: "reporting", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Power BI",
                                }
                            },
                        ]
                    },

                    
                    { 
                        path: "organization-settings", 
                        component: PageNotFoundComponent,
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Organization Settings",
                        },
                    },


                    { 
                        path: "users", 
                        component: PageNotFoundComponent,
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "users",
                        },
                        children: [
                            { 
                                path: "invite-new-user", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Invite New User",
                                }
                            },
                            { 
                                path: "all-users", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "All Users",
                                }
                            },
                            { 
                                path: "user-bulk-upload", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "User Bulk Upload",
                                }
                            },
                        ]
                    },


                        
                    { 
                        path: "system-errors", 
                        component: PageNotFoundComponent,
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Campaign Settings",
                        },
                        children: [
                            { 
                                path: "login-errors", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Login Errors",
                                }
                            },
                            { 
                                path: "logout-errors", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Logout Errors",
                                }
                            },
                            { 
                                path: "transaction-errors", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Transaction Errors",
                                }
                            },
                        ]
                    },

                ]
            },
            // admin donors...
    
            // CATCH-ALL ------------------------------------------------------------------------------------------
            { 
                path: "", 
                component: CampaignComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Campaign Selection",
                },
            },
            ]
        },
        {
        path: "styleguide",
        component: StyleguideComponent,
        },

    // { 
    //     path: "**", 
    //     data: {
    //         breadcrumb: "Page Not Found",
    //     },
    //     component: PageNotFoundComponent,
    // },
    ];


/**
 * 
 * @param user 
 */
// export const getRoutesForUser = (user:any):any => {

//     const routes:Array<any> = [];
//     if(user.apps.get("vortex") !== undefined){
//         routes.push(...vortexRoutes);
//         // routes.push(...vortexFECRoutes);
//         // routes.push(...vortexJFCRoutes);
//     }
//     if(user.apps.get("vortex-lite") !== undefined){
//         routes.push(...vortexLiteRoutes);
//     }
//     if(user.apps.get("apex") !== undefined){
//         routes.push(...apexRoutes);
//     }
//     if(user.apps.get("admin") !== undefined){
//         routes.push(...adminRoutes);
//     }

//     console.debug(routes);
//     return routes;
// }
