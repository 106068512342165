import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { GenericButtonComponent } from "../../../global/component/generic/generic-button/generic-button.component";
import { Table, TableModule, TablePageEvent } from 'primeng/table';

interface Option {
  name: string;
  value: string;
}

@Component({
  selector: 'app-donor-manual-dedupes',
  standalone: true,
  imports: [
    FormsModule,
    DropdownModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    GenericButtonComponent,
    TableModule
  ],
  templateUrl: './donor-manual-dedupes.component.html',
  styleUrl: './donor-manual-dedupes.component.scss'
})

export class DonorManualDedupesComponent {

  options: Option[] | undefined;

  selectedOption: Option | undefined;

  ngOnInit() {
      this.options = [
          { name: 'FirstName/Organization -> LastName -> Email', value: 'lastname_email' },
          { name: 'FirstName/Organization -> Address1 -> State', value: 'address_state' },
          { name: 'FirstName/Organization -> LastName -> Address1 -> State', value: 'lastname_address_state' }
      ];
  }
}
