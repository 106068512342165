import { Component } from '@angular/core';

@Component({
  selector: 'app-donor-manual-dedupes',
  standalone: true,
  imports: [],
  templateUrl: './donor-manual-dedupes.component.html',
  styleUrl: './donor-manual-dedupes.component.scss'
})
export class DonorManualDedupesComponent {

}
