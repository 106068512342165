import { Component } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { DragDropModule } from 'primeng/dragdrop';
import { BaseComponentComponent } from '../../component/dashboard/base-component/base-component.component';
import { DragAndDropComponent } from "../../component/dashboard/drag-and-drop/drag-and-drop.component";
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [ChartModule, CardModule, DragAndDropComponent, NgForOf],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

    selectedComponents: BaseComponentComponent[] = [];

    ngOnInit() {
    }

    dragStart(component: BaseComponentComponent) {
        //this.draggedComponent = component;
    }

    drop() {
        // if (this.draggedComponent) {
        //     let draggedIndex = this.findIndex(this.draggedComponent);
        //     this.selectedComponents = [...(this.selectedComponents as BaseComponentComponent[]), this.draggedComponent];
        //     this.draggedComponent = null;
        // }
    }

    dragEnd() {
        //this.draggedComponent = null;
    }

    findIndex(component: BaseComponentComponent) {
        let index = -1;
        for (let i = 0; i < this.selectedComponents.length ; i++) {
            const c:BaseComponentComponent = this.selectedComponents[i];
            if (c.typeId === component.typeId) {
                index = i;
                break;
            }
        }
        return index;
    }
    
    protected components:Array<any> = [
        {
            "title": "Component One",
            "type": "CorePieChartComponent",
            "thumb":"/assets/component-1.png"
        },    
        {
            "title": "Component Two",
            "type": "RaiseByElectionFundComponent",
            "thumb":"/assets/component-2.png"
        },
    ]
    draggedComponent: Array<DragAndDropComponent> = this.components.map(
        i => new DragAndDropComponent()
    );

};

        // this.basicData = {
        //     labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        //     datasets: [
        //         {
        //             label: 'Sales',
        //             data: [540, 325, 702, 620],
        //             backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
        //             borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
        //             borderWidth: 1
        //         }
        //     ]
        // };

        // this.basicOptions = {
        //     plugins: {
        //         legend: {
        //             labels: {
        //                 color: textColor
        //             }
        //         }
        //     },
        //     scales: {
        //         y: {
        //             beginAtZero: true,
        //             ticks: {
        //                 color: textColorSecondary
        //             },
        //             grid: {
        //                 color: surfaceBorder,
        //                 drawBorder: false
        //             }
        //         },
        //         x: {
        //             ticks: {
        //                 color: textColorSecondary
        //             },
        //             grid: {
        //                 color: surfaceBorder,
        //                 drawBorder: false
        //             }
        //         }
        //     }
        // };
