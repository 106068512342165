import { Component } from '@angular/core';

@Component({
  selector: 'app-donor-bulk-update',
  standalone: true,
  imports: [],
  templateUrl: './donor-bulk-update.component.html',
  styleUrl: './donor-bulk-update.component.scss'
})
export class DonorBulkUpdateComponent {

}
