<div class="flex justify-content-center align-items-center" style="height: 100vh;">
  <p-toast />
  <p-card class="w-2" styleClass="card-style-account">
    <ng-template pTemplate="header">
      <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
    </ng-template>
    <div class="flex justify-content-center">
      <span class="h3">Verify Two-Factor Code</span>
    </div>
    <form [formGroup]="twofaForm" (ngSubmit)="verifyCode()">
      <div class="field">
        <label for="code">2FA Code</label>
        <input pInputText id="code"
               formControlName="code"
               type="text"
               class="w-full"
               placeholder="Enter your 2FA Code" />
      </div>

      <div class="field flex align-items-center">
        <p-checkbox id="remember2fa"
                    formControlName="remember2fa"
                    binary="true"></p-checkbox>
        <label for="remember2fa" class="ml-2 mt-2">Remember this device</label>
      </div>

      <div class="flex justify-content-end mt-2">
        <p-button label="Previous" class="p-button-secondary" (click)="goBack()"></p-button>
        <p-button label="Verify" type="submit" [disabled]="twofaForm.invalid"></p-button>
      </div>
    </form>
  </p-card>
</div>
