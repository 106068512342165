<div class="layout-centered">
  <form [formGroup]="registerForm" (ngSubmit)="submitDetails()">
    <p-toast></p-toast>
    <p-card styleClass="card-style-account">
      <ng-template pTemplate="header">
        <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
      </ng-template>

      <h1 class="h3">Register</h1>
      <div class="field">
        <label for="firstName">First Name</label>
        <input type="text"
               pInputText
               formControlName="firstName"
               class="w-full"
               id="register-first-name"
               name="firstName"
               placeholder="Enter your first name" />
        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
             class="block p-error">
          <div *ngIf="firstName.errors?.['required']">First Name is required.</div>
          <div *ngIf="firstName?.errors?.['pattern']">Invalid characters in First Name.</div>
        </div>
      </div>
      <div class="field">
        <label for="lastName">Last Name</label>
        <input type="text"
               pInputText
               formControlName="lastName"
               class="w-full"
               id="register-last-name"
               name="lastName"
               placeholder="Enter your last name" />
        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
             class="block p-error">
          <div *ngIf="lastName.errors?.['required']">Last Name is required.</div>
          <div *ngIf="lastName?.errors?.['pattern']">Invalid characters in Last Name.</div>
        </div>
      </div>
      <div class="field">
        <label for="email">Email</label>
        <input type="text"
               pInputText
               formControlName="email"
               class="w-full"
               id="register-email"
               name="email"
               placeholder="Enter your email address" />
        <div *ngIf="email.invalid && (email.dirty || email.touched)"
             class="block p-error">
          <div *ngIf="email.errors?.['required']">Email is required.</div>
          <div *ngIf="email.errors?.['email']">Please enter a valid email address.</div>
          <div *ngIf="email.errors?.['inviteCodeValid']">Email is not associated with a valid invite code.</div>
        </div>
      </div>
      <div class="field">
        <label for="phoneNumber">Phone Number</label>
        <input type="text"
               pInputText
               formControlName="phoneNumber"
               class="w-full"
               id="register-phone-number"
               name="phoneNumber"
               placeholder="Enter your phone number" />
        <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)"
             class="block p-error">
          <div *ngIf="phoneNumber.errors?.['required']">Phone Number is required.</div>
          <div *ngIf="phoneNumber.errors?.['pattern']">Invalid phone number format.</div>
        </div>
      </div>
      <div class="field">
        <label for="password">Password</label>
        <div class="p-inputgroup">
          <input type="{{showPassword ? 'text' : 'password'}}"
                 pInputText
                 formControlName="password"
                 class="w-full"
                 id="register-password"
                 name="password"
                 (input)="validatePassword()"
                 placeholder="Create a password" />
          <span class="p-inputgroup-addon" (click)="togglePasswordVisibility()">
            <i class="pi cursor" [ngClass]="{'pi-eye': !showPassword, 'pi-eye-slash': showPassword}"></i>
          </span>
        </div>

        <div class="block mt-2">
          <span [ngClass]="{'text-success': passwordValidations.uppercase, 'text-danger': !passwordValidations.uppercase}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.uppercase, 'pi-times': !passwordValidations.uppercase}"></i>
            At least one uppercase letter (A-Z).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.lowercase, 'text-danger': !passwordValidations.lowercase}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.lowercase, 'pi-times': !passwordValidations.lowercase}"></i>
            At least one lowercase letter (a-z).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.number, 'text-danger': !passwordValidations.number}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.number, 'pi-times': !passwordValidations.number}"></i>
            At least one numeric digit (0-9).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.specialChar, 'text-danger': !passwordValidations.specialChar}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.specialChar, 'pi-times': !passwordValidations.specialChar}"></i>
            At least one special character (e.g., !&#64;#$%^&*).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.minLength, 'text-danger': !passwordValidations.minLength}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.minLength, 'pi-times': !passwordValidations.minLength}"></i>
            At least 8 characters.
          </span>
        </div>

        <div *ngIf="password.invalid && (password.dirty || password.touched)"
             class="block p-error">
          <div *ngIf="password.errors?.['required']">Password is required.</div>
          <!-- <div *ngIf="password.errors?.['invalidPassword']">Password does not meet requirements.</div>-->
        </div>
      </div>
      <div class="field">
        <label for="confirmPassword">Confirm Password</label>
        <input type="password"
               pInputText
               formControlName="confirmPassword"
               class="w-full"
               id="register-confirm-password"
               name="confirmPassword"
               placeholder="Re-enter your password" />
        <div *ngIf="
          confirmPassword.invalid &&
          (confirmPassword.dirty || confirmPassword.touched)"
             class="block p-error">
          <div *ngIf="confirmPassword.errors?.['required']">
            Please confirm your password.
          </div>
        </div>
        <div class="block p-error"
             *ngIf="registerForm.errors?.['passwordMismatch'] && confirmPassword.valid ">
          Passwords do not match.
        </div>
      </div>
      <p-button label="Register"
                styleClass="mb-5"
                [disabled]="registerForm.invalid"
                type="submit"
                id="register-submit"></p-button>
      <div>Already a member? <a routerLink="/login">Login</a></div>
    </p-card>
  </form>
</div>
