
<div id="app-container">
  
    <header id="page-header">
      <app-app-nav   />
    
      <div id="brandBar">
        <div id="mobile-menu-icon" style="display: none;">
          <!-- TODO: add hamburger icon toggle for mobile menu -->
        </div>
        <!-- TODO: logo and link should be conditional based on the open product -->
        <a id="home-link" href="#home">
          <img src="/assets/images/logo-vortex.png" id="logo-vortex" /> 
        </a>
        <rc-campaign-switcher [isDockable]="true" />
      </div>
    </header>
    
    <div id="mainArea">
  
      <rc-main-nav />
  
      <!-- (update)="selectCampaign($event)" -->
      <main>
        <div *ngIf="isOpen" id="paper">
          <div id="nav-actions">
            <app-breadcrumb />
            <div id="back-button-div">
              <a href="javascript();" class="text-button back-button">
                  <i class="pi pi-angle-left text-redwood-60"></i>
              </a>
            </div>
          </div>
          <div id="componentContainer" role="main">
            <router-outlet></router-outlet>       
          </div>  
        </div>
  
        <footer id="page-footer">
          <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
          <div id="copyright" class="text-neutral-60">
            <span>©2023 by Red Curve Solutions®</span>  |  <span>All Rights Reserved</span>  | <span>138 Conant Street, Beverly, MA 01915</span>  |  <span>617-303-6800</span>  |  <span>RCS v3.0</span>
          </div>
          <div id="action-top">
            <button id="action-top-button" class="p-button p-button-icon-only p-button-no-shadow bg-redwood-40">
              <i class="fa-sharp fa-light fa-chevron-up"></i>
            </button>
          </div>
        </footer>
  
      </main>
    </div>

  </div>

<!--   
  {{navModel | json}} -->