import { Component, OnInit } from '@angular/core';
import { TextInputComponent } from '../../component/text-input/text-input.component';
import { CardModule } from 'primeng/card';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { StepperModule } from 'primeng/stepper';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { GenericButtonComponent } from '../../component/generic/generic-button/generic-button.component';

@Component({
  selector: 'app-styleguide',
  standalone: true,
  imports: [
    TextInputComponent, 
    FormsModule, 
    MultiSelectModule, 
    CardModule, 
    RadioButtonModule, 
    ListboxModule, 
    DropdownModule,
    InputSwitchModule,
    CheckboxModule,
    CalendarModule,
    TabViewModule, 
    InputTextModule,
    StepperModule, 
    DialogModule,
    GenericButtonComponent, 
    ConfirmDialogModule, 
    ToastModule, 
    MessagesModule
  ],
  templateUrl: './styleguide.component.html',
  styleUrl: './styleguide.component.scss',
  providers: [ConfirmationService, MessageService]
})

export class StyleguideComponent implements OnInit {
  
  cities!: any[];
  selectedCities!: any[];
  selectedCity!: any;
  ingredient!: string;
  checked: boolean = false;
  date: Date | undefined;
  visible: boolean = false;

  showDialog() {
      console.log("show dialog called")
      this.visible = true;
  }

  // messages: Message[] | undefined;

  // ngOnInit() {
  //   this.messages = [{ severity: 'info', detail: 'Message Content' }];
  // }


  showSuccess() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
  }

  showInfo() {
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Message Content' });
  }

  showWarn() {
      this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'Message Content' });
  }

  showError() {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Message Content' });
  }

  constructor(private confirmationService: ConfirmationService, private messageService: MessageService) {}

  confirm1(event: Event) {
      this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Are you sure you want to save this record?',
          header: 'Confirmation',
          acceptIcon:"none",
          rejectIcon:"none",
          rejectButtonStyleClass:"p-button-text",
          accept: () => {
              this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record saved', life: 3000 });
          },
          reject: () => {
              this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Record NOT saved', life: 3000 });
          }
      });
  }

  confirm2(event: Event) {
      this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Are you sure you want to delete this record?',
          header: 'Delete Confirmation',
          rejectButtonStyleClass:"p-button-text",
          acceptIcon:"none",
          rejectIcon:"none",

          accept: () => {
              this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
          },
          reject: () => {
              this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Record NOT deleted', life: 3000 });
          }
      });
  }

  snippetButton1:string = 
`<rc-generic-button 
    label="Primary Solid" 
    />
    
<rc-generic-button 
    label="Primary Outlined" 
    borderStyle="outline"
    />
    
<rc-generic-button 
    label="Primary Text Link" 
    borderStyle="text-only"
    />
`

  snippetButton2:string = 
`<rc-generic-button 
  level="secondary"
  borderStyle="solid"
  label="Secondary Solid"
  />
  
<rc-generic-button 
  level="secondary"
  label="Secondary Outlined" 
  borderStyle="outline"
  />

<rc-generic-button 
    level="secondary"
    label="Text Link functions like a button" 
    href="/"
    borderStyle="link"
    />
`

  snippetButton3:string = 
`<rc-generic-button 
    level="tertiary"
    label="Tertiary Solid"
    />
        
<rc-generic-button 
    level="tertiary"
    label="Tertiary Outlined"
    borderStyle="outline"
    />
`

  snippetButton4:string = 
`<rc-generic-button 
    label="Primary Disabled"
    [disabled]="true"
    />
    
<rc-generic-button 
    label="Primary Disabled"
    level="secondary"
    borderStyle="outline"
    [disabled]="true"
    />
    
<rc-generic-button 
    label="Text Link Disabled"
    level="secondary"
    borderStyle="text-only"
    [disabled]="true"
    />
`

  snippetButton5:string = 
`<rc-generic-button 
    label="Small"
    size="small" 
    />
    
<rc-generic-button 
    label="Medium"
    />
    
<rc-generic-button 
    label="Large"
    size="large"
    />
`

  snippetButton6:string = 
`
  <rc-generic-button 
      label="Export"
      iconImg="download"
      [classes]="['mx-1']"/>
  <rc-generic-button 
      label="Compare"
      iconImg="compare"
      borderStyle="outline"
      [classes]="['mx-1']"/>
  <rc-generic-button 
      label="Selected"
      iconImg="dropdown"
      borderStyle="outline"
      [classes]="['mx-1']"/>
  <rc-generic-button 
      label="Upload File"
      iconImg="upload"
      borderStyle="outline"
      size="small"
      [classes]="['mx-1']"/>
  <rc-generic-button 
      label="Add Line Item"
      iconImg="plus"
      borderStyle="outline"
      size="small"
      [classes]="['mx-1']"/>
  <rc-generic-button 
      label="Apply"
      iconImg="filter"
      borderStyle="outline"
      level="tertiary"
      [classes]="['mx-1']"/>
  <rc-generic-button 
      label="Clear"
      iconImg="clear-filter"
      borderStyle="outline"
      level="tertiary"
      [classes]="['mx-1']"/>
  <rc-generic-button 
      label="Additional Fields"
      iconImg="plus"
      borderStyle="text-only"
      level="secondary"
      size="small"
      [classes]="['mx-1']"/>
`

  snippetButton7:string = 
`
<rc-generic-button 
    label="Delete"
    iconImg="trash"
    iconColor="red-light"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="Delete"
    iconImg="trash"
    iconColor="gray-light"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="Edit"
    iconImg="cog"
    iconColor="teal-light"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="View Donation History"
    iconImg="search-money"
    iconColor="redwood-light"
    [isIconOnly]="true"
    />`

  snippetButton8:string = 
`<rc-generic-button 
    label="My approval - unavailable"
    iconImg="user-minus"
    iconColor="gray-light"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="My approval - unapproved"
    iconImg="user-clock"
    iconColor="gray-light"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="My approval - unapproved"
    iconImg="user-clock"
    iconColor="teal-medium"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="My approval - submitted"
    iconImg="user-clock"
    iconColor="teal-dark"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="My approval - locked"
    iconImg="user-clock"
    iconColor="gray-dark"
    [isIconOnly]="true"
    />`

  snippetButton9:string = 
`<rc-generic-button 
    label="RC approval - unapproved"
    iconImg="envelope-dollar"
    iconColor="gray-light"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="RC approval - approved"
    iconImg="envelope-dollar"
    iconColor="teal-medium"
    [isIconOnly]="true"
    />
<rc-generic-button 
    label="RC approval - submitted"
    iconImg="money-check"
    iconColor="teal-dark"
    [isIconOnly]="true"
    />`

snippetText1:string = 
`text-xs       font-size: .75rem;
text-sm       font-size: .875rem;
text-base     font-size: 1rem;        // matches .h6 and .p size
text-lg       font-size: 1.125rem;    // matches .h4 and .h5 size
text-xl       font-size: 1.25rem;     // matches .h3 size
text-2xl      font-size: 1.5rem;      // matches .h2 size
text-3xl      font-size: 1.75rem;     // matches .h1 size
text-4xl      font-size: 2rem;
`

snippetText2:string = 
`font-light        font-weight: 300;
font-normal       font-weight: 400;
font-bold         font-weight: 700;
`

snippetText3:string = 
`red-60            color: $red-60;
neutral-80        color: $neutral-80;
teal-60           color: $teal-60;
redwood-70        color: $redwood-70;
`

snippetColor1:string = 
`neutral-0         color: $neutral-0;      // white
neutral-5         color: $neutral-5;
neutral-10        color: $neutral-10;
neutral-20        color: $neutral-20;
neutral-30        color: $neutral-30;
...
neutral-80        color: $neutral-80;
neutral-90        color: $neutral-90;
neutral-100       color: $neutral-100;     // black


red-40            color: $red-40;
red-60            color: $red-60;           // RC brand color
red-80            color: $red-80;
red-100           color: $red-100;


teal-0            color: $teal-0;  
teal-5            color: $teal-5;
teal-10           color: $teal-10;
teal-20           color: $teal-20;
teal-30           color: $teal-30;
...
teal-80           color: $teal-80;
teal-90           color: $teal-90;
teal-100          color: $teal-100;    


redwood-0         color: $redwood-0;  
redwood-5         color: $redwood-5;
redwood-10        color: $redwood-10;
redwood-20        color: $redwood-20;
redwood-30        color: $redwood-30;
...
redwood-80        color: $redwood-80;
redwood-90        color: $redwood-90;
redwood-100       color: $redwood-100;    
`

snippetColor2:string = 
`bg-neutral-0         background-color: $neutral-0;      // white
bg-neutral-5         background-color: $neutral-5;
bg-neutral-10        background-color: $neutral-10;
bg-neutral-20        background-color: $neutral-20;
bg-neutral-30        background-color: $neutral-30;
...
bg-neutral-80        background-color: $neutral-80;
bg-neutral-90        background-color: $neutral-90;
bg-neutral-100       background-color: $neutral-100;     // black


bg-red-40            background-color: $red-40;
bg-red-60            background-color: $red-60;           // RC brand color
bg-red-80            background-color: $red-80;
bg-red-100           background-color: $red-100;


bg-teal-0            background-color: $teal-0;  
bg-teal-5            background-color: $teal-5;
bg-teal-10           background-color: $teal-10;
bg-teal-20           background-color: $teal-20;
bg-teal-30           background-color: $teal-30;
...
bg-teal-80           background-color: $teal-80;
bg-teal-90           background-color: $teal-90;
bg-teal-100          background-color: $teal-100;    


bg-redwood-0         background-color: $redwood-0;  
bg-redwood-5         background-color: $redwood-5;
bg-redwood-10        background-color: $redwood-10;
bg-redwood-20        background-color: $redwood-20;
bg-redwood-30        background-color: $redwood-30;
...
bg-redwood-80        background-color: $redwood-80;
bg-redwood-90        background-color: $redwood-90;
bg-redwood-100       background-color: $redwood-100;    
`

snippetColor3:string = 
`font-light        font-weight: 300;
font-normal       font-weight: 400;
font-bold         font-weight: 700;
`

snippetForm1:string = 
`<rc-text-input>TBD</rc-text-input>
`

snippetForm2:string = 
`<label for="dropdown1" class="field-label">
  Required Dropdown
</label>
<p-dropdown 
  inputId="dropdown1"
  [options]="cities" 
  [(ngModel)]="selectedCity" 
  optionLabel="name" 
  placeholder="Select a City" />

<label for="dropdown2" class="field-label">
  Invalid Dropdown
</label>
<p-dropdown 
inputId="dropdown2"
  [options]="cities" 
  [(ngModel)]="selectedCity" 
  optionLabel="name" 
  placeholder="Select a City"
  class="ng-dirty ng-invalid" />
<small id="dropdown2-error" class="error">
  Error message example
</small>

<label for="dropdown3" class="field-label">
  Disabled Dropdown
</label>
<p-dropdown 
  inputId="dropdown3"
  [options]="cities" 
  [(ngModel)]="selectedCity" 
  optionLabel="name" 
  placeholder="Select a City"
  [disabled]="true" />
<small id="dropdown3-error" class="error">
  Error message example
</small>
`

snippetForm3:string = 
`<div class="formgrid grid">
  <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
    <label for="multiselect1" class="field-label">
      Required Multiselect
    </label>
    <p-multiSelect 
      inputId="multiselect1"
      [options]="cities" 
      [(ngModel)]="selectedCities" 
      optionLabel="name" 
      placeholder="Select Cities" />
  </div>

  <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
    <label for="multiselect2" class="field-label">
      Invalid Multiselect
    </label>
    <p-multiSelect 
      inputId="multiselect2"  
      [options]="cities" 
      [(ngModel)]="selectedCities" 
      optionLabel="name" 
      class="ng-dirty ng-invalid" 
      placeholder="Select Cities" />
    <small id="multiselect2-error" class="error">
        Error message example
    </small>
  </div>

  <div class="field disabled col-12 sm:col-6 md:col-4 lg:col-3">
    <label for="multiselect3" class="field-label">
      Disabled Multiselect
    </label>
    <p-dropdown 
      inputId="multiselect3"
      [options]="cities" 
      [(ngModel)]="selectedCity" 
      optionLabel="name" 
      placeholder="Select a City"
      [disabled]="true" />
    <small id="multiselect3-error" class="error">
      Error message example
    </small>
  </div>

  <div class="field col-12">
    <label for="multiselect4" class="field-label">
      Multiselect with chips (for search columns picker)
    </label>
    <p-multiSelect 
      inputId="multiselect4"
      [options]="cities" 
      [(ngModel)]="selectedCities" 
      placeholder="Select Cities"
      optionLabel="name" 
      display="chip" 
      [showClear]="true" />
  </div>
</div>
`

snippetForm4:string = 
`<div class="formgrid grid">
  <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
    <label for="checkbox1" class="field-label">
        Default Checkbox
    </label>
    <p-checkbox 
        [(ngModel)]="checked" 
        [binary]="true"
        inputId="checkbox1"
        label="Label" />
  </div>

  <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
    <label for="checkbox2" class="field-label">
        Invalid Checkbox
    </label>
    <p-checkbox 
        [(ngModel)]="checked"
        [binary]="true"
        inputId="checkbox2"
        class="ng-invalid ng-dirty"
        label="Label" />
  </div>

  <div class="field disabled col-12 sm:col-6 md:col-4 lg:col-3">
    <label for="checkbox3" class="field-label">
        Disabled Checkbox
    </label>
    <p-checkbox 
        [(ngModel)]="checked" 
        inputId="checkbox3" 
        [disabled]="true"
        label="Label" />
  </div>
</div>
`

snippetForm5:string = 
`<div class="formgrid grid">
  <div class="required col-12 sm:col-6 md:col-4 lg:col-3">
    <p class="label mb-3">Required radio button group</p>
    <div class="flex flex-column gap-3">
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Cheese" 
          [(ngModel)]="ingredient" 
          inputId="ingredient1" />
        <label for="ingredient1" class="ml-2">
          Cheese
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Mushroom" 
          [(ngModel)]="ingredient" 
          inputId="ingredient2" />
        <label for="ingredient2" class="ml-2">
          Mushroom
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Pepper" 
          [(ngModel)]="ingredient" 
          inputId="ingredient3" />
        <label for="ingredient3" class="ml-2">
          Pepper
        </label>
      </div>
    </div>
  </div>

  <div class="col-12 sm:col-6 md:col-4 lg:col-3">
    <p class="label mb-3">Invalid radio button group</p>
    <div class="flex flex-column gap-3">
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Cheese" 
          [(ngModel)]="ingredient" 
          inputId="ingredient1"
          class="ng-invalid ng-dirty" />
        <label for="ingredient1" class="ml-2">
          Cheese
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Mushroom" 
          [(ngModel)]="ingredient" 
          inputId="ingredient2"
          class="ng-invalid ng-dirty" />
        <label for="ingredient2" class="ml-2">
          Mushroom
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Pepper" 
          [(ngModel)]="ingredient" 
          inputId="ingredient3"
          class="ng-invalid ng-dirty" />
        <label for="ingredient3" class="ml-2">
          Pepper
        </label>
      </div>
    </div>
  </div>

  <div class="col-12 sm:col-6 md:col-4 lg:col-3">
    <p class="label mb-3">Disabled radio button group</p>
    <div class="flex flex-column gap-3">
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Cheese" 
          [(ngModel)]="ingredient" 
          inputId="ingredient1"
          [disabled]="true" />
        <label for="ingredient1" class="ml-2">
          Cheese
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Mushroom" 
          [(ngModel)]="ingredient" 
          inputId="ingredient2"
          [disabled]="true" />
        <label for="ingredient2" class="ml-2">
          Mushroom
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Pepper" 
          [(ngModel)]="ingredient" 
          inputId="ingredient3"
          [disabled]="true" />
        <label for="ingredient3" class="ml-2">
          Pepper
        </label>
      </div>
    </div>
  </div>
</div>
`

snippetForm6:string = 
`<div class="formgrid grid">
  <div class="col-12 required">
    <p class="label mb-3">Horizontal layout for radio button group</p>
    <div class="flex flex-wrap gap-3">
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Cheese" 
          [(ngModel)]="ingredient" 
          inputId="ingredient1" />
        <label for="ingredient1" class="ml-2">
          Cheese
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Mushroom" 
          [(ngModel)]="ingredient" 
          inputId="ingredient2" />
        <label for="ingredient2" class="ml-2">
          Mushroom
        </label>
      </div>
      
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Pepper" 
          [(ngModel)]="ingredient" 
          inputId="ingredient3" />
        <label for="ingredient3" class="ml-2">
          Pepper
        </label>
      </div>
    
      <div class="flex align-items-center">
        <p-radioButton 
          name="pizza" 
          value="Onion" 
          [(ngModel)]="ingredient" 
          inputId="ingredient4" />
        <label for="ingredient4" class="ml-2">
          Onion
        </label>
      </div>
    </div>
  </div>
</div>
`

snippetGrid1:string = 
`<div class="grid">
  <div class="col">
    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold">1</div>
  </div>
  <div class="col">
    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">2</div>
  </div>
  <div class="col">
    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">3</div>
  </div>
</div>
`

snippetGrid2:string = 
`$xs       321.9px;
$sm       575.9px;
$md       767.9px;
$lg       991.9px;
$xl       1199.9px;
$xxl      1559.9px;
`

snippetGrid3:string = 
`Max-width breakpoints

@media screen and (max-width: #{$lg}) {}

$xs       0px - 321.9px
$sm       322px - 575.9px
$md       576px - 767.9px
$lg       768px - 991.9px
$xl       992px - 1199.9px
$xxl      1200px - 1559.9px
`

snippetGrid4:string = 
`Min-width breakpoints

@media screen and (min-width: #{$lg-min}) {}

$xs-min       322px -  575.9px 
$sm-min       576px -  767.9px 
$md-min       768px -  991.9px 
$lg-min       992px -  1199.9px
$xl-min       1200px - 1559.9px 
$xxl-min      1560px - infinity
`

snippetGrid5:string = 
`<div class="grid">
  <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 xxl:1">
    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold">1</div>
  </div>
  <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 xxl:1">
    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">2</div>
  </div>
  <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 xxl:1">
    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">3</div>
  </div>
</div>
`

snippetSpacing1:string = 
`spacing scales are ($spacer * $space-scale)

$spacer: 1rem;
$space-scales: 0, 0.25, 0.5, 1, 1.5, 2, 3, 4, 5;

.p-0 = 0 (0 scale)
.p-1 = 4px (0.25 scale)
.p-2 = 8px (0.5 scale)
.p-3 = 16px (1 scale)
.p-4 = 24px (1.5 scale)
.p-5 = 32px (2 scale)
.p-6 = 48px (3 scale)
.p-7 = 64px (4 scale)
.p-8 = 80px (5 scale)
`

snippetCard1:string =
`<p-card>

  <div class="grid">
    <div class="col">
      <p>Lorem ipsum dolor sit amet...</p>
    </div>
  </div class="grid">

</p-card> 
`

snippetCard2:string =
`<div class="grid">
  <div class="col-12 sm:col-6 lg:col-4">

    <p-card>
      <ng-template pTemplate="header">
        <p>Advanced Card</p>
        <p-button
          icon="pi pi-window-maximize"
          [text]="true"
          title="Expand"
        />
      </ng-template>
      <div class="grid">
        <div class="col">
          <p>Lorem ipsum dolor sit amet...</p>
        </div>
      </div class="grid">
    </p-card>

  </div>

  <div class="col-12 sm:col-6 lg:col-8">

    <p-card>
      <ng-template pTemplate="header">
        <p>Advanced Card</p>
        <p-button
          icon="pi pi-window-maximize"
          [text]="true"
          title="Expand"
        />
      </ng-template>
      <div class="grid">
        <div class="col">
          <p>Lorem ipsum dolor sit amet...</p>
        </div>
      </div class="grid">
    </p-card>

  </div>
</div>
`

snippetStepper1:string =
`<p-stepper orientation="vertical">
  <p-stepperPanel header="Header I">
    <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
      
      <p-card styleClass="mb-2">
        <div class="formgrid grid">
          <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield1" class="field-label">
              First Name
            </label>
            <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
          </div>
          <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield2" class="field-label">
              Middle Name
            </label>
            <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
          </div>
          <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield3" class="field-label">
              Last Name
            </label>
            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
          </div>
        </div>
      </p-card>

      <p-card styleClass="mb-2">
        <div class="formgrid grid">
          <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield1" class="field-label">
              Address
            </label>
            <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
          </div>
          <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield2" class="field-label">
              City
            </label>
            <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
          </div>
          <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield3" class="field-label">
              State
            </label>
            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
          </div>
          <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield3" class="field-label">
              Zip
            </label>
            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
          </div>
        </div>
      </p-card>

      <p-card styleClass="mb-1">
        <div class="formgrid grid">
          <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield1" class="field-label">
              Employer
            </label>
            <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
          </div>
          <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
            <label for="textfield2" class="field-label">
              Occupation
            </label>
            <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
          </div>
        </div>
      </p-card>

      <p-card>
        <p-button label="Next" severity="secondary" size="small" (onClick)="nextCallback.emit()" />
      </p-card>

    </ng-template>
  </p-stepperPanel>

  <p-stepperPanel header="Header II">
    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">

      <p-card styleClass="mb-1">
        <div class="flex flex-column h-12rem">
          <div class="flex-auto flex justify-content-center align-items-center font-medium">
            Content II
          </div>
        </div>
      </p-card>

      <p-card>
        <p-button label="Back" severity="secondary" [outlined]="true" size="small" (onClick)="prevCallback.emit()" styleClass="mr-2" />
        <p-button label="Next" severity="secondary" size="small" (onClick)="nextCallback.emit()" />
      </p-card>

    </ng-template>
  </p-stepperPanel>

  <p-stepperPanel header="Header III">
    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
      
      <p-card styleClass="mb-1">
        <div class="flex flex-column h-12rem">
          <div class="flex-auto flex justify-content-center align-items-center font-medium">
            Content III
          </div>
        </div>
      </p-card>

      <p-card>
        <p-button label="Back" severity="secondary" size="small" (onClick)="prevCallback.emit()" />
      </p-card>
      
    </ng-template>
  </p-stepperPanel>
</p-stepper>
`

snippetModal1:string =
`<p-dialog 
  header="Large Modal Header" 
  [modal]="true"
  [(visible)]="visible"
  [maximizable]="true"
  styleClass="p-dialog-static p-dialog-lg" >

  <ng-template pTemplate="header">
    <div class="modal-header">
      <span class="h3 white-space-nowrap">
        Large Modal Header
      </span>
    </div>
    <div class="p-dialog-header-icons">
      <p-button 
        label="Back" 
        icon="fa-sharp fa-light fa-chevron-left"
        styleClass="button-back p-dialog-header-icon mr-2"
        [text]="true" 
        size="small"
      />
    </div>
  </ng-template>

  <p-card styleClass="mb-2">
    <div class="formgrid grid">
      <div class="field required col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
        <label for="textfield1" class="field-label">
          First Name
        </label>
        <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
      </div>
      <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
        <label for="textfield2" class="field-label">
          Middle Name
        </label>
        <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
      <div class="field required col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
        <label for="textfield3" class="field-label">
          Last Name
        </label>
        <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
      <div class="field required col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
        <label for="textfield4" class="field-label">
          Suffix
        </label>
        <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
    </div>
  </p-card>

  <ng-template pTemplate="footer">
    <p-button 
      label="Cancel" 
      [text]="true" 
      (onClick)="visible = false" />
    <p-button 
      label="Save" 
      (onClick)="visible = false" />
  </ng-template>
</p-dialog>
`

snippetModal2:string =
`<p-dialog 
  header="Medium Modal Header" 
  [modal]="true"
  [(visible)]="visible"
  [maximizable]="true"
  styleClass="p-dialog-static p-dialog-md" >

  <ng-template pTemplate="header">
    <div class="modal-header">
      <span class="h3 white-space-nowrap">
        Medium Modal Header
      </span>
    </div>
    <div class="p-dialog-header-icons">
      <p-button 
        label="Back" 
        icon="fa-sharp fa-light fa-chevron-left"
        styleClass="button-back p-dialog-header-icon mr-2"
        [text]="true" 
        size="small"
      />
    </div>
  </ng-template>

  <p-card styleClass="mb-2">
    <div class="formgrid grid">
      <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
        <label for="textfield1" class="field-label">
          First Name
        </label>
        <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
      </div>
      <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
        <label for="textfield2" class="field-label">
          Middle Name
        </label>
        <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
      <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
        <label for="textfield3" class="field-label">
          Last Name
        </label>
        <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
      <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
        <label for="textfield4" class="field-label">
          Suffix
        </label>
        <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
    </div>
  </p-card>

  <ng-template pTemplate="footer">
    <p-button 
      label="Cancel" 
      [text]="true" 
      (onClick)="visible = false" />
    <p-button 
      label="Save" 
      (onClick)="visible = false" />
  </ng-template>
</p-dialog>
`


snippetModal3:string =
`<p-dialog 
  header="Small Modal Header" 
  [modal]="true"
  [(visible)]="visible"
  [maximizable]="true"
  styleClass="p-dialog-static p-dialog-sm" >

  <ng-template pTemplate="header">
    <div class="modal-header">
      <span class="h3 white-space-nowrap">
        Small Modal Header
      </span>
    </div>
    <div class="p-dialog-header-icons">
      <p-button 
        label="Back" 
        icon="fa-sharp fa-light fa-chevron-left"
        styleClass="button-back p-dialog-header-icon mr-2"
        [text]="true" 
        size="small"
      />
    </div>
  </ng-template>

  <p-card styleClass="mb-2">
    <div class="formgrid grid">
      <div class="field required col-12 sm:col-6">
        <label for="textfield1" class="field-label">
          First Name
        </label>
        <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
      </div>
      <div class="field col-12 sm:col-6">
        <label for="textfield2" class="field-label">
          Middle Name
        </label>
        <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
      <div class="field required col-12 sm:col-6">
        <label for="textfield3" class="field-label">
          Last Name
        </label>
        <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
      <div class="field required col-12 sm:col-6">
        <label for="textfield4" class="field-label">
          Suffix
        </label>
        <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
      </div>
    </div>
  </p-card>

  <ng-template pTemplate="footer">
    <p-button 
      label="Cancel" 
      [text]="true" 
      (onClick)="visible = false" />
    <p-button 
      label="Save" 
      (onClick)="visible = false" />
  </ng-template>
</p-dialog>
`

snippetDialog1:string = 
`<p-button 
  styleClass="p-button-tertiary"
  label="Tertiary Solid"
/>

<p-button 
  styleClass="p-button-tertiary"
  [outlined]="true" 
  label="Tertiary Outlined"
/>
`

snippetDialog2:string = 
`<p-button 
  styleClass="p-button-tertiary"
  label="Tertiary Solid"
/>

<p-button 
  styleClass="p-button-tertiary"
  [outlined]="true" 
  label="Tertiary Outlined"
/>
`

  ngOnInit() {
    this.cities = [
        {name: 'New York', code: 'NY'},
        {name: 'Rome', code: 'RM'},
        {name: 'London', code: 'LDN'},
        {name: 'Istanbul', code: 'IST'},
        {name: 'Paris', code: 'PRS'}
    ];
}

}
