<div id="centered-display">

    <h1>Welcome!</h1>
    <p ngIf="!model.lastAccessedCampaigns">
        {{curentMessage}} <a [routerLink]="'../campaign-selection'" class="p-menuitem-link">
           select a different campaign.
        </a>
    </p>
    <ul>
        <li *ngFor="let itm of model.lastAccessedCampaigns" (click)="selectCampaign(itm)">
            {{itm.name}}
        </li>
    </ul>

</div>