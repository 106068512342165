import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './global/page/page-not-found/page-not-found.component';
import { SearchPageComponent } from './global/page/search/search.page.component';
import { CampaignComponent } from './global/page/campaign/campaign.component';
import { DonorAddComponent } from './vortex/page/donor-add/donor-add.component';
import { DonorBulkUpdateComponent } from './vortex/page/donor-bulk-update/donor-bulk-update.component';
import { DonorManualDedupesComponent } from './vortex/page/donor-manual-dedupes/donor-manual-dedupes.component';
import { DonorFlagsComponent } from './vortex/page/donor-flags/donor-flags.component';
import { DonorCallSheetsComponent } from './vortex/page/donor-call-sheets/donor-call-sheets.component';
import { LoginComponent } from './global/page/login/login.component';
import { TwoFactorAuthComponent } from './vortex/page/two-factor/two-factor-auth.component';
import { TwoFactorOptionsComponent } from './vortex/page/two-factor-options/two-factor-options.component';
import { PhoneVerificationComponent } from './global/page/phone-verification/phone-verification.component';
import { RegisterComponent } from './global/page/register/register.component';
import { DashboardComponent } from './global/page/dashboard/dashboard.component';
import { authGuard } from './vortex/guards/auth.guard';
import { UserCampaignInviteComponent } from './vortex/page/user-campaign-invite/user-campaign-invite.component';
import { StyleguideComponent } from './global/page/styleguide/styleguide.component';
import { DefaultComponent } from './template/default/default.component';
import { FullPageComponent } from './template/full-page/full-page.component';
import { SelectCampaignComponent } from './global/page/select-campaign/select-campaign.component';
import { DonorCardComponent } from './vortex/page/donor-card/donor-card.component';
import { UsersComponent } from './global/page/users/users.component';
import { AdminComponent } from './vortex/page/admin/admin.component';
import { LoadingPageComponent } from './global/page/loading-page/loading-page.component';
import { ForgetPasswordComponent } from './vortex/page/forget-password/forget-password.component';
import { ResetPasswordComponent } from './vortex/page/reset-password/reset-password.component';


let urlMap:Map<string, string>|null = null;

export const routes: Routes = [
    {
        path: 'loading',
        component: LoadingPageComponent,
    },

        {
          path: 'login',
          component: FullPageComponent,
          children: [
              { 
                      path: '', 
                      component: LoginComponent 
              },
          ]
        },
        {
          path: 'twofa-options',
          component: FullPageComponent,
          children: [
            {
              path: '',
              component: TwoFactorOptionsComponent
            },
          ]
        },
        {
          path: 'twofa-auth',
          component: FullPageComponent,
          children: [
            {
              path: '',
              component: TwoFactorAuthComponent
            },
          ]
        },
        {
          path: 'phone-verification',
          component: FullPageComponent,
          children: [
            {
              path: '',
              component: PhoneVerificationComponent
            },
          ]
        },
        {
        path: 'forgot-password',
        component: FullPageComponent,
        children: [
            {
                path: '',
                component: ForgetPasswordComponent
            },
        ]
    },
    {
        path: 'reset-password',
        component: FullPageComponent,
        children: [
            {
                path: '',
                component: ResetPasswordComponent
            },
        ]
    },
    {
        path: 'register',
        component: FullPageComponent,
        data: {
            breadcrumb: "Register",
        },
        children: [
            { 
                path: '', 
                component: RegisterComponent 
            },
        ]
        },
    {
        path: '',
        component: DefaultComponent,
        children: [
            {
                path: 'campaign-selection',
                data: {
                    breadcrumb: "Select a Campaign"
                },
                component: SelectCampaignComponent,
                canActivate: [authGuard]
            },
            { 
                path: "vortex", 
                data: {
                    breadcrumb: "Home",
                },
                component: CampaignComponent,
                canActivate: [authGuard]
            },
            {
                path: "vortex/campaign-dashboard", 
                component: DashboardComponent, 
                pathMatch: "full",
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Campaign Dashboard",
                }
            },

            // DONORS 
            //--------------------------------------------------------------------
            {
                path: "vortex/donors/donor-search", 
                component: SearchPageComponent, 
                pathMatch:"full",
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donors",
                }
            },
            {
                path: "vortex/donors/card/:id",
                component: DonorCardComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donor"
                }
            },
            {
                path: "vortex/donors/add-new-donor", 
                component: DonorAddComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Add New Donor",
                },
            },
            {
                path: "vortex/donors/donor-dedupe", 
                component: DonorManualDedupesComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Dedupe Donors",
                },
            },
            {
                path: "vortex/donors/donor-flags", 
                component: DonorFlagsComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donor Flags",
                },
            },
            {
                path: "vortex/donors/donor-call-sheets", 
                component: DonorCallSheetsComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donor Call Sheets",
                },
            },

            // DONATIONS  
            //--------------------------------------------------------------------
            { 
                path: "vortex/donations/donation-search", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donations",
                }
            },
            {
                path: "vortex/donations/add-new-donation", 
                loadComponent: () => import('./vortex/page/donations-add/donations-add.component').then( (x) => x.DonationsAddComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Add New Donation",
                    },
            },
            {
                path: "vortex/donations/donation-batches", 
                loadComponent: () => import('./vortex/page/donations-batches/donations-batches.component').then( (x) => x.DonationsBatchesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donation Batches",
                    },
            },
            {
                path: "vortex/donations/donation-flags", 
                loadComponent: () => import('./vortex/page/donations-flags/donations-flags.component').then( (x) => x.DonationsFlagsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donation Flags",
                    },
            },

            // FUNDRAISING ACTIVITY 
            //--------------------------------------------------------------------
            { 
                path: "vortex/fundraising-activity/fundraisers", 
                component: SearchPageComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraisers",
                }
            },
            {
                path: "vortex/fundraising-activity/events", 
                loadComponent: () => import('./vortex/page/fundraisers-events/fundraisers-events.component').then( (x) => x.FundraisersEventsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Events",
                    },
            },
            {
                path: "vortex/fundraising-activity/initiatives", 
                loadComponent: () => import('./vortex/page/fundraisers-initiatives/fundraisers-initiatives.component').then( (x) => x.FundraisersInitiativesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Initiatives",
                    },
            },

            // EXPENSES
            //--------------------------------------------------------------------
            { 
                path: "vortex/expenses/expense-search", 
                component: SearchPageComponent, 
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Expense Search",
                }
            },
            { 
                path: "vortex/expenses/add-new-expense", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Add New Expense",
                }
            },

            // REPORTING CENTER
            //--------------------------------------------------------------------
            { 
                path: "vortex/reporting-center/system-reports", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "System Reports",
                }
            },
            { 
                path: "vortex/reporting-center/custom-reports", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Custom Reports",
                }
            },

            // FEC
            //--------------------------------------------------------------------
            { 
                path: "vortex/fec/vortex-filer", 
                loadComponent: () => import('./vortex/page/fec-vortex/fec-vortex.component').then( (x) => x.FecVortexComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Vortex Filer",
                }
            },
            { 
                path: "vortex/fec/fec-settings", 
                loadComponent: () => import('./vortex/page/fec-settings/fec-settings.component').then( (x) => x.FecSettingsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "FEC Settings",
                }
            },
            { 
                path: "vortex/fec/fec-report-cleanup", 
                loadComponent: () => import('./vortex/page/fec-report-cleanup/fec-report-cleanup.component').then( (x) => x.FecReportCleanupComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "FEC Report Cleanup",
                }
            },

            // JFC
            //--------------------------------------------------------------------
            { 
                path: "vortex/jfc/jfc-allocations", 
                loadComponent: () => import('./vortex/page/jfc-allocations/jfc-allocations.component').then( (x) => x.JfcAllocationsComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "JFC Allocations",
                }
            },
            // TODO: jfc-batches folder and file should be renamed to distribution-batches
            { 
                path: "vortex/jfc/distribution-batches", 
                loadComponent: () => import('./vortex/page/jfc-batches/jfc-batches.component').then( (x) => x.JfcBatchesComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Distribution Batches",
                }
            },

            // ===================================================================
            // VORTEX LITE
            //--------------------------------------------------------------------
            { 
                path: "vortex-lite", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraising Tally",
                }
            },
            { 
                path: "vortex-lite/fundraising-tally", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraising Tally",
                }
            },
            { 
                path: "vortex-lite/events", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Events",
                }
            },
            { 
                path: "vortex-lite/fundraisers", 
                loadComponent: () => import('./global/page/donor/donor.component').then( (x) => x.DonorComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Fundraisers",
                }
            },
            { 
                path: "vortex-lite/donor-flags", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Donor Flags",
                }
            },
            { 
                path: "vortex-lite/club-settings", 
                loadComponent: () => import('./global/page/search/search.page.component').then( (x) => x.SearchPageComponent ),
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Club Settings",
                }
            },

            // ===================================================================
            // APEX
            //--------------------------------------------------------------------
            { 
                path: "apex/my-widgets", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "My Widgets",
                }
            },
            { 
                path: "apex/apex-settings", 
                component: PageNotFoundComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "APEX Settings",
                }
            },

            // ===================================================================
            // ADMIN TOOLS
            //--------------------------------------------------------------------
            { 
                path: "admin",
                component: AdminComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Admin",
                },

                children: [
                    { 
                        path: "donors-donations", 
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Donors And Donations",
                        },
                        children: [
                            {
                                path: "donor-bulk-update",
                                component: DonorBulkUpdateComponent, 
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Donor Bulk Update",
                                },
                            },
                            // TODO: donations-bulk-update folder and file shouldbe renamed to donation-bulk-upload
                            {
                                path: "donation-bulk-upload", 
                                loadComponent: () => import('./vortex/page/donations-bulk-update/donations-bulk-update.component').then( (x) => x.DonationsBulkUpdateComponent ),
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Donation Bulk Upload",
                                    },
                            },
                            {
                                path: "compliance-letters", 
                                loadComponent: () => import('./vortex/page/donations-compliance-letters/donations-compliance-letters.component').then( (x) => x.DonationsComplianceLettersComponent ),
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Compliance Letters",
                                    },
                            }
                        ]
                    },
                    { 
                        path: "campaign-settings", 
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Campaign Settings",
                        },
                        children: [
                            { 
                                path: "campaign-list", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Campaign List",
                                }
                            },
                            { 
                                path: "fund-codes", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Fund Codes",
                                }
                            },
                            { 
                                path: "donation-source-rates", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Donation Source Rates",
                                }
                            },
                            { 
                                path: "accounting-system", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Accounting System",
                                }
                            },
                            { 
                                path: "compliance", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Compliance",
                                }
                            },
                            { 
                                path: "search-throttle", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Search Throttle",
                                }
                            },
                            { 
                                path: "donor-campaign-limits", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Donor Campaign Limits",
                                }
                            },
                            { 
                                path: "campaign-types", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Campaign Types",
                                }
                            },
                            { 
                                path: "default-approver", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Default Approver",
                                }
                            }
                        ]
                    },
                    { 
                        path: "organization-settings", 
                        component: PageNotFoundComponent,
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Organization Settings",
                        },
                    },
                    { 
                        path: "users",
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "users",
                        },
                        children: [
                            { 
                                path: "invite-new-user", 
                                component: UserCampaignInviteComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Invite New User",
                                }
                            },
                            { 
                                path: "all-users", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "All Users",
                                }
                            },
                            { 
                                path: "user-bulk-upload", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "User Bulk Upload",
                                }
                            },
                        ]
                    },
                    { 
                        path: "system-errors", 
                        canActivate: [authGuard],
                        data: {
                            breadcrumb: "Campaign Settings",
                        },
                        children: [
                            { 
                                path: "login-errors", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Login Errors",
                                }
                            },
                            { 
                                path: "logout-errors", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Logout Errors",
                                }
                            },
                            { 
                                path: "transaction-errors", 
                                component: PageNotFoundComponent,
                                canActivate: [authGuard],
                                data: {
                                    breadcrumb: "Transaction Errors",
                                }
                            },
                        ]
                    },

                ]
            },

            // CATCH-ALL 
            //--------------------------------------------------------------------
            { 
                path: "", 
                component: CampaignComponent,
                canActivate: [authGuard],
                data: {
                    breadcrumb: "Campaign Selection",
                },
            },
            ]
        },
        {
        path: "styleguide",
        component: StyleguideComponent,
        },

    // { 
    //     path: "**", 
    //     data: {
    //         breadcrumb: "Page Not Found",
    //     },
    //     component: PageNotFoundComponent,
    // },
    ];


/**
 * 
 * @param user 
 */
// export const getRoutesForUser = (user:any):any => {

//     const routes:Array<any> = [];
//     if(user.apps.get("vortex") !== undefined){
//         routes.push(...vortexRoutes);
//         // routes.push(...vortexFECRoutes);
//         // routes.push(...vortexJFCRoutes);
//     }
//     if(user.apps.get("vortex-lite") !== undefined){
//         routes.push(...vortexLiteRoutes);
//     }
//     if(user.apps.get("apex") !== undefined){
//         routes.push(...apexRoutes);
//     }
//     if(user.apps.get("admin") !== undefined){
//         routes.push(...adminRoutes);
//     }

//     console.debug(routes);
//     return routes;
// }
