<div class="flex justify-content-center align-items-center" style="height: 100vh;">
  <p-toast />
  <p-card class="w-3" styleClass="card-style-account">
    <ng-template pTemplate="header">
      <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
    </ng-template>

    <div class="flex justify-content-center">
      <span class="h3">Reset Password</span>
    </div>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <div class="field">
        <label for="newPassword">New Password</label>
        <div class="p-inputgroup">
          <input pInputText id="newPassword"
                 formControlName="newPassword"
                 [type]="showPassword ? 'text' : 'password'"
                 (input)="validatePassword()" />
          <span class="p-inputgroup-addon" (click)="togglePasswordVisibility()">
            <i class="pi cursor" [ngClass]="{'pi-eye': !showPassword, 'pi-eye-slash': showPassword}"></i>
          </span>
        </div>

        <div class="block mt-2">
          <span [ngClass]="{'text-success': passwordValidations.uppercase, 'text-danger': !passwordValidations.uppercase}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.uppercase, 'pi-times': !passwordValidations.uppercase}"></i>
            At least one uppercase letter (A-Z).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.lowercase, 'text-danger': !passwordValidations.lowercase}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.lowercase, 'pi-times': !passwordValidations.lowercase}"></i>
            At least one lowercase letter (a-z).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.number, 'text-danger': !passwordValidations.number}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.number, 'pi-times': !passwordValidations.number}"></i>
            At least one numeric digit (0-9).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.specialChar, 'text-danger': !passwordValidations.specialChar}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.specialChar, 'pi-times': !passwordValidations.specialChar}"></i>
            At least one special character (e.g., !&#64;#$%^&*).
          </span><br>
          <span [ngClass]="{'text-success': passwordValidations.minLength, 'text-danger': !passwordValidations.minLength}">
            <i class="pi" [ngClass]="{'pi-check': passwordValidations.minLength, 'pi-times': !passwordValidations.minLength}"></i>
            At least 8 characters.
          </span>
        </div>
      </div>
      <div class="flex justify-content-end">
        <button pButton type="submit" class="mt-2" label="Reset Password" [disabled]="resetPasswordForm.invalid"></button>
      </div>
    </form>
  </p-card>
</div>
