<p-dialog [(visible)]="visible" [modal]="true" [closable]="true" header="Red Curve Solutions® SaaS Service Account" [style]="{ width: '400px' }">
  <div class="content">
    <h3>Access to RCS SaaS Services requires company authorization</h3>
    <p>
      Please contact your campaign representative to receive an invitation for a new user account.
    </p>
    <hr />
    <h4>Contact Us</h4>
    <p>
      <strong>Red Curve Solutions, LLC</strong><br />
      138 Conant Street, 2<sup>nd</sup> Floor<br />
      Beverly, MA 01915
    </p>
    <p>
      <strong>Phone:</strong> 617-303-6800<br />
      <strong>E-mail:</strong> <a href="mailto:client&#64;redcurve.com">client&#64;redcurve.com</a>
    </p>
    <p-footer>
      <button pButton label="OK" icon="pi pi-check" (click)="closeModal($event)"></button>
    </p-footer>
  </div>
</p-dialog>
