<p-iconField iconPosition="left">
    <p-inputIcon styleClass="pi pi-search" />
    <input 
        type="text" 
        pInputText 
        [placeholder]="placeholder" 
        />
</p-iconField>

<!--
 *ngIf="controlType === FormTypeConstants.Search"

        [(value)]="controlType"

-->