import { Component, OnInit } from '@angular/core';
import { ServerClientService } from '../../services/data/CampaignService/server-client.service';
import { MainModel } from '../../model/MainModel';
import { NgFor, NgIfContext } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import Campaign from '../../model/Campaign';

@Component({
  selector: 'app-post-login-loading',
  standalone: true,
  imports: [NgFor, RouterLink],
  templateUrl: './post-login-loading.component.html',
  styleUrl: './post-login-loading.component.scss'
})
export class PostLoginLoadingComponent implements OnInit {

  constructor(
    private serverClient: ServerClientService,
    private router:Router,
    protected model:MainModel
  ){
    
  }

  ngOnInit(){
    //const x = this.serverClient.getPostLoginData();
    //console.log(x);
  }

  // setting this willtrigger call to get apps and features...
  selectCampaign(campaign:Campaign){
    console.log(campaign);
    this.model.selectedCampaign.set(campaign);
  }

  hasLocalSavedCampaign:boolean = false;
  curentMessage:string = "We are getting campaigns you have accessed recently or ";

}
