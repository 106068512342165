import { Component } from '@angular/core';

@Component({
  selector: 'rc-campaign',
  standalone: true,
  imports: [],
  templateUrl: './campaign.component.html',
  styleUrl: './campaign.component.scss'
})
export class CampaignComponent {

}
