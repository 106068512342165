<div class="account-settings-container">
  <!-- Account Settings Modal -->
  <p-toast></p-toast>
  <!-- Personal Information Section -->
  <p-card styleClass="mb-3 pt-2 pb-4">
    <h2>Personal Information</h2>
    <form [formGroup]="personalInfoForm">
      <div class="formgrid grid">
        <div class="field col-12 sm:col-6">
          <label for="firstName" class="field-label required">First Name</label>
          <input pInputText id="firstName" formControlName="firstName" />
        </div>

        <div class="field col-12 sm:col-6">
          <label for="lastName" class="field-label required">Last Name</label>
          <input pInputText id="lastName" formControlName="lastName" />
        </div>

        <div class="field col-12 sm:col-6">
          <label for="email" class="field-label required">Account Email</label>
          <div class="input-container">
            <input pInputText id="email" formControlName="email" type="email" [attr.disabled]="true"  />
            <!-- <button pButton label="Verify" (click)="verifyEmail()" class="p-button-sm verify-btn"></button>-->
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label for="phone" class="field-label required">Account Phone</label>

          <p-inputGroup styleClass="input-group">
            <p-inputMask id="phone" formControlName="phone" mask="(999) 999-9999" placeholder="(123) 456-7890" class="flex-input" />

              <p-button *ngIf="!personalInfoForm.get('isPhoneVerified')?.value"
                [label]="phoneVerificationLabel"
                [icon]="phoneVerificationIcon"
                [severity]="phoneVerificationSeverity"
                [disabled]="isVerifying"
                (click)="handlePhoneVerification()" />

              <p-button *ngIf="personalInfoForm.get('isPhoneVerified')?.value"
                label="Verified" 
                icon="pi pi-check-circle" 
                severity="success" 
                disabled></p-button>
          </p-inputGroup>
        </div>
      </div>


      <!-- Phone Verification Section (Only show if not verified) -->
      <div *ngIf="!personalInfoForm.get('isPhoneVerified')?.value && showPhoneVerification" class="verification-section mt-3">
        <p>
          An SMS has been sent to <strong>{{ personalInfoForm.get('phone')?.value }}</strong>.
          Please enter the verification code below.
          If you didn't receive a verification SMS,
          <a href="#" (click)="resendPhoneVerification($event)">send another code</a> or
          <a href="#" (click)="cancelPhoneVerification($event)">cancel your phone update</a>.
        </p>

        <div class="formgrid grid">
          <div class="col-12 sm:col-6">
            <input pInputText type="text" formControlName="phoneVerificationCode" placeholder="Enter verification code" />
          </div>
          <div class="col-12 sm:col-6">
            <p-button [label]="verifyCodeButtonLabel" (click)="submitPhoneVerification()" severity="secondary" [outlined]="true" size="small"></p-button>
          </div>
        </div>
      </div>


      <!-- Warning Message -->
      <p-message severity="warn" text="Email and phone number you choose here will be used for login and two-factor authentication of your account." styleClass="mt-3"></p-message>
    </form>
  </p-card>

  <!-- Update Password Section -->
  <p-card styleClass="mb-3 pt-2 pb-4">
    <h2>Update Password</h2>
    <button *ngIf="!showPasswordSection" pButton label="Update Password" (click)="togglePasswordSection()" class="p-button-secondary"></button>

    <div *ngIf="showPasswordSection">
      <form *ngIf="showPasswordSection" [formGroup]="passwordForm">
        <div class="formgrid grid">
          <!-- Current Password -->
          <div class="field col-12 sm:col-6 sm:mr-6">
            <label for="currentPassword" class="field-label required">Current Password</label>
            <div class="p-inputgroup">
              <input pInputText id="currentPassword"
                     formControlName="currentPassword"
                     [type]="showCurrentPassword ? 'text' : 'password'" />
              <span class="p-inputgroup-addon cursor" (click)="toggleCurrentPasswordVisibility()">
                <i class="pi" [ngClass]="{'pi-eye-slash': !showCurrentPassword, 'pi-eye': showCurrentPassword}"></i>
              </span>
            </div>
          </div>

          <!-- New Password -->
          <div class="field col-12 sm:col-6">
            <label for="newPassword" class="field-label required">New Password</label>
            <div class="p-inputgroup">
              <input pInputText id="newPassword"
                     formControlName="newPassword"
                     [type]="showPassword ? 'text' : 'password'"
                     (input)="validatePassword()" />
              <span class="p-inputgroup-addon cursor" (click)="togglePasswordVisibility()">
                <i class="pi" [ngClass]="{'pi-eye-slash': !showPassword, 'pi-eye': showPassword}"></i>
              </span>
            </div>
          </div>

          <!-- Confirm Password -->
          <div class="field  col-12 sm:col-6">
            <label for="confirmPassword" class="field-label required">Confirm New Password</label>
            <div class="p-inputgroup">
              <input pInputText id="confirmPassword"
                     formControlName="confirmPassword"
                     [type]="showConfirmPassword ? 'text' : 'password'" />
              <span class="p-inputgroup-addon cursor" (click)="toggleConfirmPasswordVisibility()">
                <i class="pi" [ngClass]="{'pi-eye-slash': !showConfirmPassword, 'pi-eye': showConfirmPassword}"></i>
              </span>
            </div>
          </div>
        </div>
      </form>


      <!-- Password Rules List -->
      <div class="password-validation">
        <div [ngClass]="{'text-success': passwordValidations.uppercase, 'text-danger': !passwordValidations.uppercase}">
          <i class="pi inline-block mr-2" [ngClass]="{'pi-check': passwordValidations.uppercase, 'pi-times': !passwordValidations.uppercase}"></i>
          At least one uppercase letter (A-Z).
        </div>
        <div [ngClass]="{'text-success': passwordValidations.lowercase, 'text-danger': !passwordValidations.lowercase}">
          <i class="pi inline-block mr-2" [ngClass]="{'pi-check': passwordValidations.lowercase, 'pi-times': !passwordValidations.lowercase}"></i>
          At least one lowercase letter (a-z).
        </div>
        <div [ngClass]="{'text-success': passwordValidations.number, 'text-danger': !passwordValidations.number}">
          <i class="pi inline-block mr-2" [ngClass]="{'pi-check': passwordValidations.number, 'pi-times': !passwordValidations.number}"></i>
          At least one numeric digit (0-9).
        </div>
        <div [ngClass]="{'text-success': passwordValidations.specialChar, 'text-danger': !passwordValidations.specialChar}">
          <i class="pi inline-block mr-2" [ngClass]="{'pi-check': passwordValidations.specialChar, 'pi-times': !passwordValidations.specialChar}"></i>
          At least one special character (e.g., !&#64;#$%^&*).
        </div>
        <div [ngClass]="{'text-success': passwordValidations.minLength, 'text-danger': !passwordValidations.minLength}">
          <i class="pi inline-block mr-2" [ngClass]="{'pi-check': passwordValidations.minLength, 'pi-times': !passwordValidations.minLength}"></i>
          At least 8 characters.
        </div>
      </div>

      <div class="mt-3" *ngIf="showPasswordSection">
        <button pButton label="Update" class="p-button-secondary mr-2" (click)="updatePassword()"></button>
        <button pButton label="Cancel" class="p-button-secondary p-button-outlined" (click)="cancelPasswordUpdate()"></button>
      </div>

    </div>
  </p-card>

  <div class="mb-6 pb-3"></div>

  <!-- Action Buttons -->
  <div class="imported-dialog-footer">
    <button pButton label="Cancel" (click)="cancel()" class="p-button-text"></button>
    <button pButton label="Save" (click)="save()"></button>
  </div>
</div>
