<p-breadcrumb class="breadcrumb" [model]="navItems" *ngIf="isShowBreadcrumb">
    <ng-template pTemplate="item" let-item>
      <ng-container *ngIf="item.label === 'Home'">
            <a [routerLink]="item.url" class="p-menuitem-link p-breadcrumb-home"><i class="fa-sharp fa-light fa-house-flag"></i>&nbsp;{{ item.label }}</a>
        </ng-container>
        <ng-container *ngIf="item.label !== 'Home'">
            <a [routerLink]="item.url" class="p-menuitem-link">
                <span class="">{{ item.label }}</span>
            </a>
        </ng-container>
    </ng-template>
</p-breadcrumb>