import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../vortex/services/auth.service';

@Component({
  selector: 'app-phone-verification',
  standalone: true,
  imports: [CommonModule, ButtonModule, InputTextModule, CardModule, ToastModule, ReactiveFormsModule],
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss'],
  providers: [MessageService, AuthService]
})
export class PhoneVerificationComponent implements OnInit {
  @Input() phoneNumber: string = ''; 
  @Input() email: string = ''; 
  @Output() verificationCompleted = new EventEmitter<boolean>();

  verificationForm!: FormGroup;
  isCodeSent = false;
  isVerifying = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.phoneNumber = params['phone'] || '';
      this.email = params['email'] || '';

      // Initialize the form with the verification code disabled
      this.verificationForm = this.fb.group({
        phoneNumber: [this.phoneNumber, [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
        email: [this.email, [Validators.required, Validators.email]],
        verificationCode: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
      });
    });
  }


  sendVerificationCode() {
    const phoneNumber = this.verificationForm.value.phoneNumber;
    const email = this.verificationForm.value.email;

    // Validate phone number (ensure it is not null and matches the pattern)
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid phone number' });
      return;
    }

    const payload = { phoneNumber, email };

    this.authService.generateSms2fa(payload).subscribe({
      next: () => {
        this.isCodeSent = true;
        this.verificationForm.controls['verificationCode'].enable(); 
        this.messageService.add({ severity: 'success', summary: 'Code Sent', detail: 'Verification code sent to your phone' });
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send verification code' });
      }
    });
  }


  verifyCode() {
    const verificationCode = this.verificationForm.value.verificationCode;

    // Validate verification code (ensure it is not null and follows the format)
    if (!verificationCode || !/^\d{6}$/.test(verificationCode)) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid verification code. It should be a 6-digit number.' });
      return;
    }

    this.isVerifying = true;

    this.authService.verifyPhoneNumber({
      userId: this.email,
      verificationCode: verificationCode,
    }).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Verified', detail: 'Phone number and email verified' });
        this.verificationCompleted.emit(true);
        this.isVerifying = false;
        this.router.navigate(['login']);
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid verification code' });
        this.isVerifying = false;
      }
    });
  }

}
