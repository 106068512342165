import { Component } from '@angular/core';
import { CampaignSwitcherComponent } from "../../component/campaign-switcher/campaign-switcher.component";

@Component({
  selector: 'rc-select-campaign',
  standalone: true,
  imports: [CampaignSwitcherComponent],
  templateUrl: './select-campaign.component.html',
  styleUrl: './select-campaign.component.scss'
})
export class SelectCampaignComponent {


}
