export const authInterceptor = (req: any, next: any) => {

    const token = getJwtTokenFromCookie();
    console.log(token);
    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
      return next(cloned);
    }

    return next(req);
  }

  const getJwtTokenFromCookie = (): string | null => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith('rcs_auth_cookie='));
    return cookie ? cookie.split('=')[1] : null;
  }

