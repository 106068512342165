import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../services/auth.service';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CardModule, ReactiveFormsModule, ToastModule, InputTextModule, ButtonModule, CommonModule],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [MessageService, AuthService]
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup;
  showPassword: boolean = false;
  passwordValidations = {
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    minLength: false
  };
  private email: string = '';
  private token: string = '';
  private passwordPattern = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$';

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'] ? decodeURIComponent(params['email']) : '';
      this.token = params['token'] ? decodeURIComponent(params['token']).replace(/ /g, '+') : '';
    });

    this.resetPasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordPattern)]]
    });
  }

  get newPassword() {
    return this.resetPasswordForm.controls['newPassword'];
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  validatePassword(): void {
    const passwordControl = this.resetPasswordForm.get('newPassword');
    if (!passwordControl) return;
    const password = passwordControl.value || '';
    this.passwordValidations = {
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      minLength: password.length >= 8
    };

    // Ensure all validation conditions match the regex pattern
    const isValid = this.passwordValidations.uppercase &&
      this.passwordValidations.lowercase &&
      this.passwordValidations.number &&
      this.passwordValidations.specialChar &&
      this.passwordValidations.minLength;

    // If all rules are passed, update form validity
    if (isValid) {
      passwordControl.setErrors(null);
    } else {
      passwordControl.setErrors({ invalidPassword: true });
    }

    // Ensure Angular detects the changes
    passwordControl.markAsTouched();
    passwordControl.markAsDirty();
   // passwordControl.updateValueAndValidity();
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      const payload = { email: this.email, token: this.token, newPassword: this.resetPasswordForm.value.newPassword };
      this.authService.resetPassword(payload).subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password reset successful' });
          setTimeout(() => {
            this.router.navigateByUrl("login");
          }, 3000);
        },
        error: () => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid reset request' });
        }
      });
    }
  }
}
