
<div id="app-container">
  
    <header id="page-header">
      <p-toast />
      <app-app-nav *ngIf="this.display.profile >= SCREEN_PROFILE.LARGE" />
    
      <div id="brandBar">
        <div id="mobile-nav-actions">
          <button 
            type="button" 
            id="mobile-menu-btn" 
            class="header-btn js-menu-closed p-button p-button-text" 
            type="button" 
            [attr.aria-expanded]="this.model.navigation.currentState === 'open'"
            role="button" 
            aria-label="Menu" 
            (click)="toggle()">
            <i class="fa-sharp fa-light fa-bars menu-closed" *ngIf="this.model.navigation.currentState === 'closed'"></i>
            <i class="fa-sharp fa-light fa-xmark-large menu-open" *ngIf="this.model.navigation.currentState === 'open'"></i>
          </button>
          <button id="mobile-search-btn" class="header-btn -initial p-button p-button-text" type="button" aria-expanded="false" role="button" aria-label="Search">
            <i class="fa-sharp fa-light fa-magnifying-glass"></i>
          </button>
        </div>

        <!-- TODO: logo and link should be conditional based on the open product -->
        <div class="header-logo-wrapper">
          <a id="home-link" href="#home">
            <img src="/assets/images/logo-vortex.png" id="logo-vortex" />  
            <!-- <img src="/assets/images/logo-vortex-lite.png" id="logo-vortex-lite" />
            <img src="/assets/images/logo-apex.png" id="logo-apex" /> 
            <img src="/assets/images/logo-admin-tools.png" id="logo-admin-tools" />  -->
          </a>
        </div>
        
        <div class="global-quicksearch search-open">
          <p-inputGroup>
            <p-dropdown 
              [options]="areas" 
              [(ngModel)]="selectedSearchArea" 
              optionLabel="name" 
              placeholder="Select" />
            <input type="text" pInputText class="global-quicksearch-input" placeholder="Keyword" [(ngModel)]="searchKeyword" />
            <button type="button" pButton icon="pi pi-search" class="quicksearch-button button-search" (click)="globalSearch()"></button>
            <!-- button-close should be programattically rendered on mobile only -->
            <button type="button"  *ngIf="this.display.profile < SCREEN_PROFILE.LARGE" pButton icon="pi pi-times" class="quicksearch-button button-close"></button>
          </p-inputGroup>
        </div>

        <div class="back-button-div mobile-view">
          <a (click)="goBack()" class="p-button p-button-text back-button">
              <i class="pi pi-angle-left"></i>
          </a>
        </div>

        <rc-campaign-switcher [isDockable]="true" />
        
      </div>
    </header>
    
    <div id="mainArea">

      <div id="sidenav" class={{model.navigation.currentState}}>
        <app-app-nav mode="apps-only" *ngIf="this.display.profile <= SCREEN_PROFILE.LARGE" />
        <rc-campaign-switcher [isDockable]="true" *ngIf="this.display.profile <= SCREEN_PROFILE.LARGE" />
        <rc-main-nav />
        <app-app-nav mode="account-only" *ngIf="this.display.profile <= SCREEN_PROFILE.LARGE" />
      </div>
      <!-- (update)="selectCampaign($event)" -->
      <main>
        <div *ngIf="isOpen" id="paper">
          <div id="nav-actions">
            <app-breadcrumb />
            <div class="back-button-div desktop-view">
              <a (click)="goBack()" class="p-button p-button-text back-button">
                  <i class="pi pi-angle-left"></i>
              </a>
            </div>
          </div>
          <div id="componentContainer" role="main">
            <router-outlet></router-outlet>       
          </div>  
        </div>
  
        <footer id="page-footer">
          <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
          <div id="copyright">
            <span>©2023 by Red Curve Solutions®</span>  |  <span>All Rights Reserved</span>  | <span>138 Conant Street, Beverly, MA 01915</span>  |  <span>617-303-6800</span>  |  <span>RCS v3.0</span>
          </div>
          <div id="action-top">
            <button id="action-top-button" class="p-button p-button-icon-only p-button-no-shadow bg-redwood-40">
              <i class="fa-sharp fa-light fa-chevron-up"></i>
            </button>
          </div>
        </footer>
  
      </main>
    </div>

</div>

<p-dialog [(visible)]="showSearchDialog"
          [modal]="true"
          [maximizable]="true"
          header="Search Donors"
          styleClass="p-dialog-static p-dialog-lg fadeinright animation-duration-300 global-quicksearch-dialog">

  <app-search-page *ngIf="showSearchDialog"
    [showInDialog]="true"
    [selectedSearchArea]="selectedSearchArea" 
    [searchKeyword]="searchKeyword" (cancelEvent)="handleCancel()">
  </app-search-page>

</p-dialog>

<!--   
  {{navModel | json}} -->
