import { Component } from '@angular/core';

@Component({
  selector: 'app-donor-add',
  standalone: true,
  imports: [],
  templateUrl: './donor-add.component.html',
  styleUrl: './donor-add.component.scss'
})
export class DonorAddComponent {

}
