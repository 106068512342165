import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageModule } from 'primeng/message';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CardModule } from 'primeng/card';
import { AuthService } from '../../services/auth.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-two-factor-auth',
  standalone: true,
  imports: [
    CardModule,
    CommonModule,
    ButtonModule,
    RadioButtonModule,
    MessageModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastModule
  ],
  providers: [AuthService],
  templateUrl: './two-factor-options.component.html',
  styleUrls: ['./two-factor-options.component.scss']
})
export class TwoFactorOptionsComponent implements OnInit {

  twoFactorForm: FormGroup;
  options: { type: string, value: string }[] = [];
  errorMessage = '';
  email: string = '';

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService,
    private msgService: MessageService,
    private router: Router
  ) {
    this.twoFactorForm = this.fb.group({
      selectedOption: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['email']) {
        this.email = params['email'];
      }
      if (params['contactOptions']) {
        try {
          this.options = JSON.parse(params['contactOptions']); // Parse login options from query params
        } catch (error) {
          this.errorMessage = 'Invalid two-factor options received.';
        }
      }

      // Auto-select and send code if only one option exists
      if (this.options.length === 1) {
        this.twoFactorForm.patchValue({ selectedOption: this.options[0].value });
        this.sendCode();
      }
    });
  }

  sendCode(): void {
    if (this.twoFactorForm.valid) {
      const selected = this.twoFactorForm.value.selectedOption;

      if (this.options.length === 1) {
        // Use the first available option automatically
        this.trigger2fa(this.options[0]);
      } else {
        this.trigger2fa(selected);
      }
    }
  }

  private trigger2fa(selected: { type: string, value: string }): void {
    if (selected.type === 'Email') {
      this.authService.generateEmail2fa(this.email).subscribe({
        next: () => {
          this.msgService.add({ severity: 'success', summary: 'Success', detail: '2FA code sent successfully.' })
          this.router.navigate(["twofa-auth"], {
            queryParams: {
              email: this.email,
              method: 'Email'
            }
          });
        },
        error: () => this.errorMessage = 'Failed to send two-factor code. Please try again.'
      });
    } else {
      this.authService.generateSms2fa({ email: this.email, phoneNumber: null }).subscribe({
        next: () => {
          this.msgService.add({ severity: 'success', summary: 'Success', detail: '2FA code sent successfully.' })
          this.router.navigate(["twofa-auth"], {
            queryParams: {
              email: this.email,
              method: 'Phone'
            }
          });
        },
        error: () => this.errorMessage = 'Failed to send two-factor code. Please try again.'
      });
    }
  }
}
