
<!-- <p-card header="Bar Charts">
    <p class="m-0">
        <p-chart type="bar" [data]="basicData" [options]="basicOptions" />
    </p>
</p-card> -->

<h2>Campaign Dashboard</h2>
<div class="flex pt-4">
    <div class="full-width border full-heigh" pDroppable style="height:300px">
        @for (item of components; track item.type) {
            <drag-and-drop [type]="item.type" [componentImg]="item.thumb" [componentName]="item.title" [renderPreview]="false"/>
        }
    </div>
    <div class="full-width border full-heigh" pDraggable style="height:300px">
        <ng-template ngFor let-item [ngForOf]="selectedComponents" let-i="index">
            <drag-and-drop componentImg="item.thumb" componentName="item.title" />
          </ng-template>
    </div>
</div>