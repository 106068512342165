import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ServerClientService } from '../../global/services/data/CampaignService/server-client.service';
import { MainModel } from '../../global/model/MainModel';
import NavModel from '../../global/model/NavModel';
import { DashboardComponent } from '../../global/page/dashboard/dashboard.component';
import { BreadcrumbComponent } from "../../global/component/breadcrumb/breadcrumb.component";
import { AppBarComponent } from "../../global/component/app-bar/app-bar.component";
import { CampaignSwitcherComponent } from "../../global/component/campaign-switcher/campaign-switcher.component";
import { MainNavComponent } from "../../global/component/main-nav/main-nav.component";
import { ToastModule } from 'primeng/toast';
import { NgIf } from '@angular/common';
import {Location} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DisplayService, SCREEN_PROFILE } from '../../global/services/ui/DisplayService';
import { DialogModule } from 'primeng/dialog';
import { GenericButtonComponent } from '../../global/component/generic/generic-button/generic-button.component';
import { SearchPageComponent } from '../../global/page/search/search.page.component'; 
interface SearchArea {
  name: string;
  code: string;
}

@Component({
  selector: 'app-default',
  standalone: true,
  imports: [RouterOutlet, NgIf, BreadcrumbComponent, AppBarComponent, CampaignSwitcherComponent, MainNavComponent, ToastModule,
  FormsModule, DropdownModule, ButtonModule, InputGroupModule, InputTextModule,DialogModule, GenericButtonComponent, SearchPageComponent],
  templateUrl: './default.component.html',
  styleUrl: './default.component.scss'
})

export class DefaultComponent implements OnInit{
  isOpen = true;
  isShowBreadcrumb:boolean = false;
  displayComponent:any = DashboardComponent;
  currentApp: string = "admin";
  campaignList: any = [];
  SCREEN_PROFILE = SCREEN_PROFILE;

  //  areas: SearchArea[] | undefined;
   areas: SearchArea[] = [
    { name: 'Donors', code: 'NY' },
    { name: 'Donations', code: 'RM' },
    { name: 'Expenses', code: 'LDN' },
    { name: 'Batches', code: 'IST' },
    { name: 'Bundlers', code: 'PRS' },
    { name: 'Events', code: 'EV' }
  ];
  selectedSearchArea: SearchArea | undefined;
  searchKeyword: string = '';
  showSearchDialog: boolean = false;

  toggle() {
    console.debug(this.model.navigation.currentState)
    //this.isOpen = !this.isOpen;
    this.model.navigation.toggleMainNavState();
  }

  constructor(
    private serverClient: ServerClientService, 
    private contexts: ChildrenOutletContexts|null, 
    protected model:MainModel,
    private router:Router,
    private activeRoute:ActivatedRoute,
    private _location: Location,
    protected display:DisplayService
    ) {
      router.events.subscribe((value:any) => {
        if(value instanceof NavigationEnd){
              
          if(value instanceof NavigationEnd){
              console.debug(this.activeRoute);
              }
          }
        });
        
    };

    goBack(){
      this._location.back();
    }

    toggleMenu(){
      this.model.navigation.toggleMainNavState();
    }

    ngOnInit() {
      this.selectedSearchArea= this.areas[0];
    }

    clearSearchCriteria(){
      this.showSearchDialog = false;
      this.selectedSearchArea = undefined;
      this.searchKeyword = '';
      this.selectedSearchArea= this.areas[0];
    }
    
    globalSearch() {
      if(!this.selectedSearchArea) { 
        alert('please select a search area');
        return;
      };
      if(!this.searchKeyword.trim()) {
        alert('please enter a keyword');
        return;
      };
      this.showSearchDialog = true;
    }

    handleCancel(){
      this.showSearchDialog =false;
    }
}

