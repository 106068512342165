import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from '../../../global/services/ui/NavService';
import { UserPermissionsService } from '../../../global/services/permissions/UserPermissionsService';
import { MainModel } from '../../../global/model/MainModel';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-two-factor-auth',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    ToastModule,
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule
  ],
  providers: [
    AuthService,
    MessageService,
    NavService
  ],
  templateUrl: './two-factor-auth.component.html',
  styleUrl: './two-factor-auth.component.scss'
})
export class TwoFactorAuthComponent {
  twofaForm: FormGroup;
  email: string = '';
  method: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private http: HttpClient,
    private navService: NavService,
    private route: ActivatedRoute,
    private model: MainModel,
    private router: Router,
    private permissions: UserPermissionsService,
    private location: Location
  ) {
    this.twofaForm = this.fb.group({
      code: ['', [Validators.required]],
      remember2fa: [false] // Default unchecked
    });
  }

  ngOnInit() {
    this.email = this.route.snapshot.queryParamMap.get('email') as string;
    this.method = this.route.snapshot.queryParamMap.get('method') as string;
  }

  verifyCode() {
    const formValues = this.twofaForm.value;

    this.authService.verify2fa({
      email: this.email,
      method: this.method,
      code: formValues.code,
      rememberDevice: formValues.remember2fa
    }).subscribe({
      next: (data: any) => {
        if (formValues.remember2fa) {
          localStorage.setItem('remember2fa', 'true'); // Store preference in local storage
        }

        this.permissions.securityModel.isAdminRole = data.isAdmin && data.isAdmin === true;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in.' });
        this.router.navigateByUrl('/loading');

        this.navService.loadRequiredModels(() => {
          setTimeout(() => {
            if (!this.model.selectedCampaign()) {
              this.router.navigateByUrl("select-campaign");
            } else {
              this.navService.navigateToDefaultPageRemote();
            }
          }, 3000);
        });
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Code.' });
      }
    });
  }

  goBack() {
    this.location.back();
  }
}
