import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { APP_CONFIG } from '../../config/config.token';
import { AppConfig } from '../../config/app.config';
import { AuthService } from '../../../vortex/services/auth.service';
import { RegisterModel } from '../../model/RegisterModel';
import { passwordMatchValidator } from '../../validators/passwordMatchValidator';
import { GlobalAsyncValidatorService } from '../../services/data/UserInviteService/global-async-validator-service';


@Component({
  selector: 'app-register',
  standalone: true,
  imports: [InputTextModule, RouterLink, CommonModule, ReactiveFormsModule, ButtonModule, CardModule, ToastModule],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  //registerForm: FormGroup;
  inviteCode: string | null = null;
  showPassword: boolean = false;
  passwordValidations = {
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    minLength: false
  };

  // Define the regex pattern
  //• RequireDigit: Requires at least one numeric digit(0-9). 
  //• RequiredLength: Specifies the minimum length of the password.Default is 8 characters.
  //• RequireNonAlphanumeric: Requires at least one non - alphanumeric character(e.g., !, @, #). 
  //• RequireUppercase: Requires at least one uppercase letter(A - Z). 
  //• RequireLowercase: Requires at least one lowercase letter(a - z). 
  //• RequiredUniqueChars: Specifies the minimum number of unique characters in the password.Default is 1.
  private passwordPattern = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,}$';

  registerForm: FormGroup = this.fb.group({
    firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
    lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
    email: [
      '',
      [Validators.required, Validators.email],
      [this.validatorService.validateUserInvitationCode(this.activateRoute.snapshot.queryParamMap.get('code'))]
    ],
    phoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),  // Ensures min length is 8
        Validators.pattern(this.passwordPattern) // Enforces regex rules
      ]
    ],
    confirmPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),  // Ensures min length is 8
        Validators.pattern(this.passwordPattern) // Enforces regex rules
      ]
    ]
  }, {
    validators: passwordMatchValidator
  });

  constructor(
    private fb: FormBuilder,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private destroyRef: DestroyRef,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private validatorService: GlobalAsyncValidatorService
  ) {

   
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  validatePassword(): void {
    const passwordControl = this.registerForm.get('password');
    if (!passwordControl) return;

    const password = passwordControl.value || '';
    this.passwordValidations = {
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      minLength: password.length >= 8
    };

    const isValid = Object.values(this.passwordValidations).every(v => v);
    if (isValid) {
      passwordControl.setErrors(null);
    } else {
      passwordControl.setErrors({ invalidPassword: true });
    }

    passwordControl.markAsTouched();
    passwordControl.markAsDirty();
  }

  ngOnInit() {
    this.inviteCode = this.activateRoute.snapshot.queryParamMap.get('code');
    if (!this.inviteCode) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invitation code is missing!' });
    }
  }

  get firstName() {
    return this.registerForm.controls['firstName'];
  }

  get lastName() {
    return this.registerForm.controls['lastName'];
  }

  get email() {
    return this.registerForm.controls['email'];
  }

  get phoneNumber() {
    return this.registerForm.controls['phoneNumber'];
  }

  get password() {
    return this.registerForm.controls['password'];
  }

  get confirmPassword() {
    return this.registerForm.controls['confirmPassword'];
  }

  submitDetails() {
    const postData = { ...this.registerForm.value };
    delete postData.confirmPassword;
    postData.inviteCode = this.activateRoute.snapshot.queryParamMap.get('code');
    const subscription = this.authService.register(postData as RegisterModel).subscribe({
      next: (response: any) => {
        console.debug(response);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Register successfully' });
        this.router.navigate(['phone-verification'], {
          queryParams: {
            phone: postData.phoneNumber,
            email: postData.email
          }
        });

      },
        error: (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.error?.message ?? 'Error while registering user!' });
        }
    });

    // destroy all subscriptions to prevent memory leaks.
    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }
}
