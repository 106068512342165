import { Component, effect, HostListener, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation  } from 'angular-animations';
import { MainModel } from './global/model/MainModel';
import { ServerClientService } from './global/services/data/CampaignService/server-client.service';
import App from './global/model/App';
import Campaign from './global/model/Campaign';
import { NavService } from './global/services/ui/NavService';
import { JsonPipe } from '@angular/common';
import { Observable, tap, of, debounceTime } from 'rxjs';
import { DisplayService } from './global/services/ui/DisplayService';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    JsonPipe
],
  animations: [
    fadeInOnEnterAnimation(), 
    fadeOutOnLeaveAnimation(), 
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document:Document,
    protected model:MainModel,
    private serverClient:ServerClientService,
    private navService:NavService,
    private activatedRoute:ActivatedRoute,
    protected router:Router,
    private displayService:DisplayService
  ){

    this.model.navigation.document = document;

    /**
     * On Campaign Selection we:
     * 1. save it as the last accessed campaign
     * 2. get aplications and features as per the campaign
     */
    effect(() => {
      // const c:Campaign|null|undefined = this.model.selectedCampaign();
      // console.debug(`The campaign is: ${this.model.selectedCampaign()?.campaignId}`);
      // if(c){
      //   this.navService.saveLasAccessedCampaign(c);
      // }
      });
    };

    
    @HostListener('window:resize', ['$event'])
    windowChange(evt:UIEvent){
      this.displayService.onResize( );
    }

    /**
     * On every page load we check to see that we have:
     * 1. list of campaigns
     * 2. last (most recently) accessed campaigns
     */
  ngOnInit() { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(this.router.url !== "/login"){
          this.navService?.loadRequiredModels();
        }
      }
    });
  }
}
