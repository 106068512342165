import { Component, effect } from '@angular/core';
import { CardModule } from 'primeng/card';
import { GenericButtonComponent } from '../../../global/component/generic/generic-button/generic-button.component';
import { TableModule } from 'primeng/table';
import { GenericTableComponent } from '../../../global/component/generic/generic-table/generic-table.component';
import { GridDisplayColumn } from '../../../global/model/SearchModel';
import { DonorFlagService } from '../../services/donor-flag.service';
import { MainModel } from '../../../global/model/MainModel';
import { DialogModule } from 'primeng/dialog';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-donor-flags',
  standalone: true,
  imports: [
    CardModule, GenericButtonComponent, TableModule,
    DialogModule, FormsModule, ReactiveFormsModule,
    ConfirmDialogModule,
    NgIf, GenericTableComponent, ToggleButtonModule,
    InputTextModule
  ],
  providers: [DonorFlagService, ConfirmationService],
  templateUrl: './donor-flags.component.html',
  styleUrl: './donor-flags.component.scss'
})
export class DonorFlagsComponent {
  showAddEditDialog: boolean = false;
  isEditMode: boolean = false;
  addEditDonorFlag: FormGroup;

  rows: any[] = [];
  columns: GridDisplayColumn[] = [
    {
      columnName: "status",
      displayColumn: "Status",
      dislayColumnType: "checkbox"
    },
    {
      columnName: "name",
      displayColumn: "Flag",
      dislayColumnType: "text",
      isSortable: true,
      isFilterable: true
    },
    {
      columnName: "description",
      displayColumn: "Flag Description",
      dislayColumnType: "text",
      isSortable: true,
      isFilterable: true
    },
    {
      columnName: "noOfTags",
      displayColumn: "Count",
      dislayColumnType: "text",
      isSortable: true
    },
    {
      columnName: "edit|delete",
      displayColumn: "Actions",
      dislayColumnType: "actions"
    }
  ]

  constructor(private mainModel: MainModel,
              private donorFlagService: DonorFlagService,
              private confirmationService: ConfirmationService,
              private fb: FormBuilder) {

    this.addEditDonorFlag = this.fb.group({
      donorFlagId: [0],      
      name: ['', Validators.required],
      description: ['', Validators.required],
      status: [false, Validators.required]
    })

    effect(() => {
      this.loadDonorFlags();
    });

  }

  loadDonorFlags(): void {
    const c: any = this.mainModel.selectedCampaign();
    this.donorFlagService.getDonorFlags(parseInt(c?.campaignId)).subscribe({
      next: data => {
        this.rows = data;
      },
      error: err => {
        console.error('Unable to load donor flags. Please try again later.', err);
      },
      complete: () => console.debug("Donor flags data loading completed.")
    });
  }

  onActionClicked(eventData: any) {
    switch(eventData.type) {
      case "status":
        this.onDonorFlagStatusChange(eventData.data);
        break;
      case "edit":
        this.onDonorFlagEdit(eventData.data);
        break;
      case "delete":
        this.onDonorFlagDelete(eventData.data);
        break;
    }
  }

  onAddNewFlag() {
    this.isEditMode = false;
    this.showAddEditDialog = true;
    this.addEditDonorFlag.patchValue({
      donorFlagId: 0,
      name: '',
      description: '',
      status: true
    });
  }

  onDonorFlagStatusChange(donorFlag: any) {
    donorFlag.status = !donorFlag.status;    
    this.donorFlagService.saveDonorFlag(donorFlag).subscribe({
      next: (res) => {
        const index = this.rows.findIndex(f => f.donorFlagId === donorFlag.donorFlagId);
        if (index !== -1) {
          this.rows[index] = { ...this.rows[index], status: donorFlag.status };
        }
      }
    });
  }

  onDonorFlagEdit(donorFlag: any) {
    this.isEditMode = true;
    this.showAddEditDialog = true;
    this.addEditDonorFlag.patchValue(donorFlag);
  }

  onDonorFlagDelete(donorFlag: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this donor flag?",
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        donorFlag.isDeleted = true;
        this.donorFlagService.saveDonorFlag(donorFlag).subscribe({
          next: (res) => { 
            window.location.reload();
          }
        });
      }
    })
  }

  onCloseAddEditDialog() {
    this.showAddEditDialog = false;
  }

  onSaveDonorFlag() {
    if (this.addEditDonorFlag.valid) {
      
    const c: any = this.mainModel.selectedCampaign();

      const donorFlag: any = {
        donorFlagId: parseInt(this.addEditDonorFlag.value.donorFlagId || '', 10),
        campaignId: parseInt(c?.campaignId),
        name: this.addEditDonorFlag.value.name || '',
        description: this.addEditDonorFlag.value.description || '',
        status: this.addEditDonorFlag.value.status || false,
        isDeleted: false
      };

      this.donorFlagService.saveDonorFlag(donorFlag).subscribe({
        next: (res) => { 
          window.location.reload();
        }
      });
    }

    console.log("Save donor flag clicked!")
  }

}
