<p-table [value]="rows"
            [paginator]="true"
            [rows]="10"
            [rowsPerPageOptions]="[5, 10, 20]">
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.isSortable ? col.columnName : ''">
                <div class="grid-sortable-title">
                    <div class="column-title">{{col.displayColumn}}</div>
                    <p-sortIcon *ngIf="col.isSortable"
                                [field]="col.columnName"
                                style="cursor: pointer;"
                                id="sort-icon-{{col.columnName}}" />
                </div>
            </th>
        </tr>
        <tr>
            <th *ngFor="let col of columns">
                <p-columnFilter
                    *ngIf="col.isFilterable"
                    type="text"
                    [field]="col.columnName" />
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
        <tr>
            <td *ngFor="let col of columns">
                <span *ngIf="col.dislayColumnType == 'text'">
                    {{ row[col.columnName] }}
                </span>
                <span *ngIf="col.dislayColumnType == 'checkbox'">
                    <rc-generic-button 
                        [label]="col.displayColumn"
                        iconImg="select"
                        [iconColor]="row[col.columnName] == true ? 'teal-light' : 'gray-light'"
                        [isIconOnly]="true"
                        (clickHandler)="onActionClicked(col.columnName, row)"
                        />
                </span>
                <span *ngIf="col.dislayColumnType == 'actions'">                    
                    <rc-generic-button 
                        *ngIf="col.columnName.indexOf('edit') >= 0"
                        label="Edit"
                        iconImg="cog"
                        iconColor="teal-light"
                        [isIconOnly]="true"
                        (clickHandler)="onActionClicked('edit', row)"
                        />
                    <rc-generic-button
                        *ngIf="col.columnName.indexOf('delete') >= 0"
                        label="Delete"
                        iconImg="trash"
                        iconColor="red-light"
                        [isIconOnly]="true"
                        (clickHandler)="onActionClicked('delete', row)"
                        />
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>