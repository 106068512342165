import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TableModule } from "primeng/table";
import { GridDisplayColumn } from "../../../model/SearchModel";
import { CommonModule } from "@angular/common";
import { GenericButtonComponent } from "../generic-button/generic-button.component";

@Component({
    selector: 'rc-generic-table',
    standalone: true,
    imports: [TableModule, CommonModule, GenericButtonComponent],
    templateUrl: './generic-table.component.html',
    styleUrl: './generic-table.component.scss'
  })
  export class GenericTableComponent {
    @Input() columns: GridDisplayColumn[] = [];
    @Input() rows: any[] = [];
    @Output() actionClicked = new EventEmitter<any>();

    onActionClicked(type: string, data: any) {
      this.actionClicked.emit({ type, data });
    }
  }  