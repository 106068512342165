<form [formGroup]="registerForm" (ngSubmit)="submitDetails()">
  <p-card header="Register" [style]="{ width: '360px' }">
    <div class="field">
      <label for="firstName">First Name</label>
      <input type="text"
             pInputText
             formControlName="firstName"
             class="w-full"
             id="register-first-name"
             name="firstName"
             placeholder="Enter your first name" />
      <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
           class="block p-error">
        <div *ngIf="firstName.errors?.['required']">First Name is required.</div>
        <div *ngIf="firstName?.errors?.['pattern']">Invalid characters in First Name.</div>
      </div>
    </div>
    <div class="field">
      <label for="lastName">Last Name</label>
      <input type="text"
             pInputText
             formControlName="lastName"
             class="w-full"
             id="register-last-name"
             name="lastName"
             placeholder="Enter your last name" />
      <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
           class="block p-error">
        <div *ngIf="lastName.errors?.['required']">Last Name is required.</div>
        <div *ngIf="lastName?.errors?.['pattern']">Invalid characters in Last Name.</div>
      </div>
    </div>
    <div class="field">
      <label for="email">Email</label>
      <input type="text"
             pInputText
             formControlName="email"
             class="w-full"
             id="register-email"
             name="email"
             placeholder="Enter your email address" />
      <div *ngIf="email.invalid && (email.dirty || email.touched)"
           class="block p-error">
        <div *ngIf="email.errors?.['required']">Email is required.</div>
        <div *ngIf="email.errors?.['email']">Please enter a valid email address.</div>
        <div *ngIf="email.errors?.['inviteCodeValid']">Email is not associated with a valid invite code.</div>
      </div>
    </div>
    <div class="field">
      <label for="password">Password</label>
      <input type="password"
             pInputText
             formControlName="password"
             class="w-full"
             id="register-password"
             name="password"
             placeholder="Create a password" />
      <div *ngIf="password.invalid && (password.dirty || password.touched)"
             class="block p-error">
        <div *ngIf="password.errors?.['required']">Password is required.</div>
      </div>
    </div>
    <div class="field">
      <label for="confirmPassword">Confirm Password</label>
      <input type="password"
             pInputText
             formControlName="confirmPassword"
             class="w-full"
             id="register-confirm-password"
             name="confirmPassword"
             placeholder="Re-enter your password" />
      <div *ngIf="
          confirmPassword.invalid &&
          (confirmPassword.dirty || confirmPassword.touched)"
             class="block p-error">
        <div *ngIf="confirmPassword.errors?.['required']">
          Please confirm your password.
        </div>
      </div>
      <small class="block p-error"
             *ngIf="registerForm.errors?.['passwordMismatch'] && confirmPassword.valid && password.valid">
        Passwords do not match.
      </small>
    </div>
    <p-button label="Register"
              styleClass="w-full"
              [disabled]="registerForm.invalid"
              type="submit"
              id="register-submit"></p-button>
    <div class="mt-2">Already a member? <a routerLink="/login">Login</a></div>
  </p-card>
</form>
