<div class="flex justify-content-center align-items-center" style="height: 100vh;">
  <p-toast />
  <p-card class="w-2" styleClass="card-style-account">
    <ng-template pTemplate="header">
      <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
    </ng-template>
    <div class="flex justify-content-center">
      <span class="h3">Forgot Password</span>
    </div>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <div class="field">
        <label for="email">Email</label>
        <input pInputText id="email"
               formControlName="email"
               type="email" />
      </div>
      <div class="flex justify-content-end">
        <button pButton type="submit" class="mt-2" label="Submit" [disabled]="forgotPasswordForm.invalid"></button>
      </div>
    </form>
  </p-card>
</div>
