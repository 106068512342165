import {Component, Injectable} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { DashboardComponent } from '../../page/dashboard/dashboard.component';
import { SearchComponent } from '../../component/search/search.component';
import { PageNotFoundComponent } from '../../page/page-not-found/page-not-found.component';
import { MainModel, UserModel } from '../../model/MainModel';
import { MenuItem } from 'primeng/api';
import { concatAll, ConnectableObservable, debounceTime, exhaustAll, forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { LoginComponent } from '../../page/login/login.component';
import { RegisterComponent } from '../../page/register/register.component';
import Campaign from '../../model/Campaign';
import { ServerClientService } from '../data/CampaignService/server-client.service';
import { AnimationStyleMetadata } from '@angular/animations';
import App from '../../model/App';
import { Admin_Tools, Anonymous_Navigation, NavOrder, NavSetsByAppName } from '../../model/NavModel';
import { UserPermissionsService } from '../permissions/UserPermissionsService';
import { Permission, SecurityModel } from '../../model/SecurityModel';


/**
 * The NavService will be instantiated by the root services object. THis object should register itself to:
 * 1. listen for route changes - if a route event happens and current application models are populated the NavService 
 * will request that those calls me made. 
 */
@Injectable({
    providedIn: 'root'
  })
export class NavService {

    constructor(
        private activedRoute:ActivatedRoute,
        private serverClient:ServerClientService,
        private router:Router,
        private model:MainModel,
        private permissionsAPI:UserPermissionsService
    ){

    }
    
    
    /**
     * This works on the statically configured navigation set (contains icons, etc...).
     * @param objOrArray 
     * @param parentObj 
     * @returns 
     */
    replicatePermissionsUpTheTree(objOrArray:any, parentObj:any = undefined){
        if(objOrArray.length){
            objOrArray.forEach( (i:any) => this.replicatePermissionsUpTheTree(i,parentObj) );
        }
        if(objOrArray.items?.length){
            objOrArray.items.forEach( (i:any) => this.replicatePermissionsUpTheTree(i,objOrArray) );
        }
        // feature level - as nav is only 1 level deep right now..
        if(parentObj && !parentObj.length){
            if(objOrArray.featureIds?.length)parentObj.featureIds.push(...objOrArray.featureIds);
            if(parentObj.pageIds === undefined)parentObj.pageIds = [];
            if(objOrArray.pageId)parentObj.pageIds.push(objOrArray.pageId);
        } 
        return objOrArray;
    }
    
    /**
     * The final stage of setting viewable navigation - the apps and features have been downloaded, selected,
     * permissions decided and set as per the models - and here is where we tweek the final view state.
     */
    setNavState(){
        const app:App|null = this.model.selectedApp();
        console.debug("selected app", app)
        if(app === null){
            this.model.navigation.applicationNavset = Anonymous_Navigation;
        } else {
            if(app?.name){
                let navSet:any = NavSetsByAppName.get(app.name);

                if(navSet){
                    if(app.isAdminApp === true){
                        this.model.navigation.applicationNavset = navSet;
                        return;
                    }
                    const outout:Array<any> = [];
                    const tmpNav = structuredClone(navSet);
                    this.replicatePermissionsUpTheTree(tmpNav);
                    // permissions were set in ingest function in the permissions api but the current navigation rules are to NOT show ityems that the user has access None to...
                    tmpNav.forEach(
                        (item:any) => {
                            // top level nav items are features and pages...
                            const p:Permission = this.permissionsAPI.getAccessFromFlattenedArray(item.featureIds, item.pageIds);
                            if(p > Permission.None)outout.push(item);
                            const subItems:Array<any> = [];
                            // 2nd level items are pages (currently)...)
                            item.items?.forEach(
                                (j:any) => {
                                    const p2:Permission = this.permissionsAPI.getPageAccess(j.pageId);
                                    if(p2 > Permission.None)subItems.push(j);
                                }   
                            );
                            item.items = subItems;
                        }
                    )
                    this.model.navigation.applicationNavset = outout;
                }
            }
        }
    }

    /**
     * This will copy subpermisisons - e.g. feature ids and page ids from sub nav elements into parent 
     * nav elements so we know which trees to render.
     */
    raisePermissions(navSet:any){


    }



    /**
     * Sets the current navigation structure based upon the currently selected application. 
     * @param user 
     * @param app 
     * @returns 
     */
    setCurrentNavModel = (app:App):void => {
        this.model.selectedApp.set(app);
        this.setNavState();
    }

    setAdminTools(isAdmin:boolean){
        const _adminLabel = "Admin Tools";
        if(isAdmin === true){
            const a = new App();
            a.id = 99999;
            a.name = _adminLabel;
            a.features = Admin_Tools;
            a.isAdminApp = true;
            this.model.productsAndFeatures.push(a);
        } else {
            if(this.model.productsAndFeatures[this.model.productsAndFeatures.length - 1]?.name === _adminLabel){
                this.model.productsAndFeatures.pop();
            }
        }

    }


    /**
     * THis is the merge point where 
     * @param c 
     */
    saveLasAccessedCampaign(c:Campaign):void {
        const x = this.serverClient.saveLasAccessedCampaign(c)
            .subscribe(
                (data:any) => {
                    const realOrder:Array<any> = [];
                    data.productsAndFeatures.campaignProducts.forEach((i:any) => realOrder[i.id] = i);
                    this.model.productsAndFeatures = realOrder.filter(i => i?true:false);
                    // --------------------------------------------------------------------------
                    this.model.updateNavigationModel(this.model.productsAndFeatures);
                    this.setCurrentNavModel(this.model.productsAndFeatures[0]);
                    this.setCampaignDefaultPath(c,this.model.productsAndFeatures);
                    // --------------------------------------------------------------------------
                    this.model.selectedCampaign.set(c)
                    if(!this.isOnCampaignSelectionPage())this.navigateToDefaultPage(c, this.router)
                    return data;
                }
            );
    }



    isOnCampaignSelectionPage(){
        return this.router.url === "/campaign-selection";
    }

    loadSelectedCampaignResources(campaign:Campaign, callback:Function|undefined = undefined){
        this.model.selectedCampaign.set(campaign);
        this.serverClient.getProductsAndFeaturesData(campaign.campaignId).subscribe(
            d => {
                this.permissionsAPI.securityModel.isAdminRole = d.isAdmin;
                this.model.productsAndFeatures = d.campaignProducts;
                this.setAdminTools(d.isAdmin);
                
                this.model.updateNavigationModel(this.model.productsAndFeatures);
                this.setCurrentNavModel(this.model.productsAndFeatures[0]);

                if(callback)callback();
            }
        )
    }

        
    /**
     * This function does the loading of models - its up the 
     * UI (depending on which UI is calling this)
     */
    loadRequiredModels(callbak:Function|undefined = undefined){
        if(this.model.campaigns.length === 0){
            const dataObject = {
                lastAccessed: null,
                campaigns: null,
            }
            forkJoin(
                {
                    lastAccessed: this.serverClient.getLastAccessedCampaignData(),
                    campaigns: this.serverClient.getAllCampaignsData()
                }
            ).subscribe(
                data => {
                    this.model.lastAccessedCampaigns = data.lastAccessed;
                    this.model.campaigns = data.campaigns;
                    
                    if(this.model.lastAccessedCampaigns.length > 0){
                        // setting from the list of all in case last accessed isnt accessible anymore...
                        const _lastAccessed =  this.model.lastAccessedCampaigns[0];
                        const _last = this.model.campaigns.filter((i:any) => i.campaignId === _lastAccessed.campaignId)[0];
                        this.loadSelectedCampaignResources(_last, callbak);
                    } else {
                        if(callbak)callbak();
                    }
                }
            )
        } else {
            if(callbak)callbak();
        }
    }



    setCampaignDefaultPath(campaign:Campaign, appsAndFeatures:Array<App> = this.model.productsAndFeatures):void{
        if(appsAndFeatures.length  === 0)return;
        // if already set... maybe processed before or serialized to localstorage?? who knows...
        if(campaign?.defaultPath?.length){
            console.debug("default path already set..");
        } else{
            // lets set the values intrinsically firstby processing the two seperate trees - 
            // the one from the back end - and the one configured on the front end.
            let firstMatch = false;
            try {
                NavOrder.forEach(
                    itm => {
                        if(firstMatch) return;
                        const label = itm.label.toLowerCase();
                        const vals = appsAndFeatures?.forEach((element:App) => {
                            if(firstMatch) return;  
                            const appName:string = element.name.toLowerCase();
                            if(label === appName){  
                                campaign.defaultPath = [appName.toLowerCase().replace("3",""),itm.page];
                                firstMatch = true;
                                }
                        });
                    }
                );
            } catch (error) {
                console.debug(error);
            }
            if(firstMatch === false)console.debug("couldnt find a match for", campaign, appsAndFeatures);
        }
    }

    
    navigateToDefaultPageRemote():void {
        const v:any = this.model.selectedCampaign();
        if(v)this.navigateToDefaultPage(v, this.router);
    }
    


    navigateToDefaultPage(campaign:Campaign, router:Router):void {
        if(campaign !== null){
            if(!campaign.defaultPath){
                this.setCampaignDefaultPath(campaign, this.model.productsAndFeatures);
                //const u = campaign.defaultPath.join("/");
                router.navigate(campaign.defaultPath);
            } else {
                router.navigate(campaign.defaultPath);
            }
        }
    }
    


}
