import { ApplicationConfig } from '@angular/core';
import { PreloadAllModules, provideRouter,withComponentInputBinding, withPreloading } from '@angular/router';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { routes } from "./app.routes"
import { MessageService } from 'primeng/api';
import { APP_CONFIG } from './global/config/config.token';
import { environment } from './global/environments/environment';
import { authInterceptor } from './global/interceptors/auth.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: APP_CONFIG, useValue: environment },
    provideRouter(
      routes, withComponentInputBinding(),
      withPreloading(PreloadAllModules)
    ),
    provideAnimationsAsync(),
    provideHttpClient(
      (
        withFetch(),
        withInterceptors([
          authInterceptor
        ])
      ),
    ),
    MessageService
  ]
};
