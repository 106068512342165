<div id="styleguide">
<h1 class="sg-h1">Styleguide</h1>

<p-tabView class="sg-nav">

    <p-tabPanel header="Intro">
        <h2>Introduction</h2>
        <p>Here you will find examples of basic components, variables, colors and typography styles.</p>
        <ul>
            <li>In most of the UI, we are using relative units of measure - <code>rem</code>s. Pixels are allowed for places where we need precision, such as general page layout. In content layout, please, try sticking to <code>rem</code>s.</li>
            <li><code>1rem</code> is equal to <code>16px</code> with default screen settings.</li>
        </ul>

        <p>Our main UI development and styling resources are:</p>
        <ul>
            <li><a href="https://primeng.org/checkbox" target="_blank">PrimeNG</a> components with default styling</li>
            <li><a href="https://primeflex.org/installation" target="_blank">PrimeFlex</a> CSS utility library</li>
        </ul>
        
    </p-tabPanel>

    <p-tabPanel header="Colors">
        <h2>Colors</h2>

        <p class="mb-4">NOTE: not all listed colors are currently used in the UI. But it is prudent to provide a full range of color variants for any future need.</p>

        <h4>UI design color Sass variables</h4>

        <h5>Neutrals</h5>
        <p>Greys are used in basic content elements, for example:</p>
        <ul>
            <li><code>neutral-80</code> is used as a base text color</li>
            <li><code>neutral-40</code> is used as a placeholder text color</li>
            <li><code>neutral-20</code> is used in table and form elements borders</li>
            <li><code>neutral-10</code> is used as a background color of the disabled buttons</li>
        </ul>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-0 neutral-100">neutral-0</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-5 neutral-100">neutral-5</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-10 neutral-100">neutral-10</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-20 neutral-100">neutral-20</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-30 neutral-100">neutral-30</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-40 neutral-100">neutral-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-50 neutral-0">neutral-50</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-60 neutral-0">neutral-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-70 neutral-0">neutral-70</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-80 neutral-0">neutral-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-90 neutral-0">neutral-90</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-neutral-100 neutral-0">neutral-100</div>
            </div>
        </div>

        <h5>Reds</h5>
        <p>Reds are used sparingly throughout the UI to indicate:</p>
        <ul>
            <li>RedCurve brand - <code>red-60</code></li>
            <li>Primary action elements such as primary buttons and links - <code>red-60</code> for button background and <code>red-100</code> for button shadow</li>
            <li><code>red-100</code> is used in the product top nav</li>
        </ul>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-red-40 neutral-0">red-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-red-60 neutral-0">red-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-red-80 neutral-0">red-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-red-100 neutral-0">red-100</div>
            </div>
        </div>

        <h5>Teals</h5>
        <p>Teals are ONLY used for clickable action elements:</p>
        <ul>
            <li><code>teal-70</code> is a main link color, <code>teal-80</code> is a link hover</li>
            <li><code>teal-60</code> is used for background color of a secondary use buttons, with <code>teal-100</code> for button shadow</li>
            <li><code>teal-70</code> is also used to indicate selected radio button and a checkbox</li>
            <li><code>teal-5</code> is used as background color of icon buttons and column selector chips</li>
        </ul>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-5 neutral-100">teal-5</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-10 neutral-100">teal-10</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-20 neutral-100">teal-20</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-30 neutral-100">teal-30</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-40 neutral-100">teal-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-50 neutral-100">teal-50</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-60 neutral-0">teal-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-70 neutral-0">teal-70</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-80 neutral-0">teal-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-90 neutral-0">teal-90</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-teal-100 neutral-0">teal-100</div>
            </div>
        </div>

        <h5>Redwoods</h5>
        <p>Redwoods are mostly used as a warmer alternative to greys: </p>
        <ul>
            <li>In large UI elements (page backgrounds, card headers)</li>
            <li><code>redwood-10</code> is used for background color of a tertiary use buttons, with <code>redwood-50</code> for button shadow</li>
            <li><code>redwood-70</code> is used as a text color for some heading elements</li>
            <li><code>redwood-40</code> is a color of main left nav icons</li>
        </ul>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-5 neutral-100">redwood-5</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-10 neutral-100">redwood-10</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-20 neutral-100">redwood-20</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-30 neutral-100">redwood-30</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-40 neutral-100">redwood-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-50 neutral-100">redwood-50</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-60 neutral-0">redwood-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-70 neutral-0">redwood-70</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-80 neutral-0">redwood-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-90 neutral-0">redwood-90</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-redwood-100 neutral-0">redwood-100</div>
            </div>
        </div>

        <h4>UI status color Sass variables</h4>

        <p>The colors below are ONLY to be used in UI status elements, such as alerts, status pills on Expense page, and error messages.</p>

        <h5>Danger</h5>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-danger-10 neutral-100">ui-danger-10</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-danger-20 neutral-100">ui-danger-20</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-danger-40 neutral-100">ui-danger-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-danger-60 neutral-0">ui-danger-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-danger-80 neutral-0">ui-danger-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-danger-100 neutral-0">ui-danger-100</div>
            </div>
        </div>

        <h5>Warning</h5>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-warning-10 neutral-100">ui-warning-10</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-warning-20 neutral-100">ui-warning-20</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-warning-40 neutral-100">ui-warning-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-warning-60 neutral-0">ui-warning-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-warning-80 neutral-0">ui-warning-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-warning-100 neutral-0">ui-warning-100</div>
            </div>
        </div>

        <h5>Success</h5>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-success-10 neutral-100">ui-success-10</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-success-20 neutral-100">ui-success-20</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-success-40 neutral-100">ui-success-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-success-60 neutral-0">ui-success-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-success-80 neutral-0">ui-success-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-success-100 neutral-0">ui-success-100</div>
            </div>
        </div>

        <h5>Info</h5>
        <div class="grid mb-5">
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-info-10 neutral-100">ui-info-10</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-info-20 neutral-100">ui-info-20</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-info-40 neutral-100">ui-info-40</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-info-60 neutral-0">ui-info-60</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-info-80 neutral-0">ui-info-80</div>
            </div>
            <div class="col-3">
                <div class="p-2 text-center border-round-sm bg-ui-info-100 neutral-0">ui-info-100</div>
            </div>
        </div>

        <h4>Utility Color Classes</h4>

        <h5>Text Color Classes</h5>
        <pre><code>{{snippetColor1}}</code></pre>

        <h5>Background Color Classes</h5>
        <pre><code>{{snippetColor2}}</code></pre>

    </p-tabPanel>

    <p-tabPanel header="Typography">

        <h2>Typography</h2>

        <h4>Fonts</h4>

        <p>Vortex 3 is using only one font familiy - Lato - in 3 weights: light (300), normal (400) and bold (700).</p>

        <p>You can view <a href="styleguide/#utility">typography utility classes</a> below.</p>

        <h4>Text Elements</h4>

        <p>Most of the text elements that look like headings or titles are not using heading tags because they are built into the Angular components.</p> 
        
        <p>For example, in the Add New Expense form we have five section headers - Expense Details, Line Items & Attachements, Approvals, etc. Technically, those could be treated as <code>[h2]Expense Details.[/h2]</code> headings, but in the stepper component their content is contained in <code>[p-stepperPanel header="Expense Details"][/p-stepperPanel]</code></p> 
        
        <p class="mb-6">So, in order to apply <code>h2</code> styling use a class <code>.h2</code>. And this applies to most other heading styles, except for <code>h1</code>, which is always the page title and should exist in single instance on the page.</p>
    
        <h1>h1, .h1 - Heading Example</h1>
        <p class="mb-6">Used as a main page title, such as Donor Search or Add New Expense</p>
    
        <h2>h2, .h2 - Heading Example</h2>
        <p class="mb-6">Used in stepper headers on such pages as Add New Donor, Add New Expense, etc.</p>
        
        <h3>h3, .h3 - Heading Example</h3>
        <p class="mb-6">Used as a heading in the page sections. See Event Details page, Event Donations and Event Expenses sections.</p>
        
        <h4>h4, .h4 - Heading Example</h4>
        <p class="mb-6">Used as a sub heading in the form groups. See Itemizations step in Add New Expense form - <code>[h4]Upload Itemizations[/h4]</code>, for example.</p>
        
        <h5>h5, .h5 - Heading Example</h5>
        <p class="mb-6">Not used in the current mockups but might be in the future.</p>

        <h6>h6, .h6  - Heading Example</h6>
        <p class="mb-6">Not used in the current mockups but might be in the future.</p>
        
        <p>p, .p - Standard paragragh example</p>
        <p>Paragraph text inherits typography properties from body element such as font family, weight and size, and only adds a bottom margin for spacing.</p>
        <p class="mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec feugiat ex elit, eget iaculis orci semper pulvinar. Nam nec turpis nibh. Nam facilisis ligula non velit vestibulum mattis</p>

        <h2 id="utility">Utility classes for typography</h2>

        <p><span class="font-italic">Assign a specific style</span> to an element that <i>doesn't have text styling</i> by default, such as a <code>span</code> tag or a an element in the Angular component.</p>

        <h4>Font size classes</h4>
        <pre><code>{{snippetText1}}</code></pre>

        <h4>Font weight classes</h4>
        <pre><code>{{snippetText2}}</code></pre>

        <h4>Font style class</h4>
        <p>You can assign the <code>font-italic</code> class or just use <code>i</code> or <code>em</code> elements. All look the same.</p>

        <h4 id-="text-color">Text color classes</h4>
        <p>We have generated classes for text color using all color variables. Use them sparingly, consult with the UX/UI designer when not sure if you need to assign a special color to an element in question. Refer to Color variable tab to see all color classes available for use in the UI. For example:</p>
        <pre><code>{{snippetText3}}</code></pre>

        <h4>Additional text classes</h4>
        <p>Additional text classes are taken without modification from PrimeFlex and all can be used in the UI development. Refer to the PrimeFlex website for these classes:</p>
        <ul>
            <li><a href="https://primeflex.org/textalign" target="_blank">Text Align</a></li>
            <li><a href="https://primeflex.org/textdecoration" target="_blank">Text Decoration</a></li>
            <li><a href="https://primeflex.org/textoverflow" target="_blank">Text Overflow</a></li>
            <li><a href="https://primeflex.org/texttransform" target="_blank">Text Transform</a></li>
            <li><a href="https://primeflex.org/lineheight" target="_blank">Line Height</a></li>
            <li><a href="https://primeflex.org/whitespace" target="_blank">White Space</a></li>
            <li><a href="https://primeflex.org/liststyletype" target="_blank">List Style Type</a></li>
            <li><a href="https://primeflex.org/verticalalign" target="_blank">Vertical Align</a></li>
        </ul>
        
    </p-tabPanel>

    <p-tabPanel header="Buttons">
        <h2>Buttons</h2>
        <h3>Instantiation</h3>
        <p>When creating instances of a button use the RedCurve generic button. The following attributes can be set:</p>

        <h4>Attributes:</h4>
        <ol>
            <li><b>label</b>:  <br />If "isIconOnly" is set to true then label is used as a "title" attribute on the button</li>
            <li><b>level</b>:  <br />level of the button - Primary (red), Secondary (teal), Tertiary (redwood)</li>
            <li><b>iconImg</b>:  <br />
                Specifies the image to display in the button. <br />Options are: <br/>
                "download" | "dropdown" | "export" | "compare" | 
                "select" | "add-item" | "filter" | "clear-filter" | 
                "trash" | "cog" | "search-money" | "user-minus" | "plus" | 
                 "user-clock" | "user-check" | "upload" | "envelope-dollar" | 
                 "money-check"</li>
            <li><b>borderStyle</b>: <br />Options are: <br />
                "solid" | "outline" | "text-only" | "icon-only" | "link" <br />
                The default is "solid".
            </li>
            <li><b>iconColor</b>:  <br />Options are: <br />
                "gray-light" | "teal-light" | "red-light" | "teal-medium" |"teal-dark" | "gray-dark" | "redwood-light"
                <br />
                The default value is undefined.
            </li>
            <li><b>size</b>:  <br />Options are:<br />
                "small" | "medium" | "large"<br />
                The default is "medium".
            </li>
            <li><b>href</b>: <br /><br />
                If you want the button to naviugate somewhere when clicked pass in a string for this value.
            </li>
            <li><b>round</b>:  <br />Boolean - sets the corners to rounded when set to true (default being "false")</li>
            <li><b>disabled</b>:  <br />Boolean - sets the button to disabled when set to true (default being "false")</li>
            <li><b>isIconOnly</b>:  <br />Boolean - sets the button to only show the icon and sets the label to a "title" when set to true</li>
            <li><b>classes</b>:  <br />Array of strings. Use this to pass down any classes you want attached to the button container and button.</li>
        </ol>

        <h4>Event Handlers:</h4>
        <ol>
            <li><b>clickHandler</b>: Function to call when the button is clicked</li>
        </ol>

        <p>
            There are three types of button levels based on use/importance. Red buttons - primary use, teal buttons - secondary use, and redwood - tertiary.
        </p>
        <p><a href="https://primeng.org/button" target="_blank">PrimeNG Button component info</a></p>

        <h3>Primary</h3>
        <ul>
            <li>Use <b>solid red button</b> for main action on the page. </li>
            <li>Use <b>oulined red button</b> for another action that is related to the main one. For example, "Submit" and "Reset". </li>
            <li>Use red link button for "Cancel" action.</li>
        </ul>
        <div class="grid">
            <div class="col-3">
                <rc-generic-button 
                    label="Primary Solid" 
                    (clickHandler)="showDialog()"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    label="Primary Outlined" 
                    borderStyle="outline"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    label="Primary Text Link" 
                    href="/"
                    borderStyle="link"
                    />
            </div>
        </div>
        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetButton1}}</code></pre>
            </div>
        </div>

        <h3>Secondary</h3>
        <ul>
            <li>Use <b>solid teal button</b> for main action on the page. Use oulined button for second in importance action that is NOT related to the main one. For example, "Export" for main action, and "Compare" as a secondary action. </li> 
            <li>Secondary outlined is <b>not really used</b> in the UI, but we gonna include it for any future need.</li>
            <li>Use <b>teal link button</b> in any places where action should look like a link but have a button functionality. For example, in forms, to open "Add New Fund Code" modal, or open dropdown to show "Additional fields"</li>
        </ul>
        <div class="grid">
            <div class="col-3">
                <rc-generic-button 
                    level="secondary"
                    borderStyle="solid"
                    label="Secondary Solid"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    level="secondary"
                    label="Secondary Outlined" 
                    borderStyle="outline"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    level="secondary"
                    label="Text Link functions like a button" 
                    href="/"
                    borderStyle="link"
                    />
            </div>
        </div>
        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetButton2}}</code></pre>
            </div>
        </div>

        <h3>Tertiary</h3>
        <ul>
            <li>Use redwood buttons for additional actions on the page. </li>
            <li>There are no text link in this color. Only use red or teal.</li>
        </ul>
        <div class="grid">
            <div class="col-3">
                <rc-generic-button 
                    level="tertiary"
                    label="Tertiary Solid"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    level="tertiary"
                    label="Tertiary Outlined"
                    borderStyle="outline"
                    />
            </div>
        </div>
        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetButton3}}</code></pre>
            </div>
        </div>

        <h3>Disabled</h3>
        <ul>
            <li>All disabled buttons look the same, independant of their enabled style and color.</li> 
        </ul>
        <div class="grid">
            <div class="col-3">
                <rc-generic-button 
                    label="Primary Disabled"
                    [disabled]="true"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    label="Primary Disabled"
                    level="secondary"
                    borderStyle="outline"
                    [disabled]="true"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    label="Text Link Disabled"
                    level="secondary"
                    borderStyle="text-only"
                    [disabled]="true"
                    />
            </div>
        </div>
        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetButton4}}</code></pre>
            </div>
        </div>

        <h3>Sizes</h3>
        <ul>
            <li>Size choice is arbitrary, based on available space and position of other elements on the page.</li>
            <li>In current mockups large buttons are used in the top right of the page because there is a lot of white space around them.</li>
            <li>Small buttons are used for places with tight space.</li>
        </ul>
        <div class="grid">
            <div class="col-3">
                <rc-generic-button 
                    label="Small"
                    size="small" 
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    label="Medium"
                    />
            </div>
            <div class="col-3">
                <rc-generic-button 
                    label="Large"
                    size="large"
                    />
            </div>
        </div>
        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetButton5}}</code></pre>
            </div>
        </div>

        <h3>Special buttons with icons</h3>
        <div class="grid">
            <div class="col">
                <rc-generic-button 
                    label="Export"
                    iconImg="download"
                    [classes]="['mx-1']"/>
                <rc-generic-button 
                    label="Compare"
                    iconImg="compare"
                    borderStyle="outline"
                    [classes]="['mx-1']"/>
                <rc-generic-button 
                    label="Selected"
                    iconImg="dropdown"
                    borderStyle="outline"
                    [classes]="['mx-1']"/>
                <rc-generic-button 
                    label="Upload File"
                    iconImg="upload"
                    borderStyle="outline"
                    size="small"
                    [classes]="['mx-1']"/>
                <rc-generic-button 
                    label="Add Line Item"
                    iconImg="plus"
                    borderStyle="outline"
                    size="small"
                    [classes]="['mx-1']"/>
                <rc-generic-button 
                    label="Apply"
                    iconImg="filter"
                    borderStyle="outline"
                    level="tertiary"
                    [classes]="['mx-1']"/>
                <rc-generic-button 
                    label="Clear"
                    iconImg="clear-filter"
                    borderStyle="outline"
                    level="tertiary"
                    [classes]="['mx-1']"/>
                <rc-generic-button 
                    label="Additional Fields"
                    iconImg="plus"
                    borderStyle="text-only"
                    level="secondary"
                    size="small"
                    [classes]="['mx-1']"/>
            </div>
        </div>
        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetButton6}}</code></pre>
            </div>
        </div>

        <h3>Icon-only buttons</h3>
        <div class="grid">
            <div class="col-12">
                <h5>Data table actions</h5>
                <rc-generic-button 
                    label="Delete"
                    iconImg="trash"
                    iconColor="red-light"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="Delete"
                    iconImg="trash"
                    iconColor="gray-light"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="Edit"
                    iconImg="cog"
                    iconColor="teal-light"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="View Donation History"
                    iconImg="search-money"
                    iconColor="redwood-light"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
            </div>
            <div class="col-12 mb-3">
                <pre><code>{{snippetButton7}}</code></pre>
            </div>

            <div class="col-12">
                <h5>Expense table actions - My approvals column</h5>
                <rc-generic-button 
                    label="My approval - unavailable"
                    iconImg="user-minus"
                    iconColor="gray-light"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="My approval - unapproved"
                    iconImg="user-clock"
                    iconColor="gray-light"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="My approval - unapproved"
                    iconImg="user-clock"
                    iconColor="teal-medium"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="My approval - submitted"
                    iconImg="user-clock"
                    iconColor="teal-dark"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="My approval - locked"
                    iconImg="user-clock"
                    iconColor="gray-dark"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
            </div>
            <div class="col-12 mb-3">
                <pre><code>{{snippetButton8}}</code></pre>
            </div>

            <div class="col-12">
                <h5>Expense table actions - RC approvals column</h5>
                <rc-generic-button 
                    label="RC approval - unapproved"
                    iconImg="envelope-dollar"
                    iconColor="gray-light"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="RC approval - approved"
                    iconImg="envelope-dollar"
                    iconColor="teal-medium"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
                <rc-generic-button 
                    label="RC approval - submitted"
                    iconImg="money-check"
                    iconColor="teal-dark"
                    [isIconOnly]="true"
                    [classes]="['mx-1']"
                    />
            </div>
            <div class="col-12">
                <pre><code>{{snippetButton9}}</code></pre>
            </div>
            </div>
</p-tabPanel>
    
    <p-tabPanel header="Forms">
        <h2>Forms</h2>
        <p class="mb-5"><a href="https://primeflex.org/formlayout" target="_blank">More information about form layout</a>, also check out examples below as well as the Grid tab.</p>

        <h3 class="neutral-100">Form Elements</h3>
        <h4>Basic Text Input Field</h4>
        <p>Component structure TDB, do not use yet. For testing CSS styling only for now.</p>
        <p>Please note that required, disabled, and uneditable field states require special class in the parent container: <code>.field.required</code>, <code>.field.disabled</code>, and <code>.field.uneditable</code>. These classes control visual representation of these states. You can simply toggle these classes on and off.</p>
        <p><a href="https://primeng.org/inputtext" target="_blank">PrimeNG InputText component info</a></p>
        <p-card>
            <div class="formgrid grid">
                <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield1" class="field-label">
                        Plain Text Field
                    </label>
                    <input id="textfield1" type="text" pInputText placeholder="Placeholder text" /> 
                    <!-- BUG: this component causing issues with tabs below -->
                    <!-- <rc-text-input id="textfield1" value="hello world" [controlType]="formTypes.Search" /> -->
                </div>
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield2" class="field-label">
                        Required Text Field
                    </label>
                    <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                </div>
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield3" class="field-label">
                        Invalid Text Field
                    </label>
                    <input id="textfield3" type="text" pInputText class="ng-invalid ng-dirty" placeholder="Placeholder text" /> 
                    <small id="textfield3-error" class="error">
                        Error message example
                    </small>
                </div>
                <div class="field disabled col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield4" class="field-label">
                        Disabled Text Field
                    </label>
                    <input id="textfield4" type="text" pInputText [disabled]="true" placeholder="Placeholder text" /> 
                    <small id="textfield4-error" class="error">
                        Error message example
                    </small>
                </div>
                <div class="field uneditable col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield5" class="field-label">
                        Uneditable Text Field
                    </label>
                    <input id="textfield5" type="text" pInputText value="Uneditable view-only content with long text" /> 
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetForm1}}</code></pre>
            </div>
        </div>

        <h4>Basic Dropdown</h4>
        <p>Please note that required and disabled field states require special class in the parent container: <code>.field.required</code> and <code>.field.disabled</code>. These classes control visual representation of these states. You can simply toggle these classes on and off.</p>
        <p>Also, I'm not sure why <code>inputId</code> is not working here - all dropdowns are linked.</p>
        <p><a href="https://primeng.org/dropdown" target="_blank">PrimeNG Dropdown component info</a></p>
        <p-card>
            <div class="formgrid grid">
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="dropdown1" class="field-label">
                        Required Dropdown
                    </label>
                    <p-dropdown 
                        inputId="dropdown1"
                        [options]="cities" 
                        [(ngModel)]="selectedCity" 
                        optionLabel="name" 
                        placeholder="Select a City" />
                </div>
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="dropdown2" class="field-label">
                        Invalid Dropdown
                    </label>
                    <p-dropdown 
                    inputId="dropdown2"
                        [options]="cities" 
                        [(ngModel)]="selectedCity" 
                        optionLabel="name" 
                        placeholder="Select a City"
                        class="ng-dirty ng-invalid" />
                    <small id="dropdown2-error" class="error">
                        Error message example
                    </small>
                </div>
                <div class="field disabled col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="dropdown3" class="field-label">
                        Disabled Dropdown
                    </label>
                    <p-dropdown 
                        inputId="dropdown3"
                        [options]="cities" 
                        [(ngModel)]="selectedCity" 
                        optionLabel="name" 
                        placeholder="Select a City"
                        [disabled]="true" />
                    <small id="dropdown3-error" class="error">
                        Error message example
                    </small>
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetForm2}}</code></pre>
            </div>
        </div>

        <h4>Multiselect Dropdown</h4>
        <p>Please note that required and disabled field states require special class in the parent container: <code>.field.required</code> and <code>.field.disabled</code>. These classes control visual representation of these states. You can simply toggle these classes on and off.</p>
        <p>Also, I'm not sure why <code>inputId</code> is not working here - all dropdowns are linked.</p>
        <p><a href="https://primeng.org/multiselect" target="_blank">PrimeNG Multiselect component info</a></p>
        <p-card>
            <div class="formgrid grid">
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="multiselect1" class="field-label">
                        Required Multiselect
                    </label>
                    <p-multiSelect 
                        inputId="multiselect1"
                        [options]="cities" 
                        [(ngModel)]="selectedCities" 
                        optionLabel="name" 
                        placeholder="Select Cities" />
                </div>
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="multiselect2" class="field-label">
                        Invalid Multiselect
                    </label>
                    <p-multiSelect 
                        inputId="multiselect2"  
                        [options]="cities" 
                        [(ngModel)]="selectedCities" 
                        optionLabel="name" 
                        class="ng-dirty ng-invalid" 
                        placeholder="Select Cities" />
                    <small id="multiselect2-error" class="error">
                        Error message example
                    </small>
                </div>
                <div class="field disabled col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="multiselect3" class="field-label">
                        Disabled Multiselect
                    </label>
                    <p-dropdown 
                        inputId="multiselect3"
                        [options]="cities" 
                        [(ngModel)]="selectedCity" 
                        optionLabel="name" 
                        placeholder="Select a City"
                        [disabled]="true" />
                    <small id="multiselect3-error" class="error">
                        Error message example
                    </small>
                </div>
                <div class="field col-12">
                    <label for="multiselect4" class="field-label">
                        Multiselect with chips (for search columns picker)
                    </label>
                    <p-multiSelect 
                        inputId="multiselect4"
                        [options]="cities" 
                        [(ngModel)]="selectedCities" 
                        placeholder="Select Cities"
                        optionLabel="name" 
                        display="chip" 
                        [showClear]="true" />
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetForm3}}</code></pre>
            </div>
        </div>

        <h4>Basic Checkbox</h4>
        <p><a href="https://primeng.org/checkbox" target="_blank">PrimeNG Checkbox component info</a></p>
        <p-card>
            <div class="formgrid grid">
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="checkbox1" class="field-label">
                        Default Checkbox
                    </label>
                    <p-checkbox 
                        [(ngModel)]="checked" 
                        [binary]="true"
                        inputId="checkbox1"
                        label="Label" />
                </div>
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="checkbox2" class="field-label">
                        Invalid Checkbox
                    </label>
                    <p-checkbox 
                        [(ngModel)]="checked"
                        [binary]="true"
                        inputId="checkbox2"
                        class="ng-invalid ng-dirty"
                        label="Label" />
                </div>
                <div class="field disabled col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="checkbox3" class="field-label">
                        Disabled Checkbox
                    </label>
                    <p-checkbox 
                        [(ngModel)]="checked" 
                        inputId="checkbox3" 
                        [disabled]="true"
                        label="Label" />
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetForm4}}</code></pre>
            </div>
        </div>

        <h4>Basic Radio Button</h4>
        <p><a href="https://primeng.org/radiobutton" target="_blank">PrimeNG RadioButton component info</a></p>
        <p-card>
            <div class="formgrid grid">
                <div class="required col-12 sm:col-6 md:col-4 lg:col-3">
                    <p class="label mb-3">Required radio button group</p>
                    <div class="flex flex-column gap-3">
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Cheese" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient1" />
                            <label for="ingredient1" class="ml-2">
                                Cheese
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Mushroom" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient2" />
                            <label for="ingredient2" class="ml-2">
                                Mushroom
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Pepper" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient3" />
                            <label for="ingredient3" class="ml-2">
                                Pepper
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                    <p class="label mb-3">Invalid radio button group</p>
                    <div class="flex flex-column gap-3">
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Cheese" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient1"
                                class="ng-invalid ng-dirty" />
                            <label for="ingredient1" class="ml-2">
                                Cheese
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Mushroom" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient2"
                                class="ng-invalid ng-dirty" />
                            <label for="ingredient2" class="ml-2">
                                Mushroom
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Pepper" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient3"
                                class="ng-invalid ng-dirty" />
                            <label for="ingredient3" class="ml-2">
                                Pepper
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-4 lg:col-3">
                    <p class="label mb-3">Disabled radio button group</p>
                    <div class="flex flex-column gap-3">
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Cheese" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient1"
                                [disabled]="true" />
                            <label for="ingredient1" class="ml-2">
                                Cheese
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Mushroom" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient2"
                                [disabled]="true" />
                            <label for="ingredient2" class="ml-2">
                                Mushroom
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Pepper" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient3"
                                [disabled]="true" />
                            <label for="ingredient3" class="ml-2">
                                Pepper
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetForm5}}</code></pre>
            </div>
        </div>

        <p-card>
            <div class="formgrid grid">
                <div class="col-12 required">
                    <p class="label mb-3">Horizontal layout for radio button group</p>
                    <div class="flex flex-wrap gap-3">
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Cheese" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient1" />
                            <label for="ingredient1" class="ml-2">
                                Cheese
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Mushroom" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient2" />
                            <label for="ingredient2" class="ml-2">
                                Mushroom
                            </label>
                        </div>
                        
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Pepper" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient3" />
                            <label for="ingredient3" class="ml-2">
                                Pepper
                            </label>
                        </div>
                    
                        <div class="flex align-items-center">
                            <p-radioButton 
                                name="pizza" 
                                value="Onion" 
                                [(ngModel)]="ingredient" 
                                inputId="ingredient4" />
                            <label for="ingredient4" class="ml-2">
                                Onion
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetForm6}}</code></pre>
            </div>
        </div>

        <!-- <p-card header="Toggle">
            <p-inputSwitch [(ngModel)]="checked" />
        </p-card>
    
        
        <p-card header="List Box">
            <p-listbox 
                [options]="cities" 
                [(ngModel)]="selectedCity" 
                optionLabel="name" 
                [style]="{'width':'15rem'}" 
                [listStyle]="{'max-height': '220px'}" />
        </p-card>
        
        <p-card header="Calendar Control">
            <p-calendar [(ngModel)]="date" />
        </p-card> -->

    </p-tabPanel>

    <p-tabPanel header="Grid">
        <h2>Grid</h2>
        <p>Vortex 3 uses PrimeFlex Sass theme to manage page layout with grids. Grid is a lightweight flex based responsive layout utility optimized for mobile phones, tablets and desktops. <a href="https://primeflex.org/gridsystem" target="_blank">More info about PrimeFlex Grid.</a></p>

        <p>PrimeFlex Grid is very similar to Bootstrap grid: it also flexbox based, and uses 12 column layout. For more information about Flex, visit <a target="_blank" href="https://css-tricks.com/snippets/css/a-guide-to-flexbox/">A Complete Guide to Flexbox</a>. A basic grid is defined by giving a container <code>grid</code> class and children the <code>col</code> class. Children of the grid will have the same width and scale according to the width of the parent.</p>

        <p-card>
            <div class="grid">
                <div class="col">
                    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold">1</div>
                </div>
                <div class="col">
                    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">2</div>
                </div>
                <div class="col">
                    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">3</div>
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetGrid1}}</code></pre>
            </div>
        </div>

        <h3>Bootstrap vs PrimeFlex Grid Structure</h3>
        <p>Notice that PrimeFlex has only 2 elements that are needed to render a grid, versus Bootstrap requiring 3.</p>
        <p><b>Bootstrap</b>: <code>.container > .row > .col</code></p>
        <p class="mb-5"><b>PrimeFlex</b>: <code>.grid > .col</code></p>

        <h3>Responsive Grid</h3>
        <p>Again, same as with Bootstrap, PrimeFlex grid uses breakpoint keywords combines with column size to define grid layout for particular screensize. But there is a difference in how it's done.</p>
        <p><b>Bootstrap</b>: <code>col-12 col-sm-6 col-md-4 col-lg-3</code></p>
        <p><b>PrimeFlex</b>: <code>col-12 sm:col-6 md:col-4 lg:col-3</code></p>

        <p>NOTE: I have added 2 extra breakpoints, same as I had in Bootstrap for Vortex 2, to allow more flexibility with extra small and extra lager screens.</p>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetGrid2}}</code></pre>
            </div>
        </div>

        <h4>Media Queries in SCSS</h4>
        <p>When using media queries in SCSS please use the following format.</p>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetGrid3}}</code></pre>
            </div>
        </div>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetGrid4}}</code></pre>
            </div>
        </div>

        <p-card>
            <div class="grid">
                <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 xxl:1">
                    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold">1</div>
                </div>
                <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 xxl:1">
                    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">2</div>
                </div>
                <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 xxl:1">
                    <div class="text-center p-3 border-round-sm bg-red-60 neutral-0 font-bold ">3</div>
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetGrid5}}</code></pre>
            </div>
        </div>

    </p-tabPanel>

    <p-tabPanel header="Spacing">
        <h2>Spacing</h2>

        <p><b>Good news!</b> PrimeFlex padding and margin class names are exactly the same as in Bootstrap. The only difference is what those numbers mean.</p>

        <p><b>Examples:</b> <code>.p-1</code> (padding), <code>.mt-1</code> (margin top), <code>.my-1</code> (margin top and bottom), <code>.px-1</code> (padding left and right)</p>

        <p>Padding and margins sizes defined by spacing scales.</p>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetSpacing1}}</code></pre>
            </div>
        </div>

        <h3>Padding</h3>
        <p><a href="https://primeflex.org/padding" target="_blank">Full list of available padding classes</a>.</p>

        <h3>Margins</h3>
        <p><a href="https://primeflex.org/margin" target="_blank">Full list of available margin classes</a>.</p>

        <h3>Responsive</h3>
        <p>Same idea as in the responsive grid. Add breakpoint keyword in front of the class. For example, <code>p-0 sm:p-1 md:pl-2 lg:my-4</code></p>

    </p-tabPanel>
    
    <p-tabPanel header="Cards">
        <h2>Cards</h2>

        <h3>Simple Card</h3>
        <p>Simple card is essentially a basic container with white background used on top of a darker background for grouping of various elements. It doesn't include header or footer. Common use is for organizing form fields into logical groups. See Stepper tab for another example.</p>

        <p-card styleClass="mb-3">
            <div class="formgrid grid">
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield1" class="field-label">
                        First Name
                    </label>
                    <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
                </div>
                <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield2" class="field-label">
                        Middle Name
                    </label>
                    <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                </div>
                <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                    <label for="textfield3" class="field-label">
                        Last Name
                    </label>
                    <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                </div>
            </div>
        </p-card>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetCard1}}</code></pre>
            </div>
        </div>

        <h3>Advanced Card with Header</h3>
        <p>Used on Donor and Donation Cards pages. May or may not contain and action button to expand content to full screen size.</p>

        <div class="grid">
            <div class="col-12 sm:col-6 lg:col-4">
                <p-card>
                    <ng-template pTemplate="header">
                        <p>Advanced Card</p>
                        <p-button 
                            icon="pi pi-window-maximize"
                            [text]="true"
                            title="Expand"
                        />
                    </ng-template>
                    <p>
                        Lorem ipsum dolor sit amet...
                    </p>
                </p-card>
            </div>
            <div class="col-12 sm:col-6 lg:col-8">
                <p-card>
                    <ng-template pTemplate="header">
                        <p>Advanced Card</p>
                        <p-button 
                            icon="pi pi-window-maximize"
                            [text]="true"
                            title="Expand"
                        />
                    </ng-template>
                    <p>
                        Lorem ipsum dolor sit amet...
                    </p>
                </p-card>
            </div>
        </div>
        
        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetCard2}}</code></pre>
            </div>
        </div>

    </p-tabPanel>

    <p-tabPanel header="Stepper">
        <h2>Stepper</h2>
        <p class="mb-5">Vertical stepper component is replacing tabbed layout that was used for multi-step forms in Vortex 2, such as New Donor, New Donation, New Expense, etc.</p>

        <p-stepper orientation="vertical">
            <p-stepperPanel header="Header I">
                <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                    <p-card styleClass="mb-2">
                        <div class="formgrid grid">
                            <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield1" class="field-label">
                                    First Name
                                </label>
                                <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
                            </div>
                            <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield2" class="field-label">
                                    Middle Name
                                </label>
                                <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                            </div>
                            <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield3" class="field-label">
                                    Last Name
                                </label>
                                <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                            </div>
                        </div>
                    </p-card>

                    <p-card styleClass="mb-2">
                        <div class="formgrid grid">
                            <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield1" class="field-label">
                                    Address
                                </label>
                                <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
                            </div>
                            <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield2" class="field-label">
                                    City
                                </label>
                                <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                            </div>
                            <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield3" class="field-label">
                                    State
                                </label>
                                <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                            </div>
                            <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield3" class="field-label">
                                    Zip
                                </label>
                                <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                            </div>
                        </div>
                    </p-card>

                    <p-card styleClass="mb-1">
                        <div class="formgrid grid">
                            <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield1" class="field-label">
                                    Employer
                                </label>
                                <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
                            </div>
                            <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                                <label for="textfield2" class="field-label">
                                    Occupation
                                </label>
                                <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                            </div>
                        </div>
                    </p-card>

                    <p-card>
                        <p-button label="Next" severity="secondary" size="small" (onClick)="nextCallback.emit()" />
                    </p-card>
                </ng-template>
            </p-stepperPanel>
            <p-stepperPanel header="Header II">
                <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
                    <p-card styleClass="mb-1">
                        <div class="flex flex-column h-12rem">
                            <div class="flex-auto flex justify-content-center align-items-center font-medium">
                                Content II
                            </div>
                        </div>
                    </p-card>
                    <p-card>
                        <p-button label="Back" severity="secondary" [outlined]="true" size="small" (onClick)="prevCallback.emit()" styleClass="mr-2" />
                        <p-button label="Next" severity="secondary" size="small" (onClick)="nextCallback.emit()" />
                    </p-card>
                </ng-template>
            </p-stepperPanel>
            <p-stepperPanel header="Header III">
                <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                    <p-card styleClass="mb-1">
                        <div class="flex flex-column h-12rem">
                            <div class="flex-auto flex justify-content-center align-items-center font-medium">
                                Content III
                            </div>
                        </div>
                    </p-card>
                    <p-card>
                        <p-button label="Back" severity="secondary" size="small" (onClick)="prevCallback.emit()" />
                    </p-card>
                </ng-template>
            </p-stepperPanel>
        </p-stepper>

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetStepper1}}</code></pre>
            </div>
        </div>


    </p-tabPanel>
    
    <!-- <p-tabPanel header="Modals">
        <h2>Modals/Dialogs</h2>
        <p>Modal component in PrimeNG is called a Dialog, and they call "modal" the scrim/mask overlay that goes under the dialog panel. All dialog panels, except for confirmation dialogs, open on the right side at full height of the screen. We have three dialog sizes by width.</p>
        <p>Maximize icon is only used on large modals.</p>
        <p>Back button is conditional - appears only when user opens modal ontop of another modal. Clicking "x" or "close" should close both modals.</p>

        <h3>Large Modal</h3>
        <p>Max width of the large modal is 1060px. On screens below that width it just opens in full screen width.</p>
        <p>Used to display full page content in a context of another page. For example, to open Donor Card from Donation Search page.</p>
        
        <p-card styleClass="justify-content-center p-2">
            <p-button (onClick)="showDialog()" label="Show Large Modal" /> 
            <p-dialog 
                header="Large Modal Header" 
                [modal]="true"
                [(visible)]="visible"
                [maximizable]="true"
                styleClass="p-dialog-static p-dialog-lg" >
                
                <ng-template pTemplate="header">
                    <div class="modal-header">
                        <span class="h4">
                            Large Modal Header
                        </span>
                    </div>
                    <div class="p-dialog-header-icons">
                        <p-button 
                            label="Back" 
                            icon="fa-sharp fa-light fa-chevron-left"
                            styleClass="button-back p-dialog-header-icon mr-2"
                            [text]="true" 
                            size="small"
                        />
                    </div>
                </ng-template>

                <p-card styleClass="mb-2">
                    <div class="formgrid grid">
                        <div class="field required col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
                            <label for="textfield1" class="field-label">
                                First Name
                            </label>
                            <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
                        </div>
                        <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
                            <label for="textfield2" class="field-label">
                                Middle Name
                            </label>
                            <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                        <div class="field required col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
                            <label for="textfield3" class="field-label">
                                Last Name
                            </label>
                            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                        <div class="field required col-12 sm:col-6 md:col-4 lg:col-3 xxl:col-2">
                            <label for="textfield4" class="field-label">
                                Suffix
                            </label>
                            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                    </div>
                </p-card>

                <ng-template pTemplate="footer">
                    <p-button 
                        label="Cancel" 
                        [text]="true" 
                        (onClick)="visible = false" />
                    <p-button 
                        label="Save" 
                        (onClick)="visible = false" />
                </ng-template>
            </p-dialog>
        </p-card> 

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetModal1}}</code></pre>
            </div>
        </div>

        <h3>Medium Modal</h3>
        <p>Max width of the medium modal is 720px. On screens below that width it just opens in full screen width.</p>
        <p>Used to display medium size content. NOTE: medium modal can't be maximized.</p>
        
        <p-card styleClass="justify-content-center p-2">
            <p-button (onClick)="showDialog()" label="Show Medium Modal" /> 
            <p-dialog 
                header="Medium Modal Header" 
                [modal]="true"
                [(visible)]="visible"
                styleClass="p-dialog-static p-dialog-md" >
                
                <ng-template pTemplate="header">
                    <div class="modal-header">
                        <span class="h4">
                            Medium Modal Header
                        </span>
                    </div>
                    <div class="p-dialog-header-icons">
                        <p-button 
                            label="Back" 
                            icon="fa-sharp fa-light fa-chevron-left"
                            styleClass="button-back p-dialog-header-icon mr-2"
                            [text]="true" 
                            size="small"
                        />
                    </div>
                </ng-template>

                <p-card styleClass="mb-2">
                    <div class="formgrid grid">
                        <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                            <label for="textfield1" class="field-label">
                                First Name
                            </label>
                            <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
                        </div>
                        <div class="field col-12 sm:col-6 md:col-4 lg:col-3">
                            <label for="textfield2" class="field-label">
                                Middle Name
                            </label>
                            <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                        <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                            <label for="textfield3" class="field-label">
                                Last Name
                            </label>
                            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                        <div class="field required col-12 sm:col-6 md:col-4 lg:col-3">
                            <label for="textfield4" class="field-label">
                                Suffix
                            </label>
                            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                    </div>
                </p-card>

                <ng-template pTemplate="footer">
                    <p-button 
                        label="Cancel" 
                        [text]="true" 
                        (onClick)="visible = false" />
                    <p-button 
                        label="Save" 
                        (onClick)="visible = false" />
                </ng-template>
            </p-dialog>
        </p-card> 

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetModal2}}</code></pre>
            </div>
        </div>

        <h3>Small Modal</h3>
        <p>Max width of the small modal is 360px. On screens below that width it just opens in full screen width.</p>
        <p>Used to display small size content. NOTE: small modal can't be maximized.</p>
        
        <p-card styleClass="justify-content-center p-2">
            <p-button (onClick)="showDialog()" label="Show Small Modal" /> 
            <p-dialog 
                header="Small Modal Header" 
                [modal]="true"
                [(visible)]="visible"
                styleClass="p-dialog-static p-dialog-sm" >
                
                <ng-template pTemplate="header">
                    <div class="modal-header">
                        <span class="h4">
                            Small Modal Header
                        </span>
                    </div>
                    <div class="p-dialog-header-icons">
                        <p-button 
                            label="Back" 
                            icon="fa-sharp fa-light fa-chevron-left"
                            styleClass="button-back p-dialog-header-icon mr-2"
                            [text]="true" 
                            size="small"
                        />
                    </div>
                </ng-template>

                <p-card styleClass="mb-2">
                    <div class="formgrid grid">
                        <div class="field required col-12">
                            <label for="textfield1" class="field-label">
                                First Name
                            </label>
                            <input id="textfield1" type="text" pInputText placeholder="Placeholder text" />
                        </div>
                        <div class="field col-12">
                            <label for="textfield2" class="field-label">
                                Middle Name
                            </label>
                            <input id="textfield2" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                        <div class="field required col-12">
                            <label for="textfield3" class="field-label">
                                Last Name
                            </label>
                            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                        <div class="field required col-12">
                            <label for="textfield4" class="field-label">
                                Suffix
                            </label>
                            <input id="textfield3" type="text" pInputText placeholder="Placeholder text" /> 
                        </div>
                    </div>
                </p-card>

                <ng-template pTemplate="footer">
                    <p-button 
                        label="Cancel" 
                        [text]="true" 
                        (onClick)="visible = false" />
                    <p-button 
                        label="Save" 
                        (onClick)="visible = false" />
                </ng-template>
            </p-dialog>
        </p-card> 

        <div class="grid">
            <div class="col mb-3">
                <pre><code>{{snippetModal3}}</code></pre>
            </div>
        </div>

    </p-tabPanel>

    <p-tabPanel header="Dialogs">
        <h2>Dialogs</h2>
        <p>These are confirmation dialogs. Uses toasts?</p>
        <p-card styleClass="flex justify-content-center py-4">
            <p-toast />
            <p-confirmDialog />
            <p-button (onClick)="confirm1($event)" label="Save" />
            <p-button (onClick)="confirm2($event)" label="Delete" [outlined]="true" />
        </p-card>
    </p-tabPanel>

    <p-tabPanel header="Toasts">
        <h2>Toasts</h2>
        <p-card styleClass="flex justify-content-center py-4 mb-5">
            <p-toast />
            <p-button (onClick)="showError()" label="Show Error" />
            <p-button (onClick)="showWarn()" label="Show Warning" />
            <p-button (onClick)="showSuccess()" label="Show Success" />
            <p-button (onClick)="showInfo()" label="Show Info" />
        </p-card>

        <h2>Messages</h2>
        <p-card>
            <p-messages 
                [(value)]="messages" 
                [enableService]="false" 
                [closable]="false" />
        </p-card>
    </p-tabPanel> -->
    
</p-tabView>

</div>
