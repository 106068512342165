
<div class="appBar {{mode}}">
  <ul class="product-list" *ngIf="mode === 'full' || mode === 'apps-only'">
    <li (click)="onAppClick(item)" *ngFor="let item of model.productsAndFeatures">
      <a class="product-link {{model.selectedApp()?.id === item?.id ? 'selected' : ''}}">{{ item?.name?.replace("VortexLite", "Vortex Lite") }}</a>
    </li>
  </ul>
  <ul class="bar-actions" *ngIf="mode === 'full' || mode === 'account-only'">
    <li>
      <a class="app-bar-icon" (click)="showAccountDetails()">
        <i class="fa-sharp fa-light fa-user"></i>
        <span>Account</span>
      </a>
    </li>
    <li>
      <a class="app-bar-icon" (click)="logout()">
        <i class="fa-sharp fa-light fa-power-off"></i>
        <span>Log Out</span>
      </a>
    </li>
  </ul>

</div>


<!-- User Account Details Modal -->
<p-dialog [(visible)]="showAccountDetailsModal"
          [modal]="true"
          [closable]="true"
          header="Account Settings"
          styleClass="p-dialog-static p-dialog-md fadeinright animation-duration-300">

  <app-account-settings *ngIf="showAccountDetailsModal" (closeDialog)="closeAccountSettingsDialog()">
  </app-account-settings>

</p-dialog>
