import { Component, DestroyRef } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { AuthService } from '../../../vortex/services/auth.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { UserPermissionsService } from '../../services/permissions/UserPermissionsService';
import { NavService } from '../../services/ui/NavService';
import { MainModel } from '../../model/MainModel';
import { DialogModule } from 'primeng/dialog';
import { TwoFactorAuthComponent } from '../../../vortex/page/two-factor/two-factor-auth.component';
import { HelpModalComponent } from '../help-modal/help-modal.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [InputTextModule,
            CommonModule,
            ReactiveFormsModule,
            ButtonModule,
            CardModule,
            ToastModule,
            RouterLink,
            DialogModule,
            TwoFactorAuthComponent,
            HelpModalComponent],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  show2FA = false;
  emailAddress = '';
  isHelpModalVisible: boolean = false;
  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  })

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private navService: NavService,
    private router: Router,
    private msgService: MessageService,
    private model:MainModel,
    private destroyRef: DestroyRef,
    private permissios:UserPermissionsService,
  ) { }

  get email() {
    return this.loginForm.controls['email'];
  }
  get password() { return this.loginForm.controls['password']; }

  //openHelpModal(event: Event) {
  //  event.preventDefault(); // Prevents the page from refreshing
  //  this.isHelpModalVisible = true;
  //}

  openHelpModal(event: Event) {
    event.preventDefault();
    this.isHelpModalVisible = false; // Reset visibility
    setTimeout(() => {
      this.isHelpModalVisible = true; // Reopen modal after a slight delay
    }, 0);
  }

  loginUser() {
    const { email, password } = this.loginForm.value;
   
    const subscription = this.authService.login({ email: email!, password: password! }).subscribe({
      next: (data: any) => {
        if (data.requiresTwoFactor) {
          this.router.navigate(["twofa-options"], {
            queryParams: {
              email: email,
              contactOptions: JSON.stringify(data.contactOptions || []) // Convert array to string
            }
          });
          //this.router.navigate(["twofa-options"], { queryParams: { email: email } });
         
        } else {
          console.log("User data", data)
          this.permissios.securityModel.isAdminRole = data.isAdmin && data.isAdmin === true;
          this.msgService.add({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in.' });
          this.router.navigateByUrl('/loading');
          this.navService.loadRequiredModels(
            () => {
              setTimeout(
                () => {
                  if (!this.model.selectedCampaign()) {
                    this.router.navigateByUrl("campaign-selection");
                  } else {
                    this.navService.navigateToDefaultPageRemote();
                  }
                },
                3000
              )
            });
        }
      },
      error: (error: any) => {
        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred while trying to log in.' });
      }
    });

    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }
}
