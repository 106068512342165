<div id="donor-card">
  <h1>Donor Card</h1>


  <p-tabView styleClass="donor-card-tabs" (onChange)="switchTab($event)">

    <div class="grid flex-nowrap justify-content-between mx-3">
      <div class="col hidden sm:flex align-items-center">
        <!-- Conditional -->
        <i class="fa-sharp fa-light fa-circle-user donor-type-icon"></i>
        <!-- <i class="fa-sharp fa-light fa-landmark donor-type-icon"></i> -->
      </div>
      <div class="col flex align-items-center" style="flex-grow: 6;" *ngIf="headerData != null">
        <div class="">
          <p class="text-xl md:text-2xl font-bold red-60">{{ headerData.prefix ? headerData.prefix + '.' : '' }} {{ headerData.firstName }} {{ headerData.lastName }} {{ headerData.suffix ? headerData.suffix + '.' : '' }}</p>
          <p class="mb-0">{{ headerData.donorId }}</p>
          <p>{{ headerData.city }}{{ headerData.state ? ', ' + headerData.state : '' }}</p>
        </div>
      </div>
      <div class="col flex align-items-center justify-content-end">
        <p-button styleClass="p-button-icon-teal-light mx-1"
                  icon="fa-sharp fa-light fa-pencil"
                  [rounded]="true"
          title="Delete" />
        <p-button styleClass="p-button-icon-red-light mx-1"
                  icon="fa-sharp fa-light fa-trash-can"
                  [rounded]="true"
          title="Delete" />

      </div>
    </div>

    <p-tabPanel header="General">

      <hr class="divider-dashed mt-0 mb-5">

      <div class="grid mx-3" *ngIf="generalInfo != null">
        <div class="col-6">
          <p><b>Employer: </b>{{ generalInfo.employer }}</p>
          <p><b>Occupation: </b>{{generalInfo.occupation}}</p>
          <p><b>DOB: </b>{{generalInfo.dateOfBirth}}</p>
          <p><b>Inception: </b> </p>
        </div>
        <div class="col-6">
          <p><b>Fundraiser: </b><!-- generalInfo.fecCandidateId}} --> </p>
          <p><b>Legacy ID: </b>{{generalInfo.legacyRecordId}}</p>
          <p><b>FEC Candidate ID: </b> <!-- generalInfo.fecCandidateId}} --></p>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Contact">
      <hr class="divider-dashed mt-0 mb-5">

      <div class="grid mx-3" *ngIf="primaryAddress != null">
        <div class="col-6">
          <p><b>Address: </b>{{primaryAddress.address1}} {{primaryAddress.address2}}</p>
          <p><b>City: </b>{{primaryAddress.city}}</p>
          <p><b>State: </b>{{primaryAddress.state}}</p>
          <p><b>Primary: </b>{{primaryAddress.isPrimaryString}}</p>
        </div>
        <div class="col-6">
          <p><b>Home Phone: </b>{{phoneNumber}} </p>
          <p><b>Primary Email: </b> {{emailAddress}}</p>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Donor Letters">
      <hr class="divider-dashed mt-0 mb-5">
        <div class="col-12">
          <p-table [value]="donorLetters" dataKey="id" [tableStyle]="{ 'min-width': '5rem' }"
            [expandedRowKeys]="expandedRows" [scrollable]="true" scrollHeight="400px">

            <ng-template pTemplate="header">
              <tr>
                <th>Letter Type</th>
                <th>Date Sent</th>
                <th>Response Date</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-donorLetterItem let-expanded="expanded">
              <tr>
                <td>{{ donorLetterItem.letterType }}</td>
                <td>{{ donorLetterItem.dateSent | date : 'M/d/yyyy' }}</td>
                <td>{{ donorLetterItem.responseReceivedDate | date : 'shortDate' }}</td>
                <td>
                  <p-button styleClass="p-button-icon-teal-light mx-1" icon="fa-sharp fa-light fa-pencil"
                    [rounded]="true" title="Delete" />
                  <p-button styleClass="p-button-icon-red-light mx-1" icon="fa-sharp fa-light fa-trash-can"
                    [rounded]="true" title="Delete" />
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" *ngIf="donorLetters.length==0">
              <tr>
                <td [attr.colspan]="4" style="text-align: center;">
                   No records found
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      
    </p-tabPanel>
    <p-tabPanel header="Call Sheets Log">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
    </p-tabPanel>
    <p-tabPanel header="Notes">
      <hr class="divider-dashed mt-0 mb-5">
        <div class="col-12">
          <p-table [value]="donorNotes" dataKey="id" [tableStyle]="{ 'min-width': '5rem' }"
            [expandedRowKeys]="expandedRows" [scrollable]="true" scrollHeight="400px">

            <ng-template pTemplate="header">
              <tr>
                <th>Date</th>
                <th>Note</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-donorNoteItem let-expanded="expanded">
              <tr>
                <td>{{ donorNoteItem.createdDate | date : 'M/d/yyyy' }}</td>
                <td>{{ donorNoteItem.note }}</td>
                <td>
                  <p-button styleClass="p-button-icon-teal-light mx-1" icon="fa-sharp fa-light fa-pencil"
                    [rounded]="true" title="Delete" />
                  <p-button styleClass="p-button-icon-red-light mx-1" icon="fa-sharp fa-light fa-trash-can"
                    [rounded]="true" title="Delete" />
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" *ngIf="donorNotes.length==0">
              <tr>
                <td [attr.colspan]="4" style="text-align: center;">
                   No records found
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Donor Flags">
      <p>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
        qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
      </p>
    </p-tabPanel>
    <p-tabPanel header="Salutation">
      <p>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
        qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
      </p>
    </p-tabPanel>
    <p-tabPanel header="Supression">
      <p>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
        qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
      </p>
    </p-tabPanel>
  </p-tabView>

  <div class="mb-5"></div>

  <div class="grid">
    <div class="col-12 sm:col-6 lg:col-3">

      <p-card>
        <ng-template pTemplate="header">
          <p>Current Cycle</p>
          <p-button icon="pi pi-window-maximize"
                    [text]="true"
                    title="Expand" />
        </ng-template>
        <div class="grid">
          <div class="col">
            <p>Current cycle...</p>
          </div>
        </div>
      </p-card>

    </div>

    <div class="col-12 sm:col-6 lg:col-3">

      <p-card>
        <ng-template pTemplate="header">
          <p>Lifetime History</p>
          <p-button icon="pi pi-window-maximize"
                    [text]="true"
                    title="Expand" />
        </ng-template>
        <div class="grid">
          <div class="col">
            <p>Lifetime history...</p>
          </div>
        </div>
      </p-card>

    </div>

    <div class="col-12 sm:col-6 lg:col-6">

      <p-card>
        <ng-template pTemplate="header">
          <p>Fund Totals</p>
          <p-button icon="pi pi-window-maximize"
                    [text]="true"
                    title="Expand" />
        </ng-template>
        <div class="grid">
          <div class="col-12">
            <p-table [value]="fundcodesHistory" 
                      dataKey="id"
                      [tableStyle]="{ 'min-width': '5rem' }"
                      [expandedRowKeys]="expandedRows" 
                      [scrollable]="true"
                      scrollHeight="400px">

              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 1rem"></th>
                  <th>Campaign</th>
                  <th>Cycle</th>
                  <th>Total</th>
                  <th>Remaining</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-fundcodeHistoryItem let-expanded="expanded">
                <tr>
                  <td>
                    <p-button type="button" pRipple [pRowToggler]="fundcodeHistoryItem" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
                  </td>
                  <td>{{ fundcodeHistoryItem.campaignName }}</td>
                  <td>{{ fundcodeHistoryItem.fundcode }}</td>
                  <td>{{ fundcodeHistoryItem.amount | currency: 'USD' }}</td>
                  <td>
                    <span [ngStyle]="fundcodeHistoryItem.remaining < 0 ? { 'color': 'red' } : {}">{{ fundcodeHistoryItem.remainingAmount | currency: 'USD' }}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-card>

    </div>
  </div>
</div>
