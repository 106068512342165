import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { APP_CONFIG } from '../../global/config/config.token';
import { AppConfig } from '../../global/config/app.config';
import { catchError, map, of } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const http = inject(HttpClient);
  const config: AppConfig = inject(APP_CONFIG);
  
  //const token = getJwtTokenFromCookie();
  return http.get(`${config.apiUrl}authentication/validate-token`, {withCredentials: true})
    .pipe(
      map(resp => {
        if (resp) {
          return true;
        } else {
          return router.parseUrl('/login');
        }
      }),
      catchError(() => {
        // Return to login page if response is 401 or error.
        router.navigate(['login']);
        return [false];
      })
    )
};


const getJwtTokenFromCookie = (): string | null => {
  const cookie = document.cookie.split('; ').find(row => row.startsWith('rcs_auth_cookie='));
  return cookie ? cookie.split('=')[1] : null;
}



