import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../global/config/config.token';
import { AppConfig } from '../../global/config/app.config';
import { Observable, map } from 'rxjs';
import { CampaignApiResponse, UserAdminInviteModel, UserInfo } from '../models/UserInviteModel';
import { NonAdminUserInviteVM } from '../models/NonAdminUserInviteModel';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) { }

  sendAdminUserInvite = (adminUserInviteModel: UserAdminInviteModel): Observable<any> => {
    return this.httpClient.post(`${this.config.apiUrl}userinvite/send-user-invite`, adminUserInviteModel, { withCredentials: true });
  }

  sendNonAdminUserInvite = (nonAdminUserInviteModel: NonAdminUserInviteVM): Observable<any> => {
    return this.httpClient.post(`${this.config.apiUrl}userinvite/send-non-admin-user-invite`, nonAdminUserInviteModel, { withCredentials: true });
  }

  getAllActiveCampaigns = (): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}campaign/list`, { withCredentials: true })
      .pipe(
        map((data: any) => data.filter((d: any) => d.isActive)));
  }

  getAllCampaignsWithChildCampaigns = (): Observable<Array<CampaignApiResponse>> => {
    return this.httpClient.get<CampaignApiResponse[]>(`${this.config.apiUrl}campaign/campaigns-with-child-campaigns`, { withCredentials: true });
  }

  getAllUserList = (): Observable<Array<UserInfo>> => {
    return this.httpClient.get<UserInfo[]>(`${this.config.apiUrl}userinvite/all-user-list`, { withCredentials: true });
  }
}
