import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginModel } from '../../global/model/LoginModel';
import { AppConfig } from '../../global/config/app.config';
import { APP_CONFIG } from '../../global/config/config.token';
import { Router, RouterConfigOptions } from '@angular/router';
import { RegisterModel } from '../../global/model/RegisterModel';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router) { }

  login = (loginModel: LoginModel): Observable<any> => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/login`, loginModel, { withCredentials: true });
  }

  register = (registerModel: RegisterModel): Observable<any> => {
    return this.httpClient.post(`${this.config.apiUrl}authentication/register`, registerModel);
  }

  logout = () => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/logout`, {}, { withCredentials: true })
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }
}
