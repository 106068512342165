import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG } from "../../../config/config.token";
import { AppConfig } from "../../../config/app.config";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, catchError, debounceTime, map, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GlobalAsyncValidatorService {
  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig  
  ) { }

  validateUserInvitationCode(code:string | null): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      if (!control.value) {
        return of(null);
      }

      return this.httpClient.get<{ inviteCodeValid: boolean }>(`${this.config.apiUrl}userinvite/validate-user-invite-code?email=${control.value}&invitecode=${code}`, {withCredentials: true})
        .pipe(
          debounceTime(3000),
          map((response) => {
            return null;
          }),
          catchError(() => of({ inviteCodeValid: true }))
        )
    }
  }
}
