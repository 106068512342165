import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../global/config/app.config";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG } from "../../global/config/config.token";
import { Observable } from "rxjs";
import { DonorFlagFormModel } from "../models/DonorModel";

@Injectable({
    providedIn: 'root'
})
export class DonorFlagService {
    constructor(private httpClient: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig)
    { }

    getDonorFlags = (campaignId: number): Observable<any> => {
        return this.httpClient.get(`${this.config.apiUrl}donorflag/get-donor-flags?campaignId=` + campaignId, { withCredentials: true });
    }

    saveDonorFlag = (donorFlagFormModel: DonorFlagFormModel): Observable<any> => {
        return this.httpClient.post(`${this.config.apiUrl}donorflag/add-edit-donor-flag`, donorFlagFormModel, { withCredentials: true });
    }
}