import { ChangeDetectorRef, Component, EventEmitter, Output, output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { userInfoModel } from '../../../global/model/LoginModel';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    MessageModule,
    InputMaskModule,
    PasswordModule,
    ReactiveFormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    ToastModule
  ],
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthService,
                 MessageService]
})
export class AccountSettingsComponent {
  showPasswordSection = false;
  showPassword = false;
  showCurrentPassword = false;
  showConfirmPassword = false;
  verifyCodeButtonLabel = 'Verify Code';
  isVerifying = false;
  isPhoneVerified = false;
  showPhoneVerification = false;

  phoneVerificationLabel = 'Verify';
  phoneVerificationIcon = 'pi pi-exclamation-circle';
  phoneVerificationSeverity: 'success' | 'info' | 'warning' | 'danger' | 'help' | 'primary' | 'secondary' | 'contrast' = 'primary';

  passwordValidations = {
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    minLength: false
  };

  personalInfoForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
    phone: ['', Validators.required],
    phoneVerificationCode: [''],
    isPhoneVerified: false
  });

  passwordForm = this.fb.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  });

  passwordRules = [
    { text: 'Includes at least one uppercase letter (A-Z).', valid: false },
    { text: 'Includes at least one lowercase letter (a-z).', valid: false },
    { text: 'Includes at least one numeric digit (0-9).', valid: false },
    { text: 'Includes at least one non-alphanumeric character (e.g., !, @, #).', valid: false },
    { text: 'None of the characters repeat.', valid: true },
    { text: 'Minimum length is 8 characters.', valid: false }
  ];

  @Output() closeDialog = new EventEmitter();
  
  constructor(private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private messageService: MessageService) {
    this.getUserInfo();
  }

  getUserInfo(): void {
    this.authService.getUserInfo().subscribe(
      (data) => {
        this.personalInfoForm.patchValue({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phoneNumber,
          isPhoneVerified: data.isPhoneVerified
        });
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to load user data' });
      }
    );
  }

  save(): void {
    if (this.personalInfoForm.invalid) {
      this.messageService.add({ severity: 'warn', summary: 'Validation Error', detail: 'Please fill in all required fields.' });
      return;
    }

    const updatePayload: userInfoModel = {
      firstName: this.personalInfoForm.get('firstName')?.value || '',
      lastName: this.personalInfoForm.get('lastName')?.value || '',
      currentPassword: null,
      newPassword: null
    };

    this.authService.updateUserInfo(updatePayload).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Profile updated successfully!' });
        // this.closeDialog.emit();
      },
      (error) => {
        console.error("Error updating user", error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error || 'An error occurred while updating your profile.' });
      }
    );
  }

  updatePassword(): void {
    if (this.passwordForm.invalid) {
      this.messageService.add({ severity: 'warn', summary: 'Validation Error', detail: 'Please fill in all password fields correctly.' });
      return;
    }

    const newPassword = this.passwordForm.get('newPassword')?.value;
    const confirmPassword = this.passwordForm.get('confirmPassword')?.value || null;
    const currentPassword = this.passwordForm.get('currentPassword')?.value || null;

    if (newPassword !== confirmPassword) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Passwords do not match.' });
      return;
    }

    const payload: userInfoModel = {
      firstName: null,
      lastName: null,
      currentPassword,
      newPassword
    };

    this.authService.updateUserInfo(payload).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password updated successfully!' });
        this.passwordForm.reset();
        this.showPasswordSection = false;
      },
      (error) => {
        console.error("Error updating password", error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error || 'An error occurred while updating your password.' });
      }
    );
  }

  cancelPasswordUpdate(): void {
    this.passwordForm.reset();
    this.showPasswordSection = false;

    // Reset password validation flags
    this.passwordValidations = {
      uppercase: false,
      lowercase: false,
      number: false,
      specialChar: false,
      minLength: false
    };

    // Reset password rules UI
    this.passwordRules = [
      { text: 'Includes at least one uppercase letter (A-Z).', valid: false },
      { text: 'Includes at least one lowercase letter (a-z).', valid: false },
      { text: 'Includes at least one numeric digit (0-9).', valid: false },
      { text: 'Includes at least one non-alphanumeric character (e.g., !, @, #).', valid: false },
      { text: 'None of the characters repeat.', valid: true },
      { text: 'Minimum length is 8 characters.', valid: false }
    ];
  }

  handlePhoneVerification(): void {
    const phoneNumber = this.personalInfoForm.get('phone')?.value;

    if (!phoneNumber) {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter a valid phone number.' });
      return;
    }

    this.showPhoneVerification = true;
    this.verifyCodeButtonLabel = 'Verify Code';

    const payload = { phoneNumber, email: null };

    this.authService.generateSms2fa(payload).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Verification code sent to your phone.' });
      },
      (error: any) => {
        console.error('Error sending verification code:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send verification code.' });
      }
    );
    this.cdr.detectChanges();
  }

  resendPhoneVerification(event: Event): void {
    event.preventDefault();
    const phoneNumber = this.personalInfoForm.get('phone')?.value;

    if (!phoneNumber) {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter a valid phone number.' });
      return;
    }

    const payload = { phoneNumber, email: null };

    this.authService.generateSms2fa(payload).subscribe(
      () => {
        this.messageService.add({ severity: 'info', summary: 'Resent', detail: 'A new verification code has been sent.' });
      },
      (error: any) => {
        console.error('Error resending verification code:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to resend verification code.' });
      }
    );
  }


  submitPhoneVerification(): void {
    const verificationCode = this.personalInfoForm.get('phoneVerificationCode')?.value;

    if (!verificationCode) {
      this.messageService.add({ severity: 'warn', summary: 'Missing Code', detail: 'Please enter the verification code.' });
      return;
    }
    const payload = {
      email: null,
      method: 'Phone',
      code: verificationCode,
      rememberDevice: false
    }
    this.authService.verify2fa(payload).subscribe(
      () => {
        this.personalInfoForm.patchValue({ isPhoneVerified: true });
        this.isPhoneVerified = true;
        this.showPhoneVerification = false;
        this.phoneVerificationLabel = 'Verified';
        this.phoneVerificationIcon = 'pi pi-check-circle';
        this.phoneVerificationSeverity = 'success';

        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Phone number verified successfully!' });
      },
      (error) => {
        console.error('Error verifying phone:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid verification code. Please try again.' });
      }
    );
  }

  cancelPhoneVerification(event: Event): void {
    event.preventDefault();
    this.showPhoneVerification = false;
    this.isVerifying = false;
  }

  togglePasswordSection() {
    this.showPasswordSection = !this.showPasswordSection;
  }

  verifyPhone() {
    this.showPhoneVerification = true;
  }

  toggleCurrentPasswordVisibility(): void {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  validatePassword(): void {
    const passwordControl = this.passwordForm.get('newPassword');
    if (!passwordControl) return;

    const password = passwordControl.value || '';

    this.passwordValidations = {
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      minLength: password.length >= 8
    };

    // Ensure all validation conditions match
    const isValid = Object.values(this.passwordValidations).every(Boolean);

    if (isValid) {
      passwordControl.setErrors(null);
    } else {
      passwordControl.setErrors({ invalidPassword: true });
    }

    // Mark as touched to ensure validation messages appear
    passwordControl.markAsTouched();
    passwordControl.markAsDirty();
  }

  onPasswordChange() {
    const password = this.passwordForm.get('newPassword')?.value || '';

    this.passwordRules[0].valid = /[A-Z]/.test(password);
    this.passwordRules[1].valid = /[a-z]/.test(password);
    this.passwordRules[2].valid = /[0-9]/.test(password);
    this.passwordRules[3].valid = /[^a-zA-Z0-9]/.test(password);
    this.passwordRules[4].valid = !/(.)\1/.test(password);
    this.passwordRules[5].valid = password.length >= 8;
  }

  cancel() {
    this.closeDialog.emit();
  }
}
