import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { GenericButtonComponent } from "../../../global/component/generic/generic-button/generic-button.component";
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CalendarModule } from 'primeng/calendar';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-donor-call-sheets',
  standalone: true,
  imports: [AccordionModule, ButtonModule, GenericButtonComponent, TagModule, DialogModule, InputTextModule, CheckboxModule, InputTextareaModule, InputSwitchModule, ToggleButtonModule, CardModule, CalendarModule, MessagesModule],
  templateUrl: './donor-call-sheets.component.html',
  styleUrl: './donor-call-sheets.component.scss'
})
export class DonorCallSheetsComponent {
  showDialog: boolean = false;
  checked: boolean = true;
  unchecked: boolean = false;

  // messages: Message[] | undefined;

  // ngOnInit() {
  //   this.messages = [{ severity: 'info', detail: 'Additional content fields can be updated in the Donor Card.' }];
  // }

  completeSheetToggle(event: Event) {
    // stopping tab header closing/opening function
    event.stopPropagation(); 

    console.log("testClick working!");
    return !true; 
  }

    
}
