<p-confirmDialog></p-confirmDialog>
<div id="donor-flags" data-cy="donor-flags">
    <h1>Donor Flags</h1>
    <p-card>
        <rc-generic-button 
            level="secondary"
            borderStyle="solid"
            label="Add New Flag"
            (clickHandler)="onAddNewFlag()"
            data-cy="add-new-flag-button"
            />
        <rc-generic-table 
            [columns]="columns"
            [rows]="rows"
            (actionClicked)="onActionClicked($event)"
            data-cy="donor-flags-table"/>
    </p-card>
</div>

<p-dialog
    [modal]="true"
    [(visible)]="showAddEditDialog"
    styleClass="p-dialog-static p-dialog-sm fadeinright animation-duration-300">

  <ng-template pTemplate="header">
    <span class="h3 mb-0 white-space-nowrap">{{ isEditMode ? 'Edit Donor Flag' : 'Add Donor Flag' }}</span>
  </ng-template>
    <ng-container>
        <form [formGroup]="addEditDonorFlag">
            <div class="formgrid grid">
                <div class="field col-12">
                    <label for="name" class="field-label">Flag Name</label>
                    <input id="name" pInputText formControlName="name" placeholder="" />    
                    <div *ngIf="addEditDonorFlag.get('name')?.invalid && addEditDonorFlag.get('name')?.touched"
                      class="block p-error">
                      <small id="name-error" class="error">
                        Please enter a Donor Flag Name.
                      </small>
                    </div>
                </div>
                <div class="field col-12">
                    <label for="description" class="field-label">Flag Description</label>
                    <textarea id="description" pInputText formControlName="description" placeholder="">
                    </textarea>
                    <div *ngIf="addEditDonorFlag.get('description')?.invalid && addEditDonorFlag.get('description')?.touched"
                        class="block p-error">
                      <small id="description-error" class="error">
                        Please enter a Donor Flag Description.
                      </small>
                    </div>
                </div>
                <div class="field col-12">
                    <p-toggleButton name="status" onLabel="Active" offLabel="Inactive" onIcon="pi pi-check-circle"
                        offIcon="pi pi-ban" formControlName="status" />
                </div>
            </div>
        </form>
      
    </ng-container>   

    <ng-template pTemplate="footer">
        <div class="flex pt-1 justify-content-end">
        <rc-generic-button label="Cancel" borderStyle="text-only" (click)="onCloseAddEditDialog()" />
        <rc-generic-button level="tertiary" label="Save" (click)="onSaveDonorFlag()" />
        </div>
    </ng-template>
</p-dialog>
