import { Component } from '@angular/core';

@Component({
  selector: 'app-raise-by-election-fund',
  standalone: true,
  imports: [],
  templateUrl: './raise-by-election-fund.component.html',
  styleUrl: './raise-by-election-fund.component.scss'
})
export class RaiseByElectionFundComponent {

}
