<p-toast/>

<div id="appBar">
    <ul class="product-list">
        <li (click)="onAppClick(item)" *ngFor="let item of model.productsAndFeatures">
            <a class="product-link {{model.selectedApp !== null && (item.name === model.selectedApp.name) ? 'selected' : ''}}">{{ item.name }}</a>
        </li>
    </ul>
    <ul class="bar-actions">
        <li>
            <a class="app-bar-icon">
                <i class="fa-sharp fa-light fa-user"></i>
            </a>
        </li>
        <li>
            <a class="app-bar-icon">
                <i class="fa-sharp fa-light fa-power-off" (click)="logout()"></i>
            </a>
        </li>
    </ul>

</div>
 {{model.selectedApp | json }}