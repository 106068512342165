<h1>Manual Dedupes</h1>

<p-card styleClass="mb-3">
    <ul class="dedupe-notes">
        <li>Potential duplicates are identified by 10 part weighted average.</li>
        <li>Please select two records from the same group to compare and merge.</li>
    </ul>

    <div class="grid">
        <div class="field col-12 sm:col-6">
            <p-dropdown 
                [options]="options" 
                [(ngModel)]="selectedOption" 
                optionLabel="name"
                placeholder="Select Option" />
        </div>
        <div class="field col-12 sm:col-6 flex align-items-center">
            <p-checkbox
                [binary]="true"
                inputId="includeCurrentDayDonors"
                label="Include Current Day Donors"
                />
        </div>
    </div>
</p-card>

<p-card>
    <div class="grid">
        <div class="col">
            <rc-generic-button 
                label="Compare"
                id="button-compare"
                level="primary"
                iconImg="compare"
                [classes]="['mr-1 my-1']" />
            
                <rc-generic-button 
                label="Refresh"
                id="button-refresh"
                level="secondary"
                iconImg="refresh"
                [classes]="['mr-1 my-1']" />
        </div>
        <div class="col text-right">
            <rc-generic-button 
                label="Clear All"
                id="button-clear-filters"
                iconImg="clear-filter"
                borderStyle="outline"
                [classes]="['my-1']" />
        </div>
    </div>
    

    <p-table styleClass="rc-datatable-outlined">
        <ng-template pTemplate="header">
            <tr>
                <th> <!-- checkboxes in this column--> </th>
                <th>Donor ID</th>
                <th>Name</th>
                <th>Address</th>
                <th>Employer/Occupation</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body">
            <tr>
                <td>lkjlkjlkjlkj</td>
                <td>kjlkjlkj</td>
                <td>kjlkjlkjlkj</td>
                <td>lkjlkjlkjlkj</td>
                <td>oiuoiuoiu</td>
            </tr>
            <!-- assign .dedupe-group-divider to the last row in the group -->
            <tr class="dedupe-group-divider">
                <td>lkjlkjlkjlkj</td>
                <td>kjlkjlkj</td>
                <td>kjlkjlkjlkj</td>
                <td>lkjlkjlkjlkj</td>
                <td>oiuoiuoiu</td>
            </tr>
        </ng-template>
    </p-table>

</p-card>
