import { JsonPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MainModel } from '../../model/MainModel';
import { cleanSVGForOutput } from '../../util/util';
//import { getBreadcrumbLabel } from '../../app.routes';


const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [
    BreadcrumbModule, NgIf,JsonPipe
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {

  navItems:any[] = [];
  isShowBreadcrumb:boolean = !false;


  /**
   * 
   * @param route 
   * @param url 
   * @param breadcrumbs 
   * @returns 
   */

  public createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const urlMap:Map<string, Route> = new Map();
    this.router.config.forEach(
      i => { if(i.data && ["breadcrumb"])urlMap.set( i.path || "", i.data["breadcrumb"] ) }
    );
    const urlSegments:Array<string> = location.pathname.split("/");
    let _url:string = "";
    const returnItems:Array<any> = [];
    //console.debug(urlSegments);
    urlSegments.forEach(
      (value, index) => {
        if(value !== null && value.length > 0){ 
          _url += (_url.length === 0 ? value : `/${value}`);
          const v:any = urlMap.get(_url);
          if(v){
            let lbl = v;
            if(value === "vortex" || value === "vortex-lite" || value === "admin" || value === "apex"){
              lbl = "Home";
            }
            returnItems.push( {
              label: lbl,
              url: _url,
              }
            );

          }
          //console.debug(index, _url, urlMap.get(_url));
        }
      }
    );
    return returnItems;
  }

  /**
   * 
   * @param router 
   * @param activeRoute 
   * @param sanitizer 
   * @param model 
   */
  constructor(
    private router: Router, 
    private activeRoute:ActivatedRoute, 
    protected model:MainModel
  ){
    this.navItems = [];
    // this.router.events.pipe(
    //     filter((event) => event instanceof NavigationEnd),
    //     tap(() => {
    //       this.navItems = this.createBreadcrumbs(this.activeRoute);
    //     }
    //   )
    // );
    router.events.subscribe((value:any) => {
      if(value instanceof NavigationEnd){
            
        if(value instanceof NavigationEnd){
            const newItems:any = this.createBreadcrumbs( activeRoute );
            this.navItems = newItems;
            //console.debug(this.router.getCurrentNavigation())
            }
        }
      }); 

  }
  
}
