import {Component, Injectable} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { DashboardComponent } from '../../../page/dashboard/dashboard.component';
import { SearchComponent } from '../../../component/search/search.component';
import { PageNotFoundComponent } from '../../../page/page-not-found/page-not-found.component';
import { MainModel, UserModel } from '../../../model/MainModel';
import { MenuItem } from 'primeng/api';
import { concatAll, ConnectableObservable, debounceTime, exhaustAll, forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { LoginComponent } from '../../../page/login/login.component';
import { RegisterComponent } from '../../../page/register/register.component';
import Campaign from '../../../model/Campaign';
import { ServerClientService } from '../../data/CampaignService/server-client.service';
import { AnimationStyleMetadata } from '@angular/animations';
import App from '../../../model/App';
import { Admin_Tools, Anonymous_Navigation, NavOrder, NavSetsByAppName } from '../../../model/NavModel';


/**
 * The NavService will be instantiated by the root services object. THis object should register itself to:
 * 1. listen for route changes - if a route event happens and current application models are populated the NavService 
 * will request that those calls me made. 
 */
@Injectable({
    providedIn: 'root'
  })
export class NavService {

    constructor(
        private activedRoute:ActivatedRoute,
        private serverClient:ServerClientService,
        private router:Router,
        private model:MainModel,
    ){

    }
    
    
    setNavState(){
        const app:App|null = this.model.selectedApp();
        console.log("selected app", app)
        if(app === null){
            this.model.navigation.applicationNavset = Anonymous_Navigation;
        } else {
            if(app?.name){
                const navSet:any = NavSetsByAppName.get(app.name);
                this.model.navigation.applicationNavset = navSet;
            }
        }
    }



    /**
     * Sets the current navigation structure based upon the currently selected application. 
     * @param user 
     * @param app 
     * @returns 
     */
    setCurrentNavModel = (app:App):void => {
        this.model.selectedApp.set(app);
        this.setNavState();
    }



    saveLasAccessedCampaign(c:Campaign):void {
        const x = this.serverClient.saveLasAccessedCampaign(c)
            .subscribe(
                (data:any) => {
                    this.model.productsAndFeatures = data.productsAndFeatures.campaignProducts;
                    if(data.productsAndFeatures.isAdmin === true){
                        const a = new App();
                        a.id = 99999;
                        a.name = "Admin Tools";
                        a.features = Admin_Tools;
                        this.model.productsAndFeatures.push(a);
                    }
                    this.model.updateNavigationModel(this.model.productsAndFeatures);
                    console.log("HERE!!!!!!!!!!!!!!!!!!!")
                    this.setCurrentNavModel(this.model.productsAndFeatures[0]);
                    if(this.isOnCampaignSelectionPage())this.navigateToDefaultPage(c, this.router)
                    return data;
                }
            );
    }



    isOnCampaignSelectionPage(){
        return this.router.url === "/loading" || this.router.url === "/campaign-selection";
    }

        
    /**
     * This gets caled onload to see if we need to get data. 
     * If there is no data and we are not on one of the app initialization screens we request it.
     * 
     */
    checkForRequiredModels(campaign:Campaign | null | undefined){
        console.log("checkForRequiredModels")
        if(this.model.campaigns.length === 0){
            const dataObject = {
                lastAccessed: null,
                campaigns: null,
            }
            forkJoin(
                {
                    lastAccessed: this.serverClient.getLastAccessedCampaignData(),
                    campaigns: this.serverClient.getAllCampaignsData()
                }
            ).subscribe(
                data => {
                    this.model.lastAccessedCampaigns = data.lastAccessed;
                    this.model.campaigns = data.campaigns;
                    if(!this.isOnCampaignSelectionPage()){
                        this.model.selectedCampaign.set(data.lastAccessed[0])
                    }
                }
            )
        }
    }



    setCampaignDefaultPath(campaign:Campaign, appsAndFeatures:Array<App> = this.model.productsAndFeatures):void{
        if(appsAndFeatures.length  === 0)return;
        // if already set... maybe processed before or serialized to localstorage?? who knows...
        if(campaign?.defaultPath?.length){
            console.log("default path already set..");
        } else{
            // lets set the values intrinsically firstby processing the two seperate trees - 
            // the one from the back end - and the one configured on the front end.
            let firstMatch = false;
            try {
                NavOrder.forEach(
                    itm => {
                        if(firstMatch) return;
                        const label = itm.label.toLowerCase();
                        const vals = appsAndFeatures?.forEach((element:App) => {
                            if(firstMatch) return;  
                            const appName:string = element.name.toLowerCase();
                            if(label === appName){  
                                campaign.defaultPath = [appName.toLowerCase().replace("3",""),itm.page];
                                firstMatch = true;
                                }
                        });
                    }
                );
            } catch (error) {
                console.debug(error);
            }
            if(firstMatch === false)console.debug("couldnt find a match for", campaign, appsAndFeatures);
        }
    }



    navigateToDefaultPage(campaign:Campaign, router:Router):void {
        this.setCampaignDefaultPath(campaign);
        if(campaign.defaultPath)router.navigate(campaign.defaultPath);
    }
    


}
