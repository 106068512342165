import { Component } from '@angular/core';
import { LoadingSpinnerComponent } from '../../component/loading-spinner/loading-spinner.component';

@Component({
  selector: 'rc-loading-page',
  standalone: true,
  imports: [LoadingSpinnerComponent],
  templateUrl: './loading-page.component.html',
  styleUrl: './loading-page.component.scss'
})
export class LoadingPageComponent {

}
