import { NgComponentOutlet, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RaiseByElectionFundComponent } from '../raise-by-election-fund/raise-by-election-fund.component';

@Component({
  selector: 'drag-and-drop',
  standalone: true,
  imports: [NgIf, NgComponentOutlet, RaiseByElectionFundComponent],
  templateUrl: './drag-and-drop.component.html',
  styleUrl: './drag-and-drop.component.scss'
})
export class DragAndDropComponent {

  @Input() componentName:string = "Component";
  @Input() componentImg:string = "/assets/component-1.png";
  @Input() type:string = "";
  @Input() renderPreview:boolean = true;

  getComponent(){
    const v:string = `new ${this.type}()`;
    return (0, eval)(v);
  }

  constructor(){

  }


}
