<div class="flex justify-content-center align-items-center" style="height: 100vh;">
  <p-card class="w-3" styleClass="card-style-account">
    <ng-template pTemplate="header">
      <img src="/assets/images/logo-redcurve.png" id="logo-redcurve" />
    </ng-template>

    <div class="two-factor-auth-container">
      <h2>Log in with Two-Factor Authentication</h2>
      <p>Please select where you want us to send you the two-factor authentication code.</p>

      <div *ngIf="errorMessage" class="error">
        <p-message severity="error" [text]="errorMessage"></p-message>
      </div>

      <div class="grid" [formGroup]="twoFactorForm">
          <div class="col-12">
            <label class="options-label">Options:</label>
            <div *ngFor="let option of options" class="option-item">
              <p-radioButton [inputId]="option.type"
                             [value]="option"
                             formControlName="selectedOption"></p-radioButton>
              <label [for]="option.value">
                <strong>{{ option.type }}:</strong> {{ option.value }}
              </label>
            </div>
          </div>
        </div>

        <div class="action-buttons">
          <button pButton label="Send Code" (click)="sendCode()" [disabled]="twoFactorForm.invalid"></button>
        </div>
      </div>
  </p-card>
</div>
