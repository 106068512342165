<form [formGroup]="loginForm" (ngSubmit)="loginUser()">
  <p-toast />
  <p-card header="Login" [style]="{ width: '360px' }" styleClass="py-4">
    <div class="field">
      <label for="email">Email</label>
      <input type="text"
             pInputText
             formControlName="email"
             class="w-full"
             id="login-email"
             name="email"
             placeholder="Type your email" />
      <small *ngIf="email.invalid && (email.dirty || email.touched)"
             class="block p-error">
        <div *ngIf="email.errors?.['required']">Email is required.</div>
        <div *ngIf="email.errors?.['email']">Please enter a valid email address.</div>
      </small>
    </div>
    <div class="field">
      <label for="password">Password</label>
      <input type="password"
             pInputText
             formControlName="password"
             class="w-full"
             id="login-password"
             name="password"
             placeholder="Type your password" />
      <small *ngIf="password.invalid && (password.dirty || password.touched)"
             class="block p-error">
        <div *ngIf="password.errors?.['required']">Password is required.</div>
      </small>
    </div>
    <div class="text-center mt-4">
      <p-button label="Log in"
                styleClass=""
                [disabled]="loginForm.invalid"
                type="submit"
                id="login-button-sign-in"></p-button>
    </div>
    <!--<div class="mt-2">Not a member? <a routerLink="/register">Register</a></div>-->
  </p-card>
</form>
