import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { Observable } from 'rxjs/internal/Observable';
import Campaign from '../../model/Campaign';
import { CommonModule, NgFor, NgIf, DOCUMENT } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import { CardModule } from 'primeng/card';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CheckboxModule } from 'primeng/checkbox';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MainModel } from '../../model/MainModel';
import { cleanSVGForOutput } from '../../util/util';
import { ServerClientService } from '../../services/data/CampaignService/server-client.service';
import {FormControl, NgModel} from '@angular/forms';

import { FormsModule }    from '@angular/forms';

import {ReactiveFormsModule} from '@angular/forms';
import { GenericTextInputComponent } from "../generic/generic-text-input/generic-text-input.component";
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { SecurityModel } from '../../model/SecurityModel';
import { UserPermissionsService } from '../../services/permissions/UserPermissionsService';
import { NavService } from '../../services/ui/NavService';

@Component({
  selector: 'rc-campaign-switcher',
  standalone: true,
  imports: [
    SidebarModule,
    CardModule,
    ToggleButtonModule,
    CheckboxModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    NgIf,
    FormsModule,
    CommonModule, 
    NgFor,
    GenericTextInputComponent,
    LoadingSpinnerComponent
],
  templateUrl: './campaign-switcher.component.html',
  styleUrl: './campaign-switcher.component.scss'
})

export class CampaignSwitcherComponent implements OnInit {
  @Input({ required: true }) isDockable:boolean = true;
  isSidebarVisible:boolean = false;
  selectedCampaign:Campaign|null = null;
  showVisible:boolean = false;
  filterValue:FormControl = new FormControl('', { updateOn: 'change' });
  viewableCampaigns:Array<any> = [];

  filterResults:Function = () => this.model.campaigns.filter(
    (i) => {
      const isFiltered:boolean = this.filterValue.value !== null && this.filterValue.value.replaceAll(" ", "") !== "";
      const hasFilterValue = i.name.toLowerCase().indexOf(this.filterValue.value?.toLowerCase()) >= 0;
      // console.log(this.showVisible.value);
      if(this.showVisible === true){
        return isFiltered ? hasFilterValue : true ;
      } else {
        return i.isActive && ( (isFiltered && hasFilterValue) || !isFiltered);
      }
    }
  );

  toggle:Function = (event:Event)=> {
    console.debug("toggle");
    this.isSidebarVisible = !this.isSidebarVisible
  }

  isSelectedCampaign:Function = (camp:any) => {
    if(!this.model.selectedCampaign)return false;
    return camp.label === this.model.selectedCampaign?.name;
  }

  getClass:Function = (obj:Campaign) => {
    this.selectedCampaign = this.model.selectedCampaign();
    let style = "";
    if(obj.isActive === false){
      style += " inactive"
    } 
    if(this.selectedCampaign && (obj.campaignId === this.selectedCampaign.campaignId))
    { 
      style += " selected"; 
    }
    return style;
  }

  selectCampaign:Function =(obj:Campaign) => {
    this.model.selectedCampaign.set(obj);
    this.toggle(null);
    this.showActions();

    this.navService.loadSelectedCampaignResources(
      obj,
      () => {
        this.navService?.navigateToDefaultPageRemote();
      }
    );
  }
  
  constructor(
    @Inject(DOCUMENT) private document:Document,
    protected model:MainModel, 
    protected permissions:UserPermissionsService,
    private service:ServerClientService,
    private navService:NavService
  ){
    this.viewableCampaigns = this.model.campaigns;
    this.model.navigation.document = document;
  }

  ngOnInit(): void {
    this.viewableCampaigns = this.model.campaigns;
  }

  hideActions(){
    DomHandler.addClass(this.document.body, 'actions-hidden');
  }

  showActions(){
    DomHandler.removeClass(this.document.body, 'actions-hidden');
  }

  // hide Actions if campaign selection is loaded 
  // ngOnInit() { 
  //   console.debug("campaign selection initialized");
  //   this.hideActions();
  // }

  // ngOnDestroy() { 
  //   this.hideActions();
  // }

}
