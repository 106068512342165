import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { LoginModel, SmsVerificationModel, TwoFaModel, userInfoModel } from '../../global/model/LoginModel';
import { AppConfig } from '../../global/config/app.config';
import { APP_CONFIG } from '../../global/config/config.token';
import { Router } from '@angular/router';
import { RegisterModel } from '../../global/model/RegisterModel';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router) { }

  login = (loginModel: LoginModel): Observable<any> => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/login`, loginModel, { withCredentials: true });
  }

  verify2fa = (twofaModel: TwoFaModel): Observable<any> => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/verify-2fa`, twofaModel, { withCredentials: true });
  }

  verifyPhoneNumber = (payload: { userId: string, verificationCode: string } ): Observable<any> => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/verify-phone-number`, payload, { withCredentials: true });
  }

  generateEmail2fa = (email: string): Observable<any> => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/generate-email-2fa-token`, {email}, { withCredentials: true });
  }

  generateSms2fa = (smsVerificationModel: SmsVerificationModel): Observable<any> => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/generate-sms-2fa-token`, smsVerificationModel, { withCredentials: true });
  }

  getUserInfo = (): Observable<any> => {
    return this.httpClient.get<{ label: string, value: string }[]>(`${this.config.apiUrl}authentication/user-info`, { withCredentials: true });
  }

  updateUserInfo = (userInfo: userInfoModel): Observable<any> => {
    return this.httpClient
      .put(`${this.config.apiUrl}authentication/update-user-info`, userInfo, { withCredentials: true });
  }

  getTwoFactorOptions(): Observable<{ label: string, value: string }[]> {
    // Example backend call, replace URL with real endpoint
    return this.httpClient.get<{ label: string, value: string }[]>('/api/two-factor/options')
      .pipe(
        catchError(() => of([
          { label: 'Sxxxxxxx@Rxxxxxx.xxM', value: 'email' },
          { label: 'XXX-XXX-9567', value: 'sms' }
        ]))
      );
  }

  //sendTwoFactorCode(selectedOption: string): Observable<void> {
  //  return this.httpClient.post<void>('/api/two-factor/send-code', { method: selectedOption });
  //}

  register = (registerModel: RegisterModel): Observable<any> => {
    return this.httpClient.post(`${this.config.apiUrl}authentication/register`, registerModel);
  }

  logout = () => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/logout`, {}, { withCredentials: true })
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }

  forgetPassword = (email: string) => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/forgot-password`, email, { withCredentials: true });
  }

  resetPassword = (payload: any) => {
    return this.httpClient
      .post(`${this.config.apiUrl}authentication/reset-password`, payload, { withCredentials: true });
  }
}
