
<!-- Fly out content -->
<ng-container *ngIf="isDockable === true">
    <div class="selected-campaign-container">
        <p class="selected-campaign-title">
            <button class="p-button p-button-text neutral-60 flex align-items-center" (click)="toggle($event)">
                <span class="selected-campaign-text">{{model.selectedCampaign()?.name}}</span>
            </button>
        </p>
    </div>

    <p-sidebar [(visible)]="isSidebarVisible" position="right" >
        <ng-template pTemplate="header">
            <span class="h3 m-0">Select Campaign</span>
        </ng-template>

        <div class="formgrid grid">
            <div class="field col-12 sm:col-6 md:col-4">
                <rc-input 
                    id="campaignQuickfind" 
                    type="text" 
                    icon="search" 
                    [classes]="['input-quickfind']"
                    placeholder="Type to find campaign" 
                    [control]="filterValue"
                    /> 
            </div>
            <div class="field col-12 sm:col-6 md:col-8 flex align-items-center">
                <!-- TODO:   -->
                <p-checkbox
                    [(ngModel)]="showVisible"
                    [binary]="true"
                    inputId="inactiveCampaignsCheck"
                    label="Show Inactive Campaigns" 
                    *ngIf="permissions.securityModel.isAdminRole === true" 
                    />

            </div>
        </div>

        <ul class="recent-campaigns" *ngIf="model.lastAccessedCampaigns?.length">
            <li class="pr-5 pl-2 block lg:inline-block">
                <strong class="inline-block text-lg">Recent:</strong>
            </li>
            <li class="pr-5" *ngFor="let itm of model.lastAccessedCampaigns.slice(0,3)" (click)="selectCampaign(itm)">
                <a>{{itm.name}}</a>
            </li>
        </ul>
        
        <p-card>
            <ul class="grid campaign-switcher-grid" >
                <li *ngFor="let item of filterResults()" class="col-12 sm:col-6 md:col-3">
                    <a (click)="selectCampaign(item)" [ngClass]="getClass(item)">
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </p-card>
    </p-sidebar>
</ng-container>

<!-- In page content -->
<ng-container *ngIf="isDockable === false">

    <h1>Select Campaign</h1>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-6 xl:col-4">
            <rc-input 
                id="campaignQuickfind" 
                type="text" 
                icon="search" 
                [classes]="['input-quickfind']" 
                placeholder="Type to find campaign" 
                [control]="filterValue"
                /> 
        </div>
        <div class="field col-12 sm:col-6 xl:col-8 flex align-items-center">
            <!-- TODO: [(ngModel)]="checked"  -->
            <p-checkbox 
                [(ngModel)]="showVisible"
                [binary]="true"
                inputId="inactiveCampaignsCheck"
                label="Show Inactive Campaigns" 
                *ngIf="permissions.securityModel.isAdminRole === true" 
                />
        </div>
    </div>

    <div class="grid recent-campaigns" *ngIf="model.lastAccessedCampaigns?.length">
        <div class="col sm:col-12 md:col-3">
            <span class="h5">Recent:</span>
        </div>
        <a class="col sm:col-4 md:col-3" *ngFor="let itm of model.lastAccessedCampaigns.slice(0,3)" (click)="selectCampaign(itm)">
            <span>{{itm.name}}</span>
        </a>
    </div>


    <p-card>
        <ul class="grid campaign-switcher-grid">
            <li *ngFor="let item of filterResults()" class="col-12 sm:col-6 md:col-4 xl:col-3">
                <span (click)="selectCampaign(item)" [ngClass]="getClass(item)">
                    {{ item.name }}
                </span>
            </li>
        </ul>
    </p-card>
</ng-container>