import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { Observable } from 'rxjs/internal/Observable';
import Campaign from '../../model/Campaign';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CheckboxModule } from 'primeng/checkbox';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MainModel } from '../../model/MainModel';
import { cleanSVGForOutput } from '../../util/util';
import { ServerClientService } from '../../services/data/CampaignService/server-client.service';

@Component({
  selector: 'rc-campaign-switcher',
  standalone: true,
  imports: [
    SidebarModule,
    CardModule,
    ToggleButtonModule,
    CheckboxModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    CommonModule,
    NgIf, 
    NgFor
  ],
  templateUrl: './campaign-switcher.component.html',
  styleUrl: './campaign-switcher.component.scss'
})

export class CampaignSwitcherComponent {
  @Input({ required: true }) isDockable:boolean = true;
  isSidebarVisible:boolean = false;
  selectedCampaign:Campaign|null = null;
  checked: boolean = false;

  toggle:Function = (event:Event)=> {
    console.log("toggle");
    this.isSidebarVisible = !this.isSidebarVisible
  }

  isSelectedCampaign:Function = (camp:any) => {
    if(!this.model.selectedCampaign)return false;
    return camp.label === this.model.selectedCampaign?.name;
  }

  getClass:Function = (obj:Campaign) => {
    this.selectedCampaign = this.model.selectedCampaign();
    let style = "";
    if(obj.isActive === false){
      style += " inactive"
    } 
    if(this.selectedCampaign && (obj.campaignId === this.selectedCampaign.campaignId))
    { 
      style += " selected"; 
    }
    return style;
  }

  selectCampaign:Function =(obj:Campaign) => {
    if(obj.isActive){
      this.model.selectedCampaign.set(obj);
      this.toggle(null);
    }
    //(sCamp);
  }
  
  constructor(
    protected model:MainModel, 
    private service:ServerClientService
  ){
  }

}
