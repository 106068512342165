import { Component, Input } from '@angular/core';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'rc-text-input',
  standalone: true,
  imports: [IconFieldModule, InputIconModule, InputTextModule, JsonPipe],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss'
})
export class TextInputComponent {

  @Input({required: true}) value = "";
  @Input({required: false}) placeholder = "";

}

